// TODO: Move run logic to project page
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";

class RunDialog extends React.PureComponent {
  render() {
    const {
      mark,
      showRunDialog,
      setTextValue,
      closeDialog,
      runChain
    } = this.props;
    return (
      <Dialog
        open={showRunDialog}
        onClose={() => {}}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Запуск цепи</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Введите метку цепи, чтобы отличать разные запуски одной и той же
            цепи
          </DialogContentText>
          <TextField
            error={mark === ""}
            autoFocus
            margin="dense"
            label="Метка"
            inputProps={{ name: "mark" }}
            fullWidth
            value={mark}
            onChange={setTextValue}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Отменить
          </Button>
          {mark && mark !== "" ? (
            <Button onClick={runChain} color="primary">
              Запустить
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    );
  }
}

export default RunDialog;
