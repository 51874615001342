import React, { Fragment, PureComponent } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import TicketSearchField from "./project/TicketSearchField";
import {
  setCurrentProject,
  toggleSidebarText,
  openNotificationDrawer,
  showSmsAeroDialog,
  showQuestquestBookingDialog
} from "../actions/layout";
import { showSearchNoteDialog } from "../actions/notes";

class Header extends PureComponent {
  render() {
    const {
      classes,
      currentProject,
      projectServices,
      clearCurrentProject,
      notificationsCounter,
      dispatchOpenNotificationDrawer,
      dispatchToggleSidebarText,
      dispatchShowSmsAeroDialog,
      dispatchShowQuestQuestBookingDialog,
      dispatchShowSearchNoteDialog
    } = this.props;

    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Menu"
            onClick={dispatchToggleSidebarText}
          >
            <i className="material-icons">menu</i>
          </IconButton>
          <Typography
            variant="title"
            color="inherit"
            noWrap
            className={classes.flex}
          >
            <span onClick={clearCurrentProject} className="pointer">
              {currentProject ? currentProject.name : "Tiketik"}
            </span>
          </Typography>

          {currentProject ? (
            <Fragment>
              <TicketSearchField />
              <IconButton
                color="inherit"
                onClick={dispatchShowSearchNoteDialog}
              >
                <i title="Найти заметку" className="material-icons">
                  find_in_page
                </i>
              </IconButton>
              {projectServices.indexOf("SMSAero") !== -1 && (
                <IconButton color="inherit" onClick={dispatchShowSmsAeroDialog}>
                  <i
                    title="Отправить SMS через SMSAero"
                    className="material-icons"
                  >
                    textsms
                  </i>
                </IconButton>
              )}
              {projectServices.indexOf("QuestQuestBooking") !== -1 && (
                <IconButton
                  color="inherit"
                  onClick={dispatchShowQuestQuestBookingDialog}
                >
                  <i title="Бронь QuestQuest" className="material-icons">
                    event
                  </i>
                </IconButton>
              )}
              <IconButton
                color="inherit"
                onClick={dispatchOpenNotificationDrawer}
              >
                {notificationsCounter && notificationsCounter > 0 ? (
                  <Badge
                    className={classes.margin}
                    badgeContent={notificationsCounter}
                    color="secondary"
                  >
                    <i title="Уведомления" className="material-icons">
                      notifications
                    </i>
                  </Badge>
                ) : (
                  <i title="Уведомления" className="material-icons">
                    notifications
                  </i>
                )}
              </IconButton>
            </Fragment>
          ) : null}
          {currentProject ? (
            <IconButton color="inherit" onClick={clearCurrentProject}>
              <i title="Выйти из проекта" className="material-icons">
                power_settings_new
              </i>
            </IconButton>
          ) : this.props.location.pathname.endsWith("/edit") ? (
            <Link to="/projects" className={classes.link}>
              Выйти
            </Link>
          ) : (
            <a className={classes.link} href="/users/sign_out">
              Выйти
            </a>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

function mapStateToProps(state) {
  const {
    currentProject,
    projectServices,
    notificationsCounter
  } = state.layout;
  return { currentProject, projectServices, notificationsCounter };
}

function mapDispatchToProps(dispatch) {
  return {
    clearCurrentProject: () => dispatch(setCurrentProject(null)),
    dispatchOpenNotificationDrawer: () =>
      dispatch(openNotificationDrawer()),
    dispatchToggleSidebarText: () => dispatch(toggleSidebarText()),
    dispatchShowSmsAeroDialog: () => dispatch(showSmsAeroDialog()),
    dispatchShowQuestQuestBookingDialog: () =>
      dispatch(showQuestquestBookingDialog()),
    dispatchShowSearchNoteDialog: () => dispatch(showSearchNoteDialog())
  };
}

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  flex: {
    flexGrow: 1
  },
  link: {
    cursor: "pointer",
    color: "#fff",
    padding: "8px 16px",
    fontSize: "0.875rem",
    textTransform: "uppercase",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      color: "#fff",
      textDecoration: "none",
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Header);
