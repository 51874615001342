import React from "react";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import OtherTicketsPage from "./OtherTicketsPage";

const OtherTicketsGuard = props => {
  if (!props.channelConnected) return <LinearProgress />;
  return <OtherTicketsPage />;
};

const mapStateToProps = state => {
  const { channelConnected } = state.tickets;
  return {
    channelConnected: channelConnected
  };
};

export default connect(mapStateToProps)(OtherTicketsGuard);
