import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ReactQuill from "react-quill";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    [{ 'align': [] }],
    [{ 'color': [] }, { 'background': [] }],
    ["link"],
    ["clean"]
  ]
};

class NoteDialog extends React.PureComponent {
  render() {
    const {
      classes,
      id,
      name,
      oldName,
      text,
      showDialog,
      setName,
      changeText,
      closeDialog,
      createNote,
      updateNote
    } = this.props;
    return (
      <Dialog
        fullScreen
        open={showDialog}
        onClose={() => {}}
        aria-labelledby="note-dialog"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={closeDialog}
              aria-label="Close"
            >
              <i className="material-icons">close</i>
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              className={classes.flex}
            >
              {id ? `Изменение заметки ${oldName}` : "Создание заметки"}
            </Typography>
            <Button onClick={id ? updateNote : createNote} color="inherit">
              Сохранить
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <TextField
            margin="dense"
            label="Название"
            fullWidth
            value={name}
            inputProps={{ name }}
            onChange={setName}
          />
          <ReactQuill
            value={text}
            onChange={changeText}
            modules={modules}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

const styles = () => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  }
});

export default withStyles(styles)(NoteDialog);
