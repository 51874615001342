import { combineReducers } from "redux";
import tickets from "./tickets";
import layout from "./layout";
import notes from "./notes";
import infos from "./infos";

const rootReducer = combineReducers({
  layout,
  tickets,
  notes,
  infos
});

export default rootReducer;
