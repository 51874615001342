import React from "react";
import { compose } from "recompose";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Header from "./Header";
import ProjectSelectPage from "./ProjectSelectPage";
import ProjectCreatePage from "./ProjectCreatePage";
import ProjectEditPage from "./project_management/EditPage";
import ProjectPage from "./project/ProjectPage";
import TextCopyWrapper from "./TextCopyWrapper";
import services from "./services";
import { hideAlert } from "../actions/layout";

class AppPage extends React.Component {
  constructor(props) {
    super(props);
    App.basic = App.cable.subscriptions.create("BasicChannel", {
      connected: function() {},
      disconnected: function() {},
      received() {}
    });
  }

  render() {
    const {
      classes,
      showAlertBar,
      alertText,
      alertColor,
      dispatchHideAlert,
      currentProject,
      projectServices
    } = this.props;
    return (
      <div>
        <Header />
        {currentProject ? (
          <Switch>
            <Redirect from="/projects" to="/" />
            <Route path="/" component={ProjectPage} />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/projects" component={ProjectSelectPage} />
            <Route exact path="/projects/new" component={ProjectCreatePage} />
            <Route
              exact
              path="/projects/:id/edit"
              component={ProjectEditPage}
            />
            <Redirect from="*" to="/projects" />
          </Switch>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={showAlertBar}
          onClose={dispatchHideAlert}
        >
          <SnackbarContent
            className={classes[alertColor]}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
                {alertText}
              </span>
            }
          />
        </Snackbar>
        <TextCopyWrapper />
        {currentProject &&
          projectServices.map(name => {
            const ServiceComponent = services[name];
            return ServiceComponent && <ServiceComponent key={name} />;
          })}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    currentProject,
    projectServices,
    alertText,
    alertColor,
    showAlertBar
  } = state.layout;
  return {
    currentProject,
    projectServices,
    alertText,
    alertColor,
    showAlertBar
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchHideAlert: () => dispatch(hideAlert())
  };
}

const styles = theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    minWidth: 0
  },
  success: {
    backgroundColor: "#43a047"
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: "#ffa000"
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AppPage);
