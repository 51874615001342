// TODO: Use components for chunks
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import CreateButton from "../../CreateButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { get, create, update, destroy } from "../../../api/subscriptions";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";

const emptyDialog = {
  showSubDialog: false,
  showDeleteConfirmation: false,
  id: null,
  name: "",
  parentId: null,
  active: true,
  path: null
};

class Subscriptions extends React.Component {
  state = { isLoading: true, tree: [], ...emptyDialog };

  componentDidMount() {
    get(this.props.projectId)
      .then(({ data }) => this.setState({ tree: data, isLoading: false }))
      .catch(error => console.log(error));
  }

  insertIn(tree, path, { id, name, active }) {
    let currentChunk = null;
    let children = tree;
    if (path) {
      const splittedPath = path.split("/").map(id => parseInt(id));
      splittedPath.forEach(id => {
        currentChunk = children.find(chunk => chunk.id === id);
        children = currentChunk.children;
      });
    }
    children.push({ id, name, active, children: [], expanded: true });
    return JSON.parse(JSON.stringify(tree));
  }

  deleteFrom(tree, path) {
    let currentChunk = null;
    let parent = null;
    let children = tree;
    const splittedPath = path.split("/").map(id => parseInt(id));
    splittedPath.forEach(id => {
      currentChunk = children.find(chunk => chunk.id === id);
      parent = children;
      children = currentChunk.children;
    });
    const index = parent.findIndex(chunk => chunk.id === currentChunk.id);
    parent.splice(index, 1);
    return JSON.parse(JSON.stringify(tree));
  }

  updateIn(tree, path, { name, active }) {
    let currentChunk = null;
    let children = tree;
    const splittedPath = path.split("/").map(id => parseInt(id));
    splittedPath.forEach(id => {
      currentChunk = children.find(chunk => chunk.id === id);
      children = currentChunk.children;
    });
    currentChunk.name = name;
    currentChunk.active = active;
    return JSON.parse(JSON.stringify(tree));
  }

  newChunk = e => {
    const { parent, path } = e.currentTarget.dataset;
    this.setState({ showSubDialog: true, parentId: parent, path: path });
  };

  createChunk = () => {
    const { name, active, parentId, tree, path } = this.state;
    const params = { name, active, subscription_chunk_id: parentId };
    create(this.props.projectId, params)
      .then(({ data }) => {
        const newTree = this.insertIn(tree, path, data);
        this.setState({ tree: newTree, ...emptyDialog });
      })
      .catch(error => console.log(error));
  };

  editChunk = e => {
    const { id, name, active, path } = e.target.dataset;
    this.setState({
      showSubDialog: true,
      id,
      name,
      path,
      active: active === "true"
    });
  };

  updateChunk = () => {
    const { id, name, active, tree, path } = this.state;
    update(this.props.projectId, { id, name, active })
      .then(() =>
        this.setState({
          tree: this.updateIn(tree, path, { name, active }),
          ...emptyDialog
        })
      )
      .catch(error => console.log(error));
  };

  confirmDelete = e => {
    const { id, name, path } = e.target.dataset;
    this.setState({ showDeleteConfirmation: true, id, name, path });
  };

  destroyChunk = () => {
    const { id, tree, path } = this.state;
    const newTree = this.deleteFrom(tree, path);
    destroy(this.props.projectId, id)
      .then(() => this.setState({ tree: newTree, ...emptyDialog }))
      .catch(error => console.log(error));
  };

  toggleExpandChunk = e => {
    const path = e.currentTarget.dataset.path.split("/");
    let newTree = JSON.parse(JSON.stringify(this.state.tree));
    let currentChunk = null;
    let children = newTree;
    path.forEach(id => {
      currentChunk = children.find(chunk => chunk.id.toString() === id);
      children = currentChunk.children;
    });
    currentChunk.expanded = !currentChunk.expanded;
    this.setState({ tree: newTree });
  };

  changeName = e => {
    this.setState({ name: e.target.value });
  };

  toggleActive = () => {
    this.setState({ active: !this.state.active });
  };

  closeDialogs = () => {
    this.setState({ ...emptyDialog });
  };

  // TODO: Merge funcions and listen for enter on modal itself (not on input only)
  createByEnter = ({ key }) => {
    if (key !== "Enter") return;
    this.createChunk();
  };

  updateByEnter = ({ key }) => {
    if (key !== "Enter") return;
    this.updateChunk();
  };

  render() {
    if (this.state.isLoading) return <LinearProgress />;
    const {
      tree,
      id,
      name,
      active,
      showSubDialog,
      showDeleteConfirmation
    } = this.state;
    return (
      <div>
        <div>{tree.map(c => this.renderChunk(c, c.id.toString(), null))}</div>
        <Dialog
          open={showSubDialog}
          onClose={() => {}}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {id ? "Обновить подписку" : "Добавить узел подписки"}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Название узла"
              onChange={this.changeName}
              fullWidth
              value={name}
              onKeyUp={id ? this.updateByEnter : this.createByEnter}
            />
            <FormControlLabel
              control={<Switch onChange={this.toggleActive} checked={active} />}
              label="Активная"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialogs} color="primary">
              Отменить
            </Button>
            {id ? (
              <Button onClick={this.updateChunk} color="primary">
                Обновить
              </Button>
            ) : (
              <Button onClick={this.createChunk} color="primary">
                Сохранить
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <DeleteConfirmationDialog
          show={showDeleteConfirmation}
          text={`Вы действительно хотите удалить подписку "${name}"? Все тикеты этой подписки, а также все дочерние подписки будут удалены.`}
          cancelCallback={this.closeDialogs}
          confirmCallback={this.destroyChunk}
        />
        <CreateButton callback={this.newChunk} title="Создать подписку" />
      </div>
    );
  }

  renderChunk = ({ id, name, active, children, expanded }, path) => {
    const { badge, green, red, chunkWrap, chunkHead } = this.props.classes;
    const color = active ? green : red;
    return (
      <div key={id} className={chunkWrap}>
        <h4 className={chunkHead}>
          <i className="material-icons">subdirectory_arrow_right</i>
          <i className={`material-icons ${color}`}>
            {active ? "done" : "close"}
          </i>
          <span
            className="pointer"
            onClick={this.editChunk}
            data-id={id}
            data-name={name}
            data-active={active}
            data-path={path}
          >
            {name}
          </span>
          <span
            className={`pointer badge badge-danger ${badge}`}
            data-id={id}
            data-name={name}
            data-path={path}
            onClick={this.confirmDelete}
            title="Удалить"
          >
            -
          </span>
          <span
            className={`pointer badge badge-primary ${badge}`}
            data-parent={id}
            data-path={path}
            onClick={this.newChunk}
            title="Добавить подуровень"
          >
            +
          </span>
          {children.length > 0 ? (
            <span
              className={`pointer badge badge-info ${badge}`}
              data-path={path}
              onClick={this.toggleExpandChunk}
              title={expanded ? "Свернуть" : "Развернуть"}
            >
              {expanded ? "<" : ">"}
            </span>
          ) : null}
        </h4>
        {expanded &&
          children.length > 0 &&
          children
            .sort((first, second) => (first.name > second.name ? 1 : -1))
            .map(c => this.renderChunk(c, `${path}/${c.id}`))}
      </div>
    );
  };
}

const styles = () => ({
  chunkWrap: {
    paddingLeft: 30
  },
  chunkHead: {
    display: "flex",
    alignItems: "center"
  },
  green: {
    color: "#00bb00"
  },
  red: {
    color: "#dd0000"
  },
  badge: {
    fontSize: 14,
    marginLeft: "10px",
    display: "inline-block",
    width: 20,
    height: 20
  }
});

export default withStyles(styles)(Subscriptions);
