import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";

class TypeDialogField extends React.PureComponent {
  moveRight = e => {
    const index = parseInt(e.currentTarget.dataset.index);
    this.props.swapFields(index, index + 1);
  };

  moveLeft = e => {
    const index = parseInt(e.currentTarget.dataset.index);
    this.props.swapFields(index, index - 1);
  };

  render() {
    const {
      classes,
      index,
      field,
      updateElement,
      toggleFieldHelper,
      deleteElement
    } = this.props;
    const { name, key, info_webhook, editable, searchable, copiable } = field;
    return (
      <Paper key={index} className={`${classes.paper} col-md-6`}>
        <TextField
          required
          fullWidth
          label="Название кастомного поля"
          placeholder="Название кастомного поля"
          className={classes.textField}
          margin="normal"
          onChange={updateElement}
          inputProps={{
            "data-index": index,
            "data-groupname": "fields",
            "data-inputname": "name"
          }}
          value={name}
          helperText="Будет отображаться в тикете с соответствующим значением"
          error={!name || name.trim() === ""}
        />
        <TextField
          required
          fullWidth
          label="Ключ кастомного поля"
          placeholder="Ключ кастомного поля"
          className={classes.textField}
          margin="normal"
          onChange={updateElement}
          inputProps={{
            "data-index": index,
            "data-groupname": "fields",
            "data-inputname": "key"
          }}
          value={key}
          helperText="Ключ, по которому будет браться значение для отображения в тикете"
          error={!key || key.trim() === ""}
        />
        <TextField
          fullWidth
          label="Вебхук информации"
          placeholder="Вебхук информации"
          className={classes.textField}
          margin="normal"
          onChange={updateElement}
          inputProps={{
            "data-index": index,
            "data-groupname": "fields",
            "data-inputname": "info_webhook"
          }}
          value={info_webhook}
          helperText="URL, по которому при клике на значок поиска отправится GET-запрос для получения информации по значению. Используйте '{value}' (без кавычек) в месте урла, куда нужно подставить текущее значение поля"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={editable}
              onChange={toggleFieldHelper('editable', index)}
              value="123"
            />
          }
          label="Редактируемое"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={searchable}
              onChange={toggleFieldHelper('searchable', index)}
              value="123"
            />
          }
          label="Поисковое"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={copiable}
              onChange={toggleFieldHelper('copiable', index)}
              value="123"
            />
          }
          label="Копируемое"
        />
        <button
          className="btn btn-danger type-fields-button"
          data-index={index}
          data-groupname="fields"
          onClick={deleteElement}
          title="Удалить"
        >
          <i className="material-icons small">close</i>
        </button>
        <button
          className="btn btn-secondary type-fields-button"
          onClick={this.moveRight}
          data-index={index}
          title="Переместить вправо"
        >
          <i className="material-icons small">keyboard_arrow_right</i>
        </button>
        <button
          className="btn btn-secondary type-fields-button"
          onClick={this.moveLeft}
          data-index={index}
          title="Переместить влево"
        >
          <i className="material-icons small">keyboard_arrow_left</i>
        </button>
      </Paper>
    );
  }
}

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: 0
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2
  }
});

export default withStyles(styles)(TypeDialogField);
