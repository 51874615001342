import { showInfoRequest } from "../actions/infos";
import axios from "axios";

const showInfo = store => next => action => {
  if (action.type === showInfoRequest.toString()) {
    const projectId = store.getState().layout.currentProject.id;
    const { id, name, value } = action.payload;
    axios
      .post(`/${projectId}/info/${id}`, { name: name, value: value })
      .then(() => {})
      .catch(error => console.log(error));
  }
  return next(action);
};

export default showInfo;
