import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default class RoleSelecor extends React.PureComponent {
  render() {
    const { roles, roleIds, selectedRoles, toggleRole } = this.props;
    return (
      <div>
        <FormLabel component="legend">Роли</FormLabel>
        {roleIds.map(id => {
          return (
            <FormControlLabel
              key={id}
              control={
                <Checkbox
                  checked={selectedRoles.has(id)}
                  onChange={toggleRole(id)}
                />
              }
              label={roles[id]}
            />
          );
        })}
      </div>
    );
  }
}
