import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import { setFilter } from "../../actions/tickets";

class Filter extends React.PureComponent {
  handleFilterClick = e => {
    const type = e.currentTarget.dataset.type;
    const { page, dispatchSetFilter } = this.props;
    dispatchSetFilter(type, page);
  };

  render() {
    const { types, classes, filter } = this.props;
    return (
      <Card className={classes.filter}>
        <CardContent className={classes.flex}>
          {Object.keys(types)
            .sort()
            .map(key => {
              return (
                <Badge
                  key={key}
                  color="error"
                  badgeContent={types[key]}
                  className="mr-3"
                >
                  <Button
                    variant="contained"
                    onClick={this.handleFilterClick}
                    data-type={key}
                    color={filter === key ? "primary" : "default"}
                  >
                    {key}
                  </Button>
                </Badge>
              );
            })}
        </CardContent>
      </Card>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetFilter: (type, page) => dispatch(setFilter({ type, page }))
  };
}

const styles = () => ({
  filter: {
    overflowX: "scroll"
  },
  flex: {
    display: "flex",
    justifyContent: "flex-start"
  }
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Filter)
);
