import React, { PureComponent, Fragment } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import renderTypeField from "./";

const buildGroupEmptyValues = props => {
  const values = props.field.collection.reduce((acc, { name }) => {
    acc[name] = null;
    return acc;
  }, {});
  return values;
};

class Group extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      values: props.value || [buildGroupEmptyValues(props)]
    };
  }

  change = index => (name, newValue) => {
    const groupValues = this.state.values[index];
    const newGroupValues = { ...groupValues, [name]: newValue };
    let newGroups = [...this.state.values];
    newGroups[index] = newGroupValues;
    this.setState({ values: newGroups }, () =>
      this.props.setValue(this.props.name, this.state.values)
    );
  };

  addSubgroupValues = () => {
    this.setState({
      values: [...this.state.values, buildGroupEmptyValues(this.props)]
    });
  };

  removeSubgroupValues = ({ currentTarget }) => {
    const index = parseInt(currentTarget.dataset.index);
    const newValues = this.state.values.filter((_, idx) => idx !== index);
    this.setState({ values: newValues }, () =>
      this.props.setValue(this.props.name, this.state.values)
    );
  };

  render() {
    const { classes, field, opts } = this.props;
    return this.state.values.map((subgroup, index) => {
      return (
        <Fragment key={index}>
          <Paper className={classes.paper}>
            {field.collection.map(groupField =>
              renderTypeField(
                groupField,
                this.change(index),
                this.state.values[index][groupField.name],
                opts
              )
            )}
            {field.enumerable && this.state.values.length !== 1 && (
              <Button
                className={classes.removeAddButton}
                color="secondary"
                data-index={index}
                onClick={this.removeSubgroupValues}
              >
                Удалить
              </Button>
            )}
            {field.enumerable && index === this.state.values.length - 1 && (
              <Button
                className={classes.groupAddButton}
                variant="fab"
                color="secondary"
                onClick={this.addSubgroupValues}
              >
                <i className="material-icons">add</i>
              </Button>
            )}
          </Paper>
        </Fragment>
      );
    });
  }
}

const styles = () => ({
  paper: {
    position: "relative",
    padding: "0 16px 52px",
    margin: "14px 0"
  },
  groupAddButton: {
    position: "absolute",
    bottom: -62,
    right: 0
  },
  removeAddButton: {
    position: "absolute",
    right: 0,
    bottom: 0
  }
});

export default withStyles(styles)(Group);
