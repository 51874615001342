import React from "react";
import { connect } from "react-redux";
import DateFnsUtils from "material-ui-pickers/utils/date-fns-utils";
import DateTimePicker from "material-ui-pickers/DateTimePicker";
import MuiPickersUtilsProvider from "material-ui-pickers/utils/MuiPickersUtilsProvider";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ruLocale from "date-fns/locale/ru";
import { hideDelayDialog } from "../../actions/tickets";

class DelayDialog extends React.Component {
  state = { selectedDate: new Date() };

  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  hideDelay = () => {
    this.props.dispatchHideDelayDialog();
  };

  delayTicket = () => {
    App.tickets.doAction("delay", {
      id: this.props.ticketToDelay,
      date: this.state.selectedDate
    });
    this.hideDelay();
  };

  render() {
    const { selectedDate } = this.state;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
        <Dialog
          open={!!this.props.ticketToDelay}
          onClose={() => {}}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Отложить тикет {this.props.ticketToDelay}?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Отложить тикет на определенное время или на определенный интервал
              от текущего момента
            </DialogContentText>
            <DateTimePicker
              value={selectedDate}
              onChange={this.handleDateChange}
              ampm={false}
              okLabel="OK"
              cancelLabel="Отмена"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.hideDelay} color="primary">
              Отмена
            </Button>
            <Button onClick={this.delayTicket} color="primary">
              Отложить
            </Button>
          </DialogActions>
        </Dialog>
      </MuiPickersUtilsProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    ticketToDelay: state.tickets.ticketToDelay
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchHideDelayDialog: () => dispatch(hideDelayDialog())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DelayDialog);
