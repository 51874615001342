import React, { PureComponent } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

class TicketType extends PureComponent {
  change = ({ target }) => {
    const { name, setValue } = this.props;
    const { value } = target;
    setValue(name, value);
  };

  render() {
    console.log('1')
    const { title, name, value, types } = this.props;
    return (
      <FormControl key={name} fullWidth>
        <InputLabel htmlFor={`${name}-tickettype`}>{title}</InputLabel>
        <Select
          value={value || ""}
          onChange={this.change}
          inputProps={{ name, id: `${name}-tickettype` }}
        >
          <MenuItem value="">
            <em>Нет</em>
          </MenuItem>
          {types.map(t => (
            <MenuItem key={t.id} value={t.id}>
              {t.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export default TicketType;
