import React from "react";
import Button from "@material-ui/core/Button";

class Pagination extends React.PureComponent {
  render() {
    const { totalPages, page, name, setPageNumber } = this.props;
    return (
      <div className="mt-2">
        {totalPages > 0 &&
          [...Array(totalPages).keys()].map(num => {
            const pageNum = num + 1;
            return page === pageNum ? (
              <Button
                variant="contained"
                color="primary"
                key={num}
                className="mr-1 mt-4"
              >
                {pageNum}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="default"
                key={num}
                className="mr-1 mt-4"
                onClick={() => setPageNumber({ name, num: pageNum })}
              >
                {pageNum}
              </Button>
            );
          })}
      </div>
    );
  }
}

export default Pagination;
