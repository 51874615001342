import axios from "axios";

export const getCommonInfo = projectId => {
  return axios.get(`/projects/${projectId}/common_info`);
};

export const edit = projectId => {
  return axios.get(`/projects/${projectId}/edit`);
};

export const update = (projectId, params) => {
  return axios.patch(`/projects/${projectId}`, {
    authenticity_token: fetchAuthenticityToken(),
    ...params
  });
};

export const regenereteKey = projectId => {
  return axios.patch(`/projects/${projectId}/update_key`, {
    authenticity_token: fetchAuthenticityToken()
  });
};

// private

const fetchAuthenticityToken = () => {
  return document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");
};
