import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Common from "./common/Common";
import Types from "./types/Types";
import Subscriptions from "./subscriptions/Subscriptions";
import Roles from "./roles/Roles";
import Staff from "./staff/Staff";
import Tasks from "./tasks/Tasks";
import Chains from "./chains/Chains";
import Notes from "./notes/Notes";
import Services from "./services/Services";

class EditPage extends React.Component {
  state = { tabNum: 0 };

  setTab = (e, value) => {
    this.setState({ tabNum: value });
  };

  render() {
    const { tabNum } = this.state;
    const { id } = this.props.match.params;
    return (
      <div>
        <div className={this.props.classes.toolbar} />
        <AppBar position="static" className={this.props.classes.bar}>
          <Tabs value={tabNum} onChange={this.setTab} scrollable fullWidth>
            <Tab label="Общие настройки" />
            <Tab label="Типы" />
            <Tab label="Подписки" />
            <Tab label="Роли" />
            <Tab label="Команда" />
            <Tab label="Задачи" />
            <Tab label="Цепи" />
            <Tab label="Заметки" />
            <Tab label="Сервисы" />
          </Tabs>
        </AppBar>
        <main className={this.props.classes.content}>
          {tabNum === 0 && <Common projectId={id} />}
          {tabNum === 1 && <Types projectId={id} />}
          {tabNum === 2 && <Subscriptions projectId={id} />}
          {tabNum === 3 && <Roles projectId={id} />}
          {tabNum === 4 && <Staff projectId={id} />}
          {tabNum === 5 && <Tasks projectId={id} />}
          {tabNum === 6 && <Chains projectId={id} />}
          {tabNum === 7 && <Notes projectId={id} />}
          {tabNum === 8 && <Services projectId={id} />}
        </main>
      </div>
    );
  }
}

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  bar: {
    marginBottom: 10
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    minWidth: 0,
    minHeight: "100vh"
  }
});

export default withStyles(styles)(EditPage);
