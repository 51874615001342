import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { setLoadingState } from "../../actions/tickets";

const refreshTickets = props => () => {
  const { group, action, dispatchSetLoadingState } = props;
  dispatchSetLoadingState(group);
  App.tickets.doAction(action);
};

const RefreshTicketButton = props => {
  return (
    <Button
      variant="fab"
      color="default"
      aria-label="Add"
      title="Обновить"
      onClick={refreshTickets(props)}
      className="refresh-button"
      mini
    >
      <i className="material-icons">refresh</i>
    </Button>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchSetLoadingState: group => dispatch(setLoadingState(group))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  RefreshTicketButton
);
