import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { showNoteRequest, hideSearchNoteDialog } from "../../actions/notes";
import { search } from "../../api/notes";

class SearchNoteDialog extends React.PureComponent {
  state = { term: "", found: [] };

  setTerm = e => {
    this.setState({ term: e.target.value });
  };

  // TODO: Maybe move to middleware?
  searchNote = ({ key }) => {
    if (key !== "Enter") return;
    const { term } = this.state;
    if (term.trim() === "") return;
    search(this.props.projectId, term).then(({ data }) =>
      this.setState({ found: data })
    );
  };

  showNote = e => {
    this.props.dispatchShowNoteRequest(e.currentTarget.dataset.note);
  };

  render() {
    const { term, found } = this.state;
    const {
      classes,
      showSearchNoteDialog,
      dispatchHideSearchNoteDialog
    } = this.props;
    return (
      <Dialog
        fullScreen
        open={showSearchNoteDialog}
        onClose={() => {}}
        aria-labelledby="note-dialog"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={dispatchHideSearchNoteDialog}
              aria-label="Close"
            >
              <i className="material-icons">close</i>
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              className={classes.flex}
            >
              Быстрый поиск заметки
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <TextField
            autoFocus
            label="Введите текст и нажмите Enter, чтобы найти заметку"
            margin="normal"
            fullWidth
            value={term}
            onChange={this.setTerm}
            onKeyUp={this.searchNote}
            placeholder="Введите текст и нажмите Enter, чтобы найти заметку"
          />
          <Table className={classes.table}>
            <TableBody>
              {found.map(note => (
                <TableRow key={note.id}>
                  <TableCell padding="dense">
                    <Button
                      color="primary"
                      size="small"
                      data-note={note.name}
                      onClick={this.showNote}
                    >
                      <i className="material-icons">visibility</i>
                    </Button>
                    {note.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    showSearchNoteDialog: state.notes.showSearchNoteDialog,
    projectId: state.layout.currentProject.id
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchHideSearchNoteDialog: () => dispatch(hideSearchNoteDialog()),
    dispatchShowNoteRequest: noteName => dispatch(showNoteRequest(noteName))
  };
}

const styles = () => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  table: {
    minWidth: 700
  }
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SearchNoteDialog)
);
