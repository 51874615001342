import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import CompactPicker from "react-color/lib/Compact";

// TODO: Do not use color picker inside button card
class TypeDialogButton extends React.PureComponent {
  render() {
    const {
      classes,
      index,
      button,
      updateElement,
      changeButtonColor,
      changeWithDialog,
      deleteElement
    } = this.props;
    const {
      name,
      webhook,
      color,
      text_color,
      with_dialog,
      dialog_fields
    } = button;
    return (
      <Paper key={index} className={`${classes.paper} col-md-6`}>
        <TextField
          required
          fullWidth
          label="Название кнопки"
          placeholder="Название кнопки"
          className={classes.textField}
          margin="normal"
          onChange={updateElement}
          inputProps={{
            "data-index": index,
            "data-groupname": "buttons",
            "data-inputname": "name"
          }}
          value={name}
          error={!name || name.trim() === ""}
        />
        <TextField
          required
          fullWidth
          label="Вебхук"
          placeholder="Вебхук"
          className={classes.textField}
          margin="normal"
          onChange={updateElement}
          inputProps={{
            "data-index": index,
            "data-groupname": "buttons",
            "data-inputname": "webhook"
          }}
          helperText="URL, на который будет отправлен POST-запрос после нажатия кнопки (для подстановки значения поля используйте {ИМЯ_ПОЛЯ} в том месте, где необходимо подставить значение)"
          value={webhook}
          error={!webhook || webhook.trim() === ""}
        />
        <div>
          <CompactPicker
            color={color}
            onChangeComplete={changeButtonColor(index, "color")}
          />
          <CompactPicker
            color={text_color}
            onChangeComplete={changeButtonColor(index, "text_color")}
          />
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={with_dialog}
              onChange={changeWithDialog(index)}
              value="123"
            />
          }
          label="С диалогом"
        />
        {with_dialog && (
          <TextField
            required
            fullWidth
            label="Названия полей"
            placeholder="Названия полей"
            className={classes.textField}
            margin="normal"
            onChange={updateElement}
            inputProps={{
              "data-index": index,
              "data-groupname": "buttons",
              "data-inputname": "dialog_fields"
            }}
            value={dialog_fields}
          />
        )}
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          data-index={index}
          data-groupname="buttons"
          onClick={deleteElement}
        >
          Удалить кнопку
        </Button>
      </Paper>
    );
  }
}

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: 0
  },
  button: {
    margin: "10px 20px 10px 5px"
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2
  }
});

export default withStyles(styles)(TypeDialogButton);
