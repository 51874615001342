import { createStore, compose } from "redux";
import rootReducer from "../reducers";
import middlewares from "../middlewares";

const composed =
  process.env.NODE_ENV === "production"
    ? compose(middlewares)
    : compose(
        middlewares,
        window.devToolsExtension
          ? window.__REDUX_DEVTOOLS_EXTENSION__()
          : f => f
      );

export default initialState => createStore(rootReducer, initialState, composed);
