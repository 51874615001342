import React from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose } from "recompose";
import Sidebar from "./Sidebar";
import CurrentTicketsPage from "./CurrentTicketsPage";
import DelayedTicketsPage from "./DelayedTicketsPage";
import OtherTicketsGuard from "./OtherTicketsGuard";
import FoundDialog from "./FoundDialog";
import TasksPage from "./TasksPage";
import ChainsPage from "./ChainsPage";
import CloseDialog from "./CloseDialog";
import DelayDialog from "./DelayDialog";
import CommentDialog from "./CommentDialog";
import NoteDialog from "./NoteDialog";
import ReassignDialog from "./ReassignDialog";
import ButtonClickDialog from "./ButtonClickDialog";
import TicketFieldEditingDialog from "./TicketFieldEditingDialog";
import SearchNoteDialog from "./SearchNoteDialog";
import FieldInfoDialog from "./FieldInfoDialog";
import NotificationTape from "./NotificationTape";
import NotificationDrawer from "./NotificationDrawer";
import {
  setChannelConnected,
  setChannelDisconnected
} from "../../actions/tickets";
import {
  setProjectServices,
  fillNotificationDrawer,
  addPoppedNotification,
  setNotificationsCounter
} from "../../actions/layout";

class ProjectPage extends React.Component {
  constructor(props) {
    super(props);
    App.tickets = App.cable.subscriptions.create(
      { channel: "TicketsChannel", project_id: props.projectId },
      {
        connected() {
          props.dispatchSetChannelConnected();
          this.perform("fetch");
        },
        disconnected() {
          props.dispatchSetChannelDisconnected();
        },
        received(data) {
          props.dispatchArbitrary(data);
        },
        doAction(actionName, opt = null) {
          this.perform(actionName, { opt: opt });
        }
      }
    );
    App.notifications = App.cable.subscriptions.create(
      { channel: "NotificationsChannel", project_id: props.projectId },
      {
        connected() {
          this.perform("fetch_unseen_notifications_count");
        },
        disconnected() {},
        received({ command, value }) {
          switch (command) {
            case "add_notification":
              props.dispatchAddNotification(value);
              break;
            case "set_unseen_notifications_count":
              props.dispatchSetNotificationsCounter(value);
              break;
            case "set_unseen_notifications":
              props.dispatchFillNotificationDrawer(value.reverse());
              break;
            case "set_last_notifications":
              props.dispatchFillNotificationDrawer(value);
              break;
            default:
              break;
          }
        },
        fetchUnseenNotifications() {
          this.perform("fetch_unseen_notifications");
        },
        loadLastNotifications() {
          this.perform("load_last_notifications");
        }
      }
    );
  }

  componentDidMount() {
    this.getProjectServices();
  }

  componentWillUnmount() {
    App.tickets && App.tickets.unsubscribe();
    App.notifications && App.notifications.unsubscribe();
    this.props.dispatchSetChannelDisconnected();
  }

  // TODO: Move to middlewares
  getProjectServices = () => {
    const { projectId, dispatchSetProjectServices } = this.props;
    axios
      .get(`/projects/${projectId}`)
      .then(({ data }) => dispatchSetProjectServices(data))
      .catch(error => console.log(error));
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Sidebar />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route exact path="/" component={CurrentTicketsPage} />
            <Route exact path="/delayed" component={DelayedTicketsPage} />
            <Route exact path="/other" component={OtherTicketsGuard} />
            <Route exact path="/tasks" component={TasksPage} />
            <Route exact path="/chains" component={ChainsPage} />
          </Switch>
        </main>
        <CloseDialog />
        <DelayDialog />
        <NoteDialog />
        <CommentDialog />
        <ReassignDialog />
        <ButtonClickDialog />
        <TicketFieldEditingDialog />
        <FoundDialog />
        <SearchNoteDialog />
        <FieldInfoDialog />
        <NotificationTape />
        <NotificationDrawer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    projectId: state.layout.currentProject.id
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetChannelConnected: () => dispatch(setChannelConnected()),
    dispatchSetChannelDisconnected: () => dispatch(setChannelDisconnected()),
    dispatchSetNotificationsCounter: count =>
      dispatch(setNotificationsCounter(count)),
    dispatchFillNotificationDrawer: info =>
      dispatch(fillNotificationDrawer(info)),
    dispatchAddNotification: text => dispatch(addPoppedNotification(text)),
    dispatchArbitrary: action => dispatch(action),
    dispatchSetProjectServices: services =>
      dispatch(setProjectServices(services))
  };
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex"
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    minWidth: 0,
    minHeight: "100vh"
  },
  toolbar: theme.mixins.toolbar
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProjectPage);
