import { openNotificationDrawer } from "../actions/layout";

const notificationDrawerOpening = store => next => action => {
  if (
    action.type === openNotificationDrawer.toString() &&
    App &&
    App.notifications
  ) {
    App.notifications.fetchUnseenNotifications()
  }
  return next(action);
};

export default notificationDrawerOpening;
