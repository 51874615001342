import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";

class CreateButton extends React.PureComponent {
  render() {
    const { classes, callback, title } = this.props;
    return (
      <Button
        className={classes.fixed}
        variant="fab"
        color="primary"
        title={title}
        onClick={callback}
      >
        <i className="material-icons">add</i>
      </Button>
    );
  }
}

const styles = theme => ({
  fixed: {
    position: "fixed",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
    zIndex: 1
  }
});

export default withStyles(styles)(CreateButton);
