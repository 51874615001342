import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import TaskTable from "../project_management/tasks/TaskTable";
import { connect } from "react-redux";
import { getList, run, stop } from "../../api/tasks";

class TasksPage extends React.Component {
  state = {
    isLoading: true,
    filter: "",
    tasks: [],
    types: {},
    subscriptions: {}
  };

  componentDidMount() {
    getList(this.props.currentProject.id)
      .then(({ data }) => this.setState({ ...data, isLoading: false }))
      .catch(error => console.log(error));
  }

  runTask = e => {
    const id = parseInt(e.currentTarget.dataset.id);
    const { tasks } = this.state;
    const index = tasks.findIndex(t => t.id === id);
    run(this.props.currentProject.id, id)
      .then(() => {})
      .catch(error => console.log(error));
  };

  stopTask = e => {
    const id = parseInt(e.currentTarget.dataset.id);
    const { tasks } = this.state;
    const index = tasks.findIndex(t => t.id === id);
    stop(this.props.currentProject.id, id)
      .then(({ data }) => {
        tasks[index] = data;
        this.setState({ tasks: [...tasks] });
      })
      .catch(error => console.log(error));
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { isLoading, filter, tasks, types, subscriptions } = this.state;
    if (isLoading) return <LinearProgress />;
    return (
      <div>
        <TextField
          style={{ margin: 8 }}
          placeholder="Строка фильтрации"
          helperText="Введите текст для фильтрации задач по имени и описанию"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          inputProps={{ name: "filter" }}
          onChange={this.handleChange}
          value={this.state.filter}
        />
        <TaskTable
          filter={filter}
          tasks={tasks}
          types={types}
          subs={subscriptions}
          run={this.runTask}
          stop={this.stopTask}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { currentProject } = state.layout;
  return { currentProject };
}

export default connect(mapStateToProps)(TasksPage);
