import React from "react";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import Ticket from "./Ticket";
import Pagination from "./Pagination";
import RefreshTicketButton from "./RefreshTicketButton";
import {
  setPageNumber,
  resetOtherTicketCollection
} from "../../actions/tickets";

const pageLimit = 36;

class OtherTicketsPage extends React.PureComponent {
  constructor(props) {
    super(props);
    App.tickets.doAction("fetch_other");
  }

  componentWillUnmount() {
    this.props.dispatchResetOtherTicketCollection();
  }

  render() {
    if (this.props.otherCollectionIsLoading) return <LinearProgress />;
    const { tickets, page, dispatchSetPageNumber } = this.props;
    const takeTo = page * pageLimit;
    const offset = takeTo - pageLimit;
    const allTicketIds = Object.keys(tickets);
    const ticketIds = allTicketIds.slice(offset, takeTo);
    const totalPages = Math.ceil(allTicketIds.length / pageLimit);
    return (
      <div>
        <RefreshTicketButton group="other" action="fetch_other" />
        <div className="row">
          {ticketIds.map(id => <Ticket key={id} id={id} data={tickets[id]} />)}
        </div>
        <Pagination
          page={page}
          totalPages={totalPages}
          setPageNumber={dispatchSetPageNumber}
          name="other"
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    other,
    otherCollectionIsLoading,
    otherTicketPageNumber
  } = state.tickets;
  return {
    tickets: other,
    otherCollectionIsLoading: otherCollectionIsLoading,
    page: otherTicketPageNumber
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchResetOtherTicketCollection: () =>
      dispatch(resetOtherTicketCollection()),
    dispatchSetPageNumber: payload => dispatch(setPageNumber(payload))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OtherTicketsPage);
