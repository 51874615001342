import axios from "axios";

export const get = projectId => {
  return axios.get(`/projects/${projectId}/notes`);
};

export const create = (projectId, name, text) => {
  return axios.post(`/projects/${projectId}/notes`, {
    authenticity_token: fetchAuthenticityToken(),
    name: name.trim(),
    text
  });
};

export const show = (projectId, name) => {
  return axios.post(`/projects/${projectId}/notes/show`, {
    authenticity_token: fetchAuthenticityToken(),
    name
  });
};

export const update = (projectId, name, params) => {
  return axios.patch(`/projects/${projectId}/notes/update`, {
    authenticity_token: fetchAuthenticityToken(),
    name,
    ...params
  });
};

export const destroy = (projectId, name) => {
  return axios.delete(`/projects/${projectId}/notes/destroy`, {
    data: { authenticity_token: fetchAuthenticityToken(), name }
  });
};

export const search = (projectId, term) => {
  return axios.post(`/projects/${projectId}/notes/search`, {
    authenticity_token: fetchAuthenticityToken(),
    term
  });
};

// private

const fetchAuthenticityToken = () => {
  return document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");
};
