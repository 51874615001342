import { refreshTicketsRequest } from "../actions/tickets";

const refreshTickets = store => next => action => {
  if (action.type === refreshTicketsRequest.toString() && App && App.tickets) {
    App.tickets.perform("fetch");
    if (window.location.href.endsWith("/other"))
      App.tickets.perform("fetch_other");
  }
  return next(action);
};

export default refreshTickets;
