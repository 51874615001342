import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

export default class DeleteConfirmationDialog extends React.PureComponent {
  render() {
    const { show, text, cancelCallback, confirmCallback } = this.props;
    return (
      <Dialog
        open={show}
        onClose={() => {}}
        aria-labelledby="delete-confirmation-dialog-title"
      >
        <DialogTitle id="delete-confirmation-dialog-title">
          Подтверждение удаления
        </DialogTitle>
        <DialogContent id="form-dialog-title">{text}</DialogContent>
        <DialogActions>
          <Button onClick={cancelCallback} color="primary">
            Отменить
          </Button>
          <Button onClick={confirmCallback} color="secondary">
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
