import React from "react";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getCommonInfo, update, regenereteKey } from "../../../api/common";
import ProjectForm from "./ProjectForm";
import { showAlert } from "../../../actions/layout";

export class Common extends React.Component {
  state = { isLoading: true, isSaving: false, values: {} };

  componentDidMount() {
    getCommonInfo(this.props.projectId)
      .then(({ data }) => this.setState({ values: data, isLoading: false }))
      .catch(error => console.log(error));
  }

  updateProject = params => {
    this.setState({ isSaving: true });
    update(this.props.projectId, params)
      .then(response => {
        this.setState({ isSaving: false });
        this.props.dispatchShowAlert(response.data);
      })
      .catch(() => {
        this.setState({ isSaving: false });
        this.props.dispatchShowAlert({
          color: "error",
          text: "Ошибка обновления проекта"
        });
      });
  };

  regenerateProjectKey = () => {
    this.setState({ isSaving: true });
    regenereteKey(this.props.projectId)
      .then(({ data }) => {
        this.setState({
          isSaving: false,
          values: { ...this.state.values, api_key: data.text }
        });
        this.props.dispatchShowAlert({
          color: "success",
          text: "Ключ успешно обновлен"
        });
      })
      .catch(() => {
        this.setState({ isSaving: false });
        this.props.dispatchShowAlert({
          color: "error",
          text: "Ошибка обновления проекта"
        });
      });
  };

  render() {
    if (this.state.isLoading) return <LinearProgress />;
    const { isSaving, values } = this.state;
    return (
      <ProjectForm
        values={values}
        isSaving={isSaving}
        onSave={this.updateProject}
        onRegenerate={this.regenerateProjectKey}
        showKey={false}
      />
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchShowAlert: payload => dispatch(showAlert(payload))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Common);
