import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { hideTicketFieldEditingDialog } from "../../actions/tickets";

export class TicketFieldEditingDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ value: this.props.value });
    }
  }

  setFieldValue = e => {
    this.setState({ value: e.target.value });
  };

  hideFieldEditing = () => {
    this.props.dispatchHideTicketFieldEditingDialog();
  };

  confirm = () => {
    const { id, name } = this.props;
    const { value } = this.state;
    App.tickets.doAction("update_ticket_field_value", { id, name, value });
    this.hideFieldEditing();
  };

  confirmByEnter = ({ key }) => {
    if (key !== "Enter") return;
    this.confirm();
  };

  render() {
    const { id, name } = this.props;
    return (
      <Dialog
        open={!!id}
        onClose={() => {}}
        aria-labelledby="form-field-editing-click-title"
      >
        <DialogTitle id="form-field-editing-click-title">
          {`Обновление значения поля '${name}' тикета ${id}`}
        </DialogTitle>
        <DialogContent>
          <TextField
            placeholder={name}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            inputProps={{ name: name }}
            onChange={this.setFieldValue}
            onKeyUp={this.confirmByEnter}
            value={this.state.value}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.hideFieldEditing} color="primary">
            Отмена
          </Button>
          <Button onClick={this.confirm} color="primary">
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  const { id, name, value } = state.tickets.ticketToEdit;
  return { id, name, value };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchHideTicketFieldEditingDialog: () =>
      dispatch(hideTicketFieldEditingDialog())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  TicketFieldEditingDialog
);
