import axios from "axios";

export const get = projectId => {
  return axios.get(`/projects/${projectId}/subscription_chunks`);
};

export const create = (projectId, params) => {
  return axios.post(`/projects/${projectId}/subscription_chunks`, {
    authenticity_token: fetchAuthenticityToken(),
    ...params
  });
};

export const update = (projectId, { id, ...params }) => {
  return axios.patch(`/projects/${projectId}/subscription_chunks/${id}`, {
    authenticity_token: fetchAuthenticityToken(),
    ...params
  });
};

export const destroy = (projectId, id) => {
  return axios.delete(`/projects/${projectId}/subscription_chunks/${id}`, {
    data: { authenticity_token: fetchAuthenticityToken() }
  });
};

// private

const fetchAuthenticityToken = () => {
  return document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");
};
