import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { hideSmsAeroDialog, showAlert } from "../../actions/layout";

class SMSAero extends React.Component {
  state = { number: "", text: "" };

  run = () => {
    const { number, text } = this.state;
    const { dispatchShowAlert, dispatchHideSmsAeroDialog } = this.props;
    const authenticity_token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    axios
      .post(`/projects/${this.props.currentProject.id}/project_services/run`, {
        authenticity_token,
        name: "SMSAero",
        options: { number, text }
      })
      .then(() => {
        dispatchShowAlert({
          color: "success",
          text: "Сообщение принято шлюзом"
        });
        dispatchHideSmsAeroDialog();
      })
      .catch(() =>
        dispatchShowAlert({ color: "error", text: "Ошибка передачи шлюзу" })
      );
  };

  setValue = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  sendByEnter = ({ key }) => {
    if (key !== "Enter") return;
    const {number, text} = this.state;
    if (number.trim() === "" || text.trim() === "") return;
    this.run();
  };

  render() {
    const { showSmsAeroDialog, dispatchHideSmsAeroDialog } = this.props;
    const { number, text } = this.state;
    return (
      <Dialog open={showSmsAeroDialog} onClose={() => {}}>
        <DialogTitle>SMS Aero</DialogTitle>
        <DialogContent>
          <DialogContentText>Отправка SMS через шлюз SmsAero</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Телефон"
            type="phone"
            fullWidth
            inputProps={{ name: "number" }}
            value={number}
            onChange={this.setValue}
            onKeyUp={this.sendByEnter}
          />
          <TextField
            margin="dense"
            label="Текст"
            fullWidth
            inputProps={{ name: "text" }}
            value={text}
            onChange={this.setValue}
            onKeyUp={this.sendByEnter}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={dispatchHideSmsAeroDialog} color="primary">
            Отменить
          </Button>
          {number.trim() !== "" &&
            text.trim() !== "" && (
              <Button onClick={this.run} color="primary">
                Отправить
              </Button>
            )}
        </DialogActions>
      </Dialog>
    );
  }
}

function mapStateToProps({ layout }) {
  return {
    currentProject: layout.currentProject,
    showSmsAeroDialog: layout.showSmsAeroDialog
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchShowAlert: payload => dispatch(showAlert(payload)),
    dispatchHideSmsAeroDialog: () => dispatch(hideSmsAeroDialog())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SMSAero);
