import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import createStore from "../store";
import AppPage from "./AppPage";

const store = createStore();

class Application extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Provider store={store}>
          <AppPage />
        </Provider>
      </BrowserRouter>
    );
  }
}

export default Application;
