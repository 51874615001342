import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { hideCloseDialog } from "../../actions/tickets";

class CloseDialog extends React.Component {
  hideClose = () => {
    this.props.dispatchHideCloseDialog();
  };

  closeTicket = () => {
    App.tickets.doAction("close", this.props.ticketToClose);
    this.hideClose();
  };

  render() {
    return (
      <Dialog
        open={!!this.props.ticketToClose}
        onClose={() => {}}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Закрыть тикет {this.props.ticketToClose}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Тикет полностью исчезнет из системы
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.hideClose} color="primary">
            Отмена
          </Button>
          <Button onClick={this.closeTicket} color="primary">
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    ticketToClose: state.tickets.ticketToClose
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchHideCloseDialog: () => dispatch(hideCloseDialog())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CloseDialog);
