//TODO: Validate all URL fields for url
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import isURL from "validator/lib/isURL";
import TypeDialogField from "./TypeDialogField";
import TypeDialogButton from "./TypeDialogButton";

const emptyField = {
  name: "",
  key: "",
  editable: false,
  searchable: false,
  copiable: false,
  info_webhook: ""
};
const emptyButton = {
  name: "",
  webhook: "",
  color: "#000000",
  text_color: "#FFFFFF",
  with_dialog: false,
  dialog_fields: ""
};
const urlCheckerOptions = {
  protocols: ["http", "https"],
  require_protocol: true
};

class TypeDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { ...props.values };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.values !== this.props.values) {
      this.setState({ ...this.props.values });
    }
  }

  resetForm = () => {
    this.setState({ ...this.props.values });
  };

  updateTextField = e => {
    const { name, value } = e.target;
    this.setState({ ...this.state, [name]: value });
  };

  updateNumField = e => {
    const { name, value } = e.target;
    const formattedValue = value.replace(/[^0-9]/g, "");
    if (formattedValue.length >= 4 && formattedValue !== "1000") return;
    this.setState({ ...this.state, [name]: parseInt(value) || 0 });
  };

  toggleSwitch = (name, field = null) => () => {
    const newState = { ...this.state, [name]: !this.state[name] };
    if (field) {
      Array.isArray(field)
        ? field.forEach(f => (newState[f] = ""))
        : (newState[field] = "");
    }
    this.setState(newState);
  };

  updateTries = e => {
    this.setState({ ...this.state, max_tries: e.target.value });
  };

  addNewField = () => {
    const { fields } = this.state;
    this.setState({ ...this.state, fields: [...fields, { ...emptyField }] });
  };

  addNewButton = () => {
    const { buttons } = this.state;
    this.setState({ ...this.state, buttons: [...buttons, { ...emptyButton }] });
  };

  updateElement = e => {
    const index = parseInt(e.target.dataset.index);
    const groupName = e.target.dataset.groupname;
    const inputName = e.target.dataset.inputname;
    const value = e.target.value;
    this.setState({
      ...this.state,
      [groupName]: this.state[groupName].reduce((acc, elem, i) => {
        acc.push(i === index ? { ...elem, [inputName]: value } : elem);
        return acc;
      }, [])
    });
  };

  deleteElement = e => {
    const index = parseInt(e.currentTarget.dataset.index);
    const groupName = e.currentTarget.dataset.groupname;
    this.setState({
      ...this.state,
      [groupName]: this.state[groupName].reduce((acc, elem, i) => {
        i === index ? null : acc.push(elem);
        return acc;
      }, [])
    });
  };

  toggleFieldHelper = (key, index) => () => {
    const { fields } = this.state;
    const updatedField = { ...fields[index] };
    updatedField[key] = !updatedField[key];
    const newFields = [...fields];
    newFields[index] = updatedField;
    this.setState({ ...this.state, fields: newFields });
  };

  changeButtonColor = (index, key) => ({ hex }) => {
    const { buttons } = this.state;
    const button = { ...buttons[index] };
    button[key] = hex;
    buttons[index] = button;
    this.setState({ buttons: [...buttons] });
  };

  changeWithDialog = index => () => {
    const { buttons } = this.state;
    const button = { ...buttons[index] };
    button.with_dialog = !button.with_dialog;
    buttons[index] = button;
    this.setState({ buttons: [...buttons] });
  };

  swapFields = (firstIndex, secondIndex) => {
    const { fields } = this.state;
    if (
      firstIndex < 0 ||
      secondIndex < 0 ||
      firstIndex >= fields.length ||
      secondIndex >= fields.length
    )
      return;
    const firstField = { ...fields[firstIndex] };
    const secondField = { ...fields[secondIndex] };
    const updatedFields = [...fields];
    updatedFields[firstIndex] = secondField;
    updatedFields[secondIndex] = firstField;
    this.setState({ fields: updatedFields });
  };

  render() {
    // TODO: Remove console.log
    console.log("render");
    const { classes, closeDialog, createType, updateType } = this.props;
    const {
      show,
      id,
      name,
      ticket_limit,
      close_webhook,
      delay_webhook,
      send_push,
      can_close,
      can_delay,
      can_take,
      can_reassign,
      use_tries,
      max_tries,
      tries_intermediate_webhook,
      tries_last_webhook,
      tries_terminal_webhook,
      fields,
      buttons
    } = this.state;
    const actionFunction = id ? updateType : createType;
    const validName = name.trim() !== "" && name.length >= 3;
    const validCloseWebhook =
      close_webhook.trim() === "" || isURL(close_webhook, urlCheckerOptions);
    const validDelayWebhook =
      delay_webhook.trim() === "" || isURL(delay_webhook, urlCheckerOptions);
    const validTriesIntermediateWebhook =
      tries_intermediate_webhook.trim() === "" ||
      isURL(tries_intermediate_webhook, urlCheckerOptions);
    const validTriesLastWebhook =
      tries_last_webhook.trim() === "" ||
      isURL(tries_last_webhook, urlCheckerOptions);
    const validTriesTerminalWebhook =
      tries_terminal_webhook.trim() === "" ||
      isURL(tries_terminal_webhook, urlCheckerOptions);
    const validFields = fields.every(
      f =>
        f.name.trim() !== "" &&
        f.key.trim() !== "" &&
        (f.info_webhook === "" || isURL(f.info_webhook))
    );
    const validButtons =
      buttons.every(b => b.webhook === "" || isURL(b.webhook)) &&
      new Set(buttons.map(f => f.name)).size === buttons.length;
    return (
      <Dialog
        fullScreen
        open={show}
        onClose={() => {}}
        aria-labelledby="form-type-dialog-title"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={closeDialog}
              aria-label="Close"
            >
              <i className="material-icons">close</i>
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              className={classes.flex}
            >
              {id
                ? `Изменение типа "${this.props.values.name}" (${id})`
                : "Создание нового типа"}
            </Typography>
            {actionFunction &&
              validName &&
              validCloseWebhook &&
              validDelayWebhook &&
              validTriesIntermediateWebhook &&
              validTriesLastWebhook &&
              validTriesTerminalWebhook &&
              validFields &&
              validButtons && (
                <Button
                  color="inherit"
                  onClick={() => actionFunction(this.state)}
                >
                  {id ? "Обновить" : "Создать"}
                </Button>
              )}
          </Toolbar>
        </AppBar>
        <DialogContent>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={send_push}
                  onChange={this.toggleSwitch("send_push")}
                  value="true"
                />
              }
              label="Отправлять push при создании тикета"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={can_close}
                  onChange={this.toggleSwitch("can_close", "close_webhook")}
                  value="true"
                />
              }
              label="Можно закрыть"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={can_delay}
                  onChange={this.toggleSwitch("can_delay", "delay_webhook")}
                  value="true"
                />
              }
              label="Можно отложить"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={can_take}
                  onChange={this.toggleSwitch("can_take")}
                  value="true"
                />
              }
              label="Можно взять"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={can_reassign}
                  onChange={this.toggleSwitch("can_reassign")}
                  value="true"
                />
              }
              label="Можно переназначить"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={use_tries}
                  onChange={this.toggleSwitch("use_tries", [
                    "tries_intermediate_webhook",
                    "tries_last_webhook",
                    "tries_terminal_webhook"
                  ])}
                  value="true"
                />
              }
              label="Использовать систему попыток"
            />
          </FormGroup>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              required
              autoFocus
              fullWidth
              label="Название типа"
              placeholder="Название типа"
              className={classes.textField}
              helperText="Будет отображаться в шапке тикета"
              margin="normal"
              onChange={this.updateTextField}
              value={name}
              error={!validName}
              inputProps={{ name: "name" }}
            />
            <TextField
              fullWidth
              label="Лимит тикетов"
              placeholder="Лимит тикетов"
              className={classes.textField}
              helperText="Максимальное количество тикетов, после которого система автоматически начнет удалять старые тикеты. 0 = нет лимита"
              margin="normal"
              onChange={this.updateNumField}
              value={ticket_limit}
              inputProps={{ name: "ticket_limit" }}
            />
            {can_close && (
              <TextField
                fullWidth
                label="Вебхук закрытия"
                placeholder="Вебхук закрытия"
                className={classes.textField}
                helperText="URL, на который будет отправлен POST-запрос перед закрытием тикета"
                margin="normal"
                onChange={this.updateTextField}
                value={close_webhook}
                inputProps={{ name: "close_webhook" }}
                error={!validCloseWebhook}
              />
            )}
            {can_delay && (
              <TextField
                fullWidth
                label="Вебхук откладки"
                placeholder="Вебхук откладки"
                className={classes.textField}
                helperText="URL, на который будет отправлен POST-запрос перед откладкой тикета"
                margin="normal"
                onChange={this.updateTextField}
                value={delay_webhook}
                inputProps={{ name: "delay_webhook" }}
                error={!validDelayWebhook}
              />
            )}
            {use_tries && (
              <div>
                <TextField
                  fullWidth
                  label="Промежуточный вебхук"
                  placeholder="Промежуточный вебхук"
                  className={classes.textField}
                  helperText="URL, на который будет отправлен POST-запрос после каждой попытки (кроме последней)"
                  margin="normal"
                  onChange={this.updateTextField}
                  value={tries_intermediate_webhook}
                  inputProps={{ name: "tries_intermediate_webhook" }}
                  error={!validTriesIntermediateWebhook}
                />
                <TextField
                  fullWidth
                  label="Вебхук последней попытки"
                  placeholder="Вебхук последней попытки"
                  className={classes.textField}
                  helperText="URL, на который будет отправлен POST-запрос после последней попытки"
                  margin="normal"
                  onChange={this.updateTextField}
                  value={tries_last_webhook}
                  inputProps={{ name: "tries_last_webhook" }}
                  error={!validTriesLastWebhook}
                />
                <TextField
                  fullWidth
                  label="Терминальный вебхук"
                  placeholder="Терминальный вебхук"
                  className={classes.textField}
                  helperText="URL, на который будет отправлен POST-запрос после всех попыток"
                  margin="normal"
                  onChange={this.updateTextField}
                  value={tries_terminal_webhook}
                  inputProps={{ name: "tries_terminal_webhook" }}
                  error={!validTriesTerminalWebhook}
                />
                <TextField
                  fullWidth
                  select
                  label="Максимальное количество попыток"
                  className={classes.textField}
                  value={max_tries}
                  onChange={this.updateTries}
                  SelectProps={{ MenuProps: { className: classes.menu } }}
                  helperText="Максимальное количество попыток"
                  margin="normal"
                >
                  {[1, 2, 3, 4].map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            )}
          </form>
          <div className="row">
            {fields.map((field, index) => (
              <TypeDialogField
                key={index}
                index={index}
                field={field}
                updateElement={this.updateElement}
                toggleFieldHelper={this.toggleFieldHelper}
                deleteElement={this.deleteElement}
                swapFields={this.swapFields}
              />
            ))}
          </div>
          <div className="row">
            {buttons.map((button, index) => (
              <TypeDialogButton
                key={index}
                index={index}
                button={button}
                updateElement={this.updateElement}
                changeButtonColor={this.changeButtonColor}
                changeWithDialog={this.changeWithDialog}
                deleteElement={this.deleteElement}
              />
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.addNewField} color="default">
            Добавить поле
          </Button>
          <Button onClick={this.addNewButton} color="default">
            Добавить кнопку
          </Button>
          <Button onClick={this.resetForm} color="primary">
            Сброс
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "30px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: 0
  },
  menu: {
    width: 200
  }
});

export default withStyles(styles)(TypeDialog);
