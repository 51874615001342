import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import isURL from "validator/lib/isURL";
import ReactSelect from "react-select";

class ChainDialog extends React.PureComponent {
  render() {
    const {
      classes,
      showChainDialog,
      taskSelections,
      taskSelectionIds,
      tasks,
      id,
      name,
      webhook,
      setChainValue,
      handleSelectChange,
      dropSelection,
      upSelection,
      downSelection,
      setDelayValue,
      addTaskSelection,
      closeDialog,
      createChain,
      updateChain
    } = this.props;
    const validName = name && name.trim() !== "";
    const validWebhook = webhook === "" || isURL(webhook);
    const showConfirmButton = validName && validWebhook;
    return (
      <Dialog
        open={showChainDialog}
        onClose={() => {}}
        aria-labelledby="form-dialog-title"
        fullScreen
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={closeDialog}
              aria-label="Close"
            >
              <i className="material-icons">close</i>
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              className={classes.flex}
            >
              Настройка цепи
            </Typography>
            {showConfirmButton && (
              <Button onClick={id ? updateChain : createChain} color="inherit">
                {id ? "Обновить" : "Создать"}
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <DialogContent>
          <TextField
            error={!validName}
            autoFocus
            margin="dense"
            label="Название"
            inputProps={{ name: "name" }}
            fullWidth
            value={name}
            onChange={setChainValue}
          />
          <TextField
            error={!validWebhook}
            margin="dense"
            label="Вебхук"
            inputProps={{ name: "webhook" }}
            fullWidth
            value={webhook}
            onChange={setChainValue}
          />
          {taskSelections.map((taskSelection, index) => {
            // TODO: Improve selections by removing filter in map
            const lastIndex = taskSelections.length - 1;
            const availableTasks = tasks.filter(
              t => t.id === taskSelection.id || !taskSelectionIds.has(t.id)
            );
            return (
              <div key={index} className="row">
                <div className="col-xs-7 col-md-7">
                  <ReactSelect
                    value={taskSelection.selection}
                    options={availableTasks.map(({ id, name }) => ({
                      value: id,
                      label: name
                    }))}
                    onChange={handleSelectChange(index)}
                  />
                </div>
                <div className="col-xs-2 col-md-2">
                  <TextField
                    margin="dense"
                    placeholder="Отложить (мин.)"
                    inputProps={{ "data-index": index }}
                    value={taskSelection.delay}
                    onChange={setDelayValue}
                  />
                </div>
                <div className="col-xs-1 col-md-1">
                  {index !== lastIndex && (
                    <Button
                      color="secondary"
                      onClick={downSelection}
                      data-index={index}
                      size="small"
                    >
                      <i className="material-icons">keyboard_arrow_down</i>
                    </Button>
                  )}
                </div>
                <div className="col-xs-1 col-md-1">
                  {index !== 0 && (
                    <Button
                      color="secondary"
                      onClick={upSelection}
                      data-index={index}
                      size="small"
                    >
                      <i className="material-icons">keyboard_arrow_up</i>
                    </Button>
                  )}
                </div>
                <div className="col-xs-1 col-md-1">
                  <Button
                    color="secondary"
                    onClick={dropSelection}
                    data-index={index}
                    size="small"
                  >
                    <i className="material-icons">delete</i>
                  </Button>
                </div>
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={addTaskSelection} color="primary">
            Добавить существующую задачу в цепь
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = () => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  }
});

export default withStyles(styles)(ChainDialog);
