import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import isURL from "validator/lib/isURL";

class TaskDialog extends React.PureComponent {
  validateMinutes = str => {
    return str.match(/^(([0-5])?([0-9]),){0,59}([0-5])?([0-9])?$/);
  };

  validateHours = str => {
    return str.match(
      /^((([0-1]?[0-9])|(2[0-3])),){0,23}(([0-1]?[0-9])|(2[0-3]))?$/
    );
  };

  validateDays = str => {
    return str.match(
      /^((([1-9])|([1-2][0-9])|(3[01])),){0,30}(([1-9])|([1-2][0-9])|(3[01]))?$/
    );
  };

  validateMonths = str => {
    return str.match(/^((([1-9])|(1[0-2])),){0,11}(([1-9])|(1[0-2]))?$/);
  };

  render() {
    const {
      showDialog,
      dialogAction,
      name,
      description,
      typeId,
      subId,
      maxTickets,
      sendPush,
      withSource,
      sourceUrl,
      periodic,
      types,
      subs,
      runMinutes,
      runHours,
      runDays,
      runMonths,
      severalTicketsPerTime,
      useSubscriptionFromResponse,
      handleChange,
      setMaxTickets,
      toggleSwitch,
      switchPeriodic,
      closeDialog,
      createTask,
      updateTask
    } = this.props;
    const validNames = [name, description].every(p => p && p.trim() !== "");
    const validMaxTickets = maxTickets >= 1 && maxTickets <= 1000;
    const validSource =
      !withSource ||
      (sourceUrl.trim() !== "" &&
        isURL(sourceUrl, {
          protocols: ["http", "https"],
          require_protocol: true
        }));
    const validMinutes = this.validateMinutes(runMinutes);
    const validHours = this.validateHours(runHours);
    const validDays = this.validateDays(runDays);
    const validMonths = this.validateMonths(runMonths);
    const showConfirmButton =
      validNames &&
      validMaxTickets &&
      validSource &&
      validMinutes &&
      validHours &&
      validDays &&
      validMonths;
    return (
      <Dialog open={showDialog} onClose={() => {}} fullWidth>
        <DialogTitle>Настройка задачи проекта</DialogTitle>
        <DialogContent>
          <TextField
            error={name === ""}
            autoFocus
            margin="dense"
            label="Название"
            fullWidth
            value={name}
            inputProps={{ name: "name" }}
            onChange={handleChange}
          />
          <TextField
            error={description === ""}
            margin="dense"
            label="Описание"
            fullWidth
            value={description}
            inputProps={{ name: "description" }}
            onChange={handleChange}
          />
          <FormControl fullWidth>
            <InputLabel htmlFor="ticket-type">Тип тикета</InputLabel>
            <Select
              value={typeId}
              onChange={handleChange}
              inputProps={{ name: "typeId", id: "ticket-type" }}
            >
              <MenuItem value="">
                <em>Нет</em>
              </MenuItem>
              {Object.keys(types).map(id => (
                <MenuItem key={id} value={id}>
                  {types[id]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="subscription">Подписка</InputLabel>
            <Select
              value={subId}
              onChange={handleChange}
              inputProps={{ name: "subId", id: "subscription" }}
            >
              <MenuItem value="">
                <em>Нет</em>
              </MenuItem>
              {Object.keys(subs).map(id => (
                <MenuItem key={id} value={id}>
                  {subs[id]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            error={!validMaxTickets}
            margin="dense"
            label="Максимальное количество тикетов (от 1 до 1000)"
            fullWidth
            value={maxTickets}
            onChange={setMaxTickets}
          />
          <FormControl className="col-md-4 col-sm-6">
            <FormControlLabel
              control={
                <Switch
                  checked={sendPush}
                  onChange={toggleSwitch("sendPush")}
                  value="checked"
                  color="primary"
                />
              }
              label="Отправлять пуш"
            />
          </FormControl>
          <FormControl className="col-md-4 col-sm-6">
            <FormControlLabel
              control={
                <Switch
                  checked={withSource}
                  onChange={toggleSwitch("withSource", ["sourceUrl"])}
                  value="checked"
                  color="primary"
                />
              }
              label="С запросом"
            />
          </FormControl>
          <FormControl className="col-md-4 col-sm-6">
            <FormControlLabel
              control={
                <Switch
                  checked={periodic}
                  onChange={switchPeriodic}
                  value="checked"
                  color="primary"
                />
              }
              label="Периодический"
            />
          </FormControl>
          {withSource && (
            <div>
              <TextField
                error={!validSource}
                margin="dense"
                label="URL источника"
                fullWidth
                value={sourceUrl}
                inputProps={{ name: "sourceUrl" }}
                onChange={handleChange}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={severalTicketsPerTime}
                    onChange={toggleSwitch("severalTicketsPerTime")}
                    value="checked"
                    color="primary"
                  />
                }
                label="Несколько тикетов за раз"
              />
              {severalTicketsPerTime && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={useSubscriptionFromResponse}
                      onChange={toggleSwitch("useSubscriptionFromResponse")}
                      value="checked"
                      color="primary"
                    />
                  }
                  label="Использовать подписку из ответа"
                />
              )}
            </div>
          )}
          {periodic && (
            <FormControl fullWidth>
              <TextField
                error={!validMinutes}
                margin="dense"
                fullWidth
                label="Минуты запуска"
                value={runMinutes}
                inputProps={{ name: "runMinutes" }}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                placeholder="Каждая минута"
              />
              <TextField
                error={!validHours}
                margin="dense"
                fullWidth
                label="Часы запуска"
                value={runHours}
                inputProps={{ name: "runHours" }}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                placeholder="Каждый час"
              />
              <TextField
                error={!validDays}
                margin="dense"
                fullWidth
                label="Дни запуска"
                value={runDays}
                inputProps={{ name: "runDays" }}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                placeholder="Каждый день"
              />
              <TextField
                error={!validMonths}
                margin="dense"
                fullWidth
                label="Месяцы запуска"
                value={runMonths}
                inputProps={{ name: "runMonths" }}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                placeholder="Каждый месяц"
              />
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Отменить
          </Button>
          {showConfirmButton &&
            dialogAction === "create" && (
              <Button onClick={createTask} color="primary">
                Создать
              </Button>
            )}
          {showConfirmButton &&
            dialogAction === "update" && (
              <Button onClick={updateTask} color="primary">
                Обновить
              </Button>
            )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default TaskDialog;
