import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import ChainTable from "../project_management/chains/ChainTable";
import RunDialog from "../project_management/chains/RunDialog";
import { connect } from "react-redux";
import { getList, run } from "../../api/chains";
import { showAlert } from "../../actions/layout";

class ChainsPage extends React.Component {
  state = {
    filter: "",
    isLoading: true,
    chains: [],
    tasks: [],
    selectedChain: null,
    showRunDialog: false,
    mark: ""
  };

  componentDidMount() {
    getList(this.props.currentProject.id)
      .then(({ data }) => this.setState({ ...data, isLoading: false }))
      .catch(error => console.log(error));
  }

  setTextValue = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  openRunDialog = e => {
    const id = parseInt(e.currentTarget.dataset.id);
    const { chains } = this.state;
    const chain = chains.find(c => c.id === id);
    this.setState({ selectedChain: chain, showRunDialog: true });
  };

  closeDialog = () => {
    this.setState({
      selectedChain: null,
      showRunDialog: false,
      mark: ""
    });
  };

  runChain = () => {
    const { selectedChain, mark } = this.state;
    const { currentProject, dispatchShowAlert } = this.props;
    run(currentProject.id, selectedChain.id, mark)
      .then(() => {
        this.setState({ selectedChain: null, showRunDialog: false, mark: "" });
        dispatchShowAlert({ color: "success", text: "Цепь успешно запущена" });
      })
      .catch(() => {
        dispatchShowAlert({ color: "error", text: "Ошибка запуска цепи" });
      });
  };

  render() {
    if (this.state.isLoading) return <LinearProgress />;
    const { filter, chains, showRunDialog, mark } = this.state;
    return (
      <div>
        <TextField
          style={{ margin: 8 }}
          placeholder="Строка фильтрации"
          helperText="Введите текст для фильтрации цепей по имени"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          inputProps={{ name: "filter" }}
          onChange={this.setTextValue}
          value={filter}
        />
        <ChainTable
          filter={filter}
          chains={chains}
          openRunDialog={this.openRunDialog}
        />
        <RunDialog
          mark={mark}
          showRunDialog={showRunDialog}
          setTextValue={this.setTextValue}
          closeDialog={this.closeDialog}
          runChain={this.runChain}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { currentProject } = state.layout;
  return { currentProject };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchShowAlert: payload => dispatch(showAlert(payload))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChainsPage);
