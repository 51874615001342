import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { hideReassignDialog } from "../../actions/tickets";

class ReassignDialog extends React.PureComponent {
  state = { users: [], selectedUser: null };

  componentDidUpdate(prevProps) {
    if (
      !prevProps.ticketToReassign &&
      prevProps.ticketToReassign !== this.props.ticketToReassign
    ) {
      this.getTicketUsers();
    }
  }

  getTicketUsers = () => {
    const { currentProject, ticketToReassign } = this.props;
    axios
      .get(`/${currentProject.id}/user_list/${ticketToReassign}`)
      .then(({ data }) => this.setState({ users: data }));
  };

  handleSelectChange = e => {
    const id = parseInt(e.target.value);
    const { users } = this.state;
    this.setState({ selectedUser: users.find(u => u.id === id) });
  };

  hideReassign = () => {
    this.setState({ users: [], selectedUser: null });
    this.props.dispatchHideReassignDialog();
  };

  reassignTicket = () => {
    const ticket_id = this.props.ticketToReassign;
    const user_id = this.state.selectedUser.id;
    App.tickets.doAction("reassign", { ticket_id, user_id });
    this.hideReassign();
  };

  render() {
    const { users, selectedUser } = this.state;
    return (
      <Dialog
        open={!!this.props.ticketToReassign}
        onClose={() => {}}
        aria-labelledby="form-reassign-title"
      >
        <DialogTitle id="form-reassign-title">
          Переназначить тикет {this.props.ticketToReassign}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Переназначить тикет другому пользователю, у которого его доступ к
            той же подписке
          </DialogContentText>
          <FormControl fullWidth>
            <Select
              value={(selectedUser && selectedUser.id) || ""}
              onChange={this.handleSelectChange}
            >
              {users.map(user => {
                return (
                  <MenuItem key={user.id} value={user.id}>
                    {user.email}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.hideReassign} color="primary">
            Отмена
          </Button>
          {selectedUser && (
            <Button onClick={this.reassignTicket} color="primary">
              Переназначить
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentProject: state.layout.currentProject,
    ticketToReassign: state.tickets.ticketToReassign
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchHideReassignDialog: () => dispatch(hideReassignDialog())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReassignDialog);
