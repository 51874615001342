// TODO: Maybe don't use reducers for smsaero and questquestbooking
import { handleActions } from "redux-actions";
import {
  getProjectFromLocalStorage,
  setProjectToLocalStorage,
  getShowSidebarTextFromLocalStorage,
  setShowSidebarTextToLocalStorage
} from "./helpers/layout";
import * as actions from "../actions/layout";

const initState = {
  currentProject: getProjectFromLocalStorage(),
  projectServices: [],
  showSidebarText: getShowSidebarTextFromLocalStorage(),
  textToCopy: { text: "" },
  alertText: "",
  alertColor: "info",
  notificationDrawerOpened: false,
  notificationsCounter: 0,
  poppedNotificationList: [],
  notificationDrawer: { offset: 0, list: [] },
  showAlertBar: false,
  showSmsAeroDialog: false,
  showQuestquestBookingDialog: false
};

const Layout = handleActions(
  {
    [actions.setCurrentProject]: (state, { payload }) => {
      setProjectToLocalStorage(payload);
      return { ...state, currentProject: payload };
    },
    [actions.setProjectServices]: (state, { payload }) => {
      return { ...state, projectServices: payload };
    },
    [actions.openNotificationDrawer]: state => {
      return { ...state, notificationDrawerOpened: true };
    },
    [actions.closeNotificationDrawer]: state => {
      return {
        ...state,
        notificationsCounter: 0,
        notificationDrawerOpened: false,
        poppedNotificationList: []
      };
    },
    [actions.toggleSidebarText]: state => {
      const newValue = !state.showSidebarText;
      setShowSidebarTextToLocalStorage(newValue);
      return { ...state, showSidebarText: newValue };
    },
    [actions.setTextToCopy]: (state, { payload }) => {
      return { ...state, textToCopy: { ...state.textToCopy, text: payload } };
    },
    [actions.showAlert]: (state, action) => {
      const { text, color } = action.payload;
      return {
        ...state,
        alertText: text,
        alertColor: color,
        showAlertBar: true
      };
    },
    [actions.hideAlert]: state => {
      return { ...state, alertText: "", showAlertBar: false };
    },
    [actions.setNotificationsCounter]: (state, { payload }) => {
      return { ...state, notificationsCounter: parseInt(payload) };
    },
    [actions.fillNotificationDrawer]: (state, { payload }) => {
      return {
        ...state,
        notificationDrawer: {
          ...state.notificationDrawer,
          list: payload
        }
      };
    },
    [actions.purgeNotificationDrawer]: state => {
      return { ...state, notificationDrawer: { offset: 0, list: [] } };
    },
    [actions.addPoppedNotification]: (state, { payload }) => {
      const {
        poppedNotificationList,
        notificationsCounter,
        notificationDrawer,
        notificationDrawerOpened
      } = state;
      const newNotificationDrawerList = notificationDrawerOpened
        ? [payload, ...notificationDrawer.list]
        : notificationDrawer.list;
      return {
        ...state,
        notificationsCounter: notificationsCounter + 1,
        poppedNotificationList: [payload, ...poppedNotificationList],
        notificationDrawer: {
          ...notificationDrawer,
          list: newNotificationDrawerList
        }
      };
    },
    [actions.removePoppedNotification]: (state, { payload }) => {
      const id = parseInt(payload);
      const poppedNotificationList = state.poppedNotificationList.filter(
        notification => notification.id !== id
      );
      return { ...state, poppedNotificationList: poppedNotificationList };
    },
    [actions.showSmsAeroDialog]: state => {
      return { ...state, showSmsAeroDialog: true };
    },
    [actions.hideSmsAeroDialog]: state => {
      return { ...state, showSmsAeroDialog: false };
    },
    [actions.showQuestquestBookingDialog]: state => {
      return { ...state, showQuestquestBookingDialog: true };
    },
    [actions.hideQuestquestBookingDialog]: state => {
      return { ...state, showQuestquestBookingDialog: false };
    }
  },
  initState
);

export default Layout;
