export const getProjectFromLocalStorage = () => {
  const project = localStorage.getItem("project");
  return project ? JSON.parse(project) : null;
};

export const setProjectToLocalStorage = project => {
  localStorage.setItem("project", JSON.stringify(project));
};

export const getShowSidebarTextFromLocalStorage = () => {
  if (!localStorage.getItem("show_sidebar_text")) return true;
  return localStorage.getItem("show_sidebar_text") === 'true';
}

export const setShowSidebarTextToLocalStorage = (newValue) => {
  localStorage.setItem("show_sidebar_text", newValue);
}
