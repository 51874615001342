import React, { PureComponent } from "react";
import TextField from "@material-ui/core/TextField";

class Text extends PureComponent {
  change = ({ target }) => {
    const { name, setValue } = this.props;
    const { value } = target;
    setValue(name, value);
  };

  render() {
    const { title, name, value } = this.props;
    return (
      <TextField
        margin="dense"
        label={title}
        inputProps={{ name }}
        fullWidth
        value={value || ""}
        onChange={this.change}
      />
    );
  }
}

export default Text;
