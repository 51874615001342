import { createActions } from "redux-actions";

export const {
  setCurrentProject,
  setProjectServices,
  toggleSidebarText,
  showAlert,
  hideAlert,
  setNotificationsCounter,
  fillNotificationDrawer,
  purgeNotificationDrawer,
  loadLastNotifications,
  addPoppedNotification,
  removePoppedNotification,
  openNotificationDrawer,
  closeNotificationDrawer,
  showSmsAeroDialog,
  hideSmsAeroDialog,
  showQuestquestBookingDialog,
  hideQuestquestBookingDialog,
  setTextToCopy,
  kick
} = createActions(
  "SET_CURRENT_PROJECT",
  "SET_PROJECT_SERVICES",
  "TOGGLE_SIDEBAR_TEXT",
  "SHOW_ALERT",
  "HIDE_ALERT",
  "SET_NOTIFICATIONS_COUNTER",
  "FILL_NOTIFICATION_DRAWER",
  "PURGE_NOTIFICATION_DRAWER",
  "LOAD_LAST_NOTIFICATIONS",
  "ADD_POPPED_NOTIFICATION",
  "REMOVE_POPPED_NOTIFICATION",
  "OPEN_NOTIFICATION_DRAWER",
  "CLOSE_NOTIFICATION_DRAWER",
  "SHOW_SMS_AERO_DIALOG",
  "HIDE_SMS_AERO_DIALOG",
  "SHOW_QUESTQUEST_BOOKING_DIALOG",
  "HIDE_QUESTQUEST_BOOKING_DIALOG",
  "SET_TEXT_TO_COPY",
  "KICK"
);
