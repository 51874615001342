import { applyMiddleware } from "redux";
import notificationDrawerOpening from "./notificationDrawerOpening";
import lastNotificationsLoading from "./lastNotificationsLoading";
import showNote from "./showNote";
import saveNote from "./saveNote";
import refreshTickets from "./refreshTickets";
import showInfo from "./showInfo";
import kickUser from "./kickUser";

export default applyMiddleware(
  notificationDrawerOpening,
  lastNotificationsLoading,
  showNote,
  saveNote,
  refreshTickets,
  showInfo,
  kickUser
);
