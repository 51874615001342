import { handleActions } from "redux-actions";
import {
  buildTypes,
  addTicket,
  removeTicket,
  removeTicketGroup,
  updateTicket
} from "./helpers/tickets";
import * as actions from "../actions/tickets";

const initState = {
  channelConnected: false,
  current: {},
  delayed: {},
  other: {},
  found: {},
  currentTypes: {},
  delayedTypes: {},
  foundTypes: {},
  currentTicketsFilter: "",
  delayedTicketsFilter: "",
  foundTicketsFilter: "",
  currentTicketsShowFree: true,
  delayedTicketsShowFree: true,
  currentTicketsShowOwned: true,
  delayedTicketsShowOwned: true,
  basicCollectionIsLoading: true,
  otherCollectionIsLoading: true,
  showFound: false,
  currentTicketPageNumber: 1,
  delayedTicketPageNumber: 1,
  otherTicketPageNumber: 1,
  foundTicketPageNumber: 1,
  ticketToClose: null,
  ticketToDelay: null,
  ticketToComment: { id: null, comment: "" },
  ticketToReassign: null,
  ticketWithClickedButton: { id: null, name: "", fields: "", savedValues: {} },
  ticketToEdit: { id: null, name: "", value: "" }
};

const tickets = handleActions(
  {
    [actions.setChannelConnected]: state => {
      return { ...state, channelConnected: true };
    },
    [actions.setChannelDisconnected]: () => {
      return { ...initState };
    },
    [actions.addBasicCollection]: (state, action) => {
      const { current, delayed } = action.payload;
      return {
        ...state,
        current: current,
        delayed: delayed,
        currentTypes: buildTypes(current),
        delayedTypes: buildTypes(delayed),
        basicCollectionIsLoading: false
      };
    },
    [actions.addCurrentTicket]: (state, { payload }) => {
      const { coll, types } = addTicket(state.current, payload);
      const newFound = updateTicket(state.found, payload);
      return { ...state, current: coll, currentTypes: types, found: newFound };
    },
    [actions.addDelayedTicket]: (state, { payload }) => {
      const { coll, types } = addTicket(state.delayed, payload);
      const newFound = updateTicket(state.found, payload);
      return { ...state, delayed: coll, delayedTypes: types, found: newFound };
    },
    [actions.removeTicket]: (state, action) => {
      const id = action.payload;
      const { current, delayed, other, found } = state;
      const newCurrent = removeTicket(current, id, state.currentTypes);
      const newDelayed = removeTicket(delayed, id, state.delayedTypes);
      const newOther = removeTicket(other, id);
      const newFound = removeTicket(found, id, state.foundTypes);
      return {
        ...state,
        current: newCurrent.coll,
        delayed: newDelayed.coll,
        other: newOther.coll,
        found: newFound.coll,
        currentTypes: newCurrent.types,
        delayedTypes: newDelayed.types,
        foundTypes: newFound.types
      };
    },
    [actions.moveCurrentTicketToDelayed]: (state, { payload }) => {
      const { current, delayed, currentTypes } = state;
      const newCurrent = removeTicket(current, payload.id, currentTypes);
      const newDelayed = addTicket(delayed, payload);
      return {
        ...state,
        current: newCurrent.coll,
        delayed: newDelayed.coll,
        currentTypes: newCurrent.types,
        delayedTypes: newDelayed.types
      };
    },
    [actions.moveDelayedTicketToCurrent]: (state, { payload }) => {
      const { current, delayed, delayedTypes } = state;
      const newCurrent = addTicket(current, payload);
      const newDelayed = removeTicket(delayed, payload.id, delayedTypes);
      return {
        ...state,
        current: newCurrent.coll,
        delayed: newDelayed.coll,
        currentTypes: newCurrent.types,
        delayedTypes: newDelayed.types
      };
    },
    [actions.moveTicketToOther]: (state, { payload }) => {
      const { current, delayed, other, currentTypes, delayedTypes } = state;
      const newCurrent = removeTicket(current, payload.id, currentTypes);
      const newDelayed = removeTicket(delayed, payload.id, delayedTypes);
      const newOther = state.otherCollectionIsLoading
        ? { coll: {} }
        : addTicket(other, payload);
      const newFound = updateTicket(state.found, payload);
      return {
        ...state,
        current: newCurrent.coll,
        delayed: newDelayed.coll,
        other: newOther.coll,
        found: newFound,
        currentTypes: newCurrent.types,
        delayedTypes: newDelayed.types
      };
    },
    [actions.moveOtherTicketToCurrent]: (state, { payload }) => {
      const { current, other } = state;
      const newCurrent = addTicket(current, payload);
      const newOther = removeTicket(other, payload.id);
      return {
        ...state,
        current: newCurrent.coll,
        other: newOther.coll,
        currentTypes: newCurrent.types
      };
    },
    [actions.moveOtherTicketToDelayed]: (state, { payload }) => {
      const { delayed, other } = state;
      const newDelayed = addTicket(delayed, payload);
      const newOther = removeTicket(other, payload.id);
      return {
        ...state,
        delayed: newDelayed.coll,
        other: newOther.coll,
        delayedTypes: newDelayed.types
      };
    },
    [actions.setFilter]: (state, action) => {
      const key = `${action.payload.page}TicketsFilter`;
      return { ...state, [key]: action.payload.type };
    },
    [actions.toggleShowFree]: (state, { payload }) => {
      const key = `${payload}TicketsShowFree`;
      const currentVal = state[key];
      return { ...state, [key]: !currentVal };
    },
    [actions.toggleShowOwned]: (state, { payload }) => {
      const key = `${payload}TicketsShowOwned`;
      const currentVal = state[key];
      return { ...state, [key]: !currentVal };
    },
    [actions.setLoadingState]: (state, action) => {
      const key = `${action.payload}CollectionIsLoading`;
      return { ...state, [key]: true };
    },
    [actions.addOtherTicketCollection]: (state, { payload }) => {
      return { ...state, other: payload, otherCollectionIsLoading: false };
    },
    [actions.resetOtherTicketCollection]: state => {
      return { ...state, other: {}, otherCollectionIsLoading: true };
    },
    [actions.addFoundTicketCollection]: (state, { payload }) => {
      return {
        ...state,
        found: payload,
        foundTypes: buildTypes(payload),
        showFound: true
      };
    },
    [actions.hideFound]: state => {
      return {
        ...state,
        found: {},
        foundTypes: {},
        foundTicketsFilter: "",
        showFound: false
      };
    },
    [actions.showCloseDialog]: (state, action) => {
      return { ...state, ticketToClose: action.payload };
    },
    [actions.hideCloseDialog]: state => {
      return { ...state, ticketToClose: null };
    },
    [actions.showDelayDialog]: (state, action) => {
      return { ...state, ticketToDelay: action.payload };
    },
    [actions.hideDelayDialog]: state => {
      return { ...state, ticketToDelay: null };
    },
    [actions.showCommentDialog]: (state, action) => {
      return { ...state, ticketToComment: action.payload };
    },
    [actions.setTicketComment]: (state, { payload }) => {
      return {
        ...state,
        ticketToComment: { ...state.ticketToComment, comment: payload }
      };
    },
    [actions.hideCommentDialog]: state => {
      return { ...state, ticketToComment: { id: null, comment: "" } };
    },
    [actions.showReassignDialog]: (state, { payload }) => {
      return { ...state, ticketToReassign: payload };
    },
    [actions.hideReassignDialog]: state => {
      return { ...state, ticketToReassign: null };
    },
    [actions.showButtonClickDialog]: (state, { payload }) => {
      return {
        ...state,
        ticketWithClickedButton: {
          ...state.ticketWithClickedButton,
          ...payload
        }
      };
    },
    [actions.hideButtonClickDialog]: (state, { payload }) => {
      const savedValues = payload || {};
      return {
        ...state,
        ticketWithClickedButton: { id: null, name: "", fields: "", savedValues }
      };
    },
    [actions.showTicketFieldEditingDialog]: (state, { payload }) => {
      return { ...state, ticketToEdit: { ...payload } };
    },
    [actions.hideTicketFieldEditingDialog]: state => {
      return { ...state, ticketToEdit: { id: null, name: "", value: "" } };
    },
    [actions.removeTicketGroup]: (state, { payload }) => {
      const {
        current,
        delayed,
        other,
        found,
        currentTypes,
        delayedTypes,
        foundTypes
      } = state;
      const removingIds = payload.map(id => id.toString());
      const newCurrent = removeTicketGroup(current, removingIds, currentTypes);
      const newDelayed = removeTicketGroup(delayed, removingIds, delayedTypes);
      const newOther = removeTicketGroup(other, removingIds);
      const newFound = removeTicketGroup(found, removingIds, foundTypes);
      return {
        ...state,
        current: newCurrent.coll,
        delayed: newDelayed.coll,
        other: newOther.coll,
        found: newFound.coll,
        currentTypes: newCurrent.types,
        delayedTypes: newDelayed.types,
        foundTypes: newFound.types
      };
    },
    [actions.updateTicket]: (state, { payload }) => {
      const current = updateTicket(state.current, payload);
      const delayed = updateTicket(state.delayed, payload);
      const other = updateTicket(state.other, payload);
      const found = updateTicket(state.found, payload);
      return { ...state, current, delayed, other, found };
    },
    [actions.setPageNumber]: (state, { payload }) => {
      const { name, num } = payload;
      const key = `${name}TicketPageNumber`;
      return { ...state, [key]: num };
    }
  },
  initState
);

export default tickets;
