import { createActions } from "redux-actions";

export const {
  showInfoRequest,
  showInfoSuccess,
  showInfoError,
  hideFieldInfo
} = createActions(
  "SHOW_INFO_REQUEST",
  "SHOW_INFO_SUCCESS",
  "SHOW_INFO_ERROR",
  "HIDE_FIELD_INFO"
);
