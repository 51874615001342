import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import CreateButton from "../../CreateButton";
import TextField from "@material-ui/core/TextField";
import { get, create, update, destroy, run, stop } from "../../../api/tasks";
import TaskTable from "./TaskTable";
import TaskDialog from "./TaskDialog";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";

const emptyTaskDialog = {
  showDialog: false,
  dialogAction: null,
  id: null,
  name: "",
  description: "",
  typeId: "",
  subId: "",
  maxTickets: 1000,
  sendPush: false,
  withSource: false,
  sourceUrl: "",
  periodic: false,
  runMinutes: "",
  runHours: "",
  runDays: "",
  runMonths: "",
  severalTicketsPerTime: false,
  useSubscriptionFromResponse: false
};

const emptyDeleteConfirmation = {
  showDeleteConfirmation: false,
  id: null,
  name: ""
};

class Tasks extends React.Component {
  state = {
    isLoading: true,
    filter: "",
    tasks: [],
    types: {},
    subscriptions: {},
    taskDialogValues: emptyTaskDialog,
    deleteConfirmationValues: emptyDeleteConfirmation
  };

  componentDidMount() {
    get(this.props.projectId)
      .then(({ data }) => this.setState({ ...data, isLoading: false }))
      .catch(error => console.log(error));
  }

  newTask = () => {
    this.setState({
      taskDialogValues: {
        ...emptyTaskDialog,
        showDialog: true,
        dialogAction: "create"
      }
    });
  };

  createTask = () => {
    const { tasks, taskDialogValues } = this.state;
    create(this.props.projectId, taskDialogValues)
      .then(({ data }) => {
        this.setState({
          tasks: [...tasks, data],
          taskDialogValues: emptyTaskDialog
        });
      })
      .catch(error => console.log(error));
  };

  editTask = e => {
    const id = parseInt(e.currentTarget.dataset.id);
    const task = this.state.tasks.find(t => t.id === id);
    this.setState({
      taskDialogValues: {
        ...emptyTaskDialog,
        showDialog: true,
        dialogAction: "update",
        id: task.id,
        name: task.name,
        description: task.description,
        typeId: (task.ticket_type && task.ticket_type.toString()) || "",
        subId: (task.subscription && task.subscription.toString()) || "",
        maxTickets: task.max_tickets,
        sendPush: task.send_push,
        withSource: task.with_source,
        sourceUrl: task.source_url,
        periodic: task.periodic,
        runMinutes: task.run_minutes.join(","),
        runHours: task.run_hours.join(","),
        runDays: task.run_days.join(","),
        runMonths: task.run_months.join(","),
        severalTicketsPerTime: task.several_tickets_per_time,
        useSubscriptionFromResponse: task.use_subscription_from_response
      }
    });
  };

  updateTask = () => {
    const { tasks, taskDialogValues } = this.state;
    update(this.props.projectId, taskDialogValues)
      .then(({ data }) => {
        const index = tasks.findIndex(t => t.id === taskDialogValues.id);
        tasks[index] = data;
        this.setState({ tasks: [...tasks], taskDialogValues: emptyTaskDialog });
      })
      .catch(error => console.log(error));
  };

  confirmDelete = e => {
    const id = parseInt(e.currentTarget.dataset.id);
    const { name } = this.state.tasks.find(t => t.id === id);
    this.setState({
      deleteConfirmationValues: {
        ...emptyDeleteConfirmation,
        showDeleteConfirmation: true,
        id,
        name
      }
    });
  };

  destroyTask = () => {
    const { tasks, deleteConfirmationValues } = this.state;
    destroy(this.props.projectId, deleteConfirmationValues.id)
      .then(() =>
        this.setState({
          tasks: tasks.filter(t => t.id !== deleteConfirmationValues.id),
          deleteConfirmationValues: emptyDeleteConfirmation
        })
      )
      .catch(error => console.log(error));
  };

  runTask = e => {
    const id = parseInt(e.currentTarget.dataset.id);
    run(this.props.projectId, id)
      .then(() => {})
      .catch(error => console.log(error));
  };

  stopTask = e => {
    const id = parseInt(e.currentTarget.dataset.id);
    stop(this.props.projectId, id)
      .then(() => {})
      .catch(error => console.log(error));
  };

  closeDialogs = () => {
    this.setState({
      taskDialogValues: emptyTaskDialog,
      deleteConfirmationValues: emptyDeleteConfirmation
    });
  };

  handleChange = e => {
    let { name, value } = e.target;
    if (typeof value === "string")
      value = value.replace(/^\s+/, "").replace(/\s{2,}/g, " ");
    this.setState({
      taskDialogValues: { ...this.state.taskDialogValues, [name]: value }
    });
  };

  setMaxTickets = ({ target }) => {
    if (!target.value.match(/^\d+$/)) return;
    const maxTickets = parseInt(target.value);
    this.setState({
      taskDialogValues: { ...this.state.taskDialogValues, maxTickets }
    });
  };

  toggleSwitch = (name, paramsToClear = []) => () => {
    const { taskDialogValues } = this.state;
    const newValue = !taskDialogValues[name];
    const dialogValues = { ...taskDialogValues, [name]: newValue };
    paramsToClear.forEach(param => (dialogValues[param] = ""));
    this.setState({ taskDialogValues: dialogValues });
  };

  switchPeriodic = () => {
    const isPeriodic = !this.state.taskDialogValues.periodic;
    this.setState({
      taskDialogValues: {
        ...this.state.taskDialogValues,
        periodic: isPeriodic,
        active: isPeriodic ? this.state.taskDialogValues.active : false,
        runMinutes: "",
        runHours: "",
        runDays: "",
        runMonths: ""
      }
    });
  };

  setFilter = e => {
    this.setState({ filter: e.target.value });
  };

  render() {
    if (this.state.isLoading) return <LinearProgress />;
    const {
      filter,
      taskDialogValues,
      deleteConfirmationValues,
      tasks,
      types,
      subscriptions
    } = this.state;
    return (
      <div>
        <TextField
          style={{ margin: 8 }}
          placeholder="Строка фильтрации"
          helperText="Введите текст для фильтрации задач по имени и описанию"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          inputProps={{ name: "filter" }}
          onChange={this.setFilter}
          value={filter}
        />
        <TaskTable
          filter={filter}
          tasks={tasks}
          types={types}
          subs={subscriptions}
          edit={this.editTask}
          destroy={this.confirmDelete}
          run={this.runTask}
          stop={this.stopTask}
        />
        <TaskDialog
          {...taskDialogValues}
          types={types}
          subs={subscriptions}
          handleChange={this.handleChange}
          setMaxTickets={this.setMaxTickets}
          toggleSwitch={this.toggleSwitch}
          switchPeriodic={this.switchPeriodic}
          closeDialog={this.closeDialogs}
          createTask={this.createTask}
          updateTask={this.updateTask}
        />
        <DeleteConfirmationDialog
          show={deleteConfirmationValues.showDeleteConfirmation}
          text={`Вы действительно хотите удалить задачу "${
            deleteConfirmationValues.name
          }"?`}
          cancelCallback={this.closeDialogs}
          confirmCallback={this.destroyTask}
        />
        <CreateButton callback={this.newTask} title="Добавить задачу" />
      </div>
    );
  }
}

export default Tasks;
