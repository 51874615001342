import React from "react";
import CreateButton from "../../CreateButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import TypeTable from "./TypeTable";
import TypeDialog from "./TypeDialog";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";
import { get, create, update, destroy } from "../../../api/types";

const emptyTypeDialog = {
  show: false,
  id: null,
  name: "",
  close_webhook: "",
  delay_webhook: "",
  ticket_limit: 0,
  send_push: false,
  can_close: true,
  can_delay: true,
  can_take: false,
  can_reassign: false,
  fields: [],
  buttons: [],
  use_tries: false,
  max_tries: 3,
  tries_intermediate_webhook: "",
  tries_last_webhook: "",
  tries_terminal_webhook: ""
};

const emptyDeleteConfirmation = { show: false, id: null, name: "" };

class Types extends React.PureComponent {
  state = {
    isLoading: true,
    filter: "",
    types: [],
    typeDialogValues: emptyTypeDialog,
    deleteConfirmationValues: emptyDeleteConfirmation
  };

  componentDidMount() {
    get(this.props.projectId)
      .then(({ data }) => this.setState({ types: data, isLoading: false }))
      .catch(error => console.log(error));
  }

  newType = () => {
    this.setState({ typeDialogValues: { ...emptyTypeDialog, show: true } });
  };

  createType = params => {
    create(this.props.projectId, params)
      .then(({ data }) => {
        this.setState({ types: data, typeDialogValues: emptyTypeDialog });
      })
      .catch(error => console.log(error));
  };

  editType = e => {
    const id = parseInt(e.currentTarget.dataset.id);
    const { types } = this.state;
    const type = types.find(t => t.id === id);
    this.setState({
      typeDialogValues: { ...emptyTypeDialog, show: true, ...type }
    });
  };

  updateType = params => {
    update(this.props.projectId, params)
      .then(({ data }) => {
        this.setState({ types: data, typeDialogValues: emptyTypeDialog });
      })
      .catch(error => console.log(error));
  };

  confirmDelete = e => {
    const id = parseInt(e.currentTarget.dataset.id);
    const { name } = this.state.types.find(t => t.id === id);
    this.setState({
      deleteConfirmationValues: {
        ...emptyDeleteConfirmation,
        show: true,
        id,
        name
      }
    });
  };

  destroyType = () => {
    const { id } = this.state.deleteConfirmationValues;
    destroy(this.props.projectId, id)
      .then(() => {
        this.setState({
          types: this.state.types.filter(t => t.id !== id),
          deleteConfirmationValues: emptyDeleteConfirmation
        });
      })
      .catch(error => console.log(error));
  };

  closeDialogs = () => {
    this.setState({
      typeDialogValues: emptyTypeDialog,
      deleteConfirmationValues: emptyDeleteConfirmation
    });
  };

  setFilter = e => {
    this.setState({ filter: e.target.value });
  };

  render() {
    if (this.state.isLoading) return <LinearProgress />;
    const {
      filter,
      types,
      typeDialogValues,
      deleteConfirmationValues
    } = this.state;
    return (
      <div>
        <TextField
          style={{ margin: 8 }}
          placeholder="Строка фильтрации"
          helperText="Введите текст для фильтрации типа по имени"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          onChange={this.setFilter}
          value={filter}
        />
        <TypeTable
          filter={filter}
          types={types}
          editType={this.editType}
          destroyType={this.confirmDelete}
        />
        <TypeDialog
          values={typeDialogValues}
          closeDialog={this.closeDialogs}
          createType={this.createType}
          updateType={this.updateType}
        />
        <DeleteConfirmationDialog
          show={deleteConfirmationValues.show}
          text={`Вы действительно хотите удалить тип "${
            deleteConfirmationValues.name
          }"? Все тикеты данного типа будут удалены из проекта.`}
          cancelCallback={this.closeDialogs}
          confirmCallback={this.destroyType}
        />
        <CreateButton callback={this.newType} title="Создать тип" />
      </div>
    );
  }
}

export default Types;
