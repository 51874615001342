import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "material-ui-pickers/utils/date-fns-utils";
import DateTimePicker from "material-ui-pickers/DateTimePicker";
import MuiPickersUtilsProvider from "material-ui-pickers/utils/MuiPickersUtilsProvider";
import ruLocale from "date-fns/locale/ru";
import isDate from "date-fns/isDate";
import { hideButtonClickDialog } from "../../actions/tickets";

class ButtonClickDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { values: this.buildFieldValues() };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ values: this.buildFieldValues() });
    }
  }

  buildFieldValues = () => {
    const { fields, savedValues } = this.props;
    return fields.split(",").reduce((acc, name) => {
      if (savedValues[name]) {
        acc[name] = savedValues[name];
      } else if (name.startsWith("@")) {
        acc[name.slice(1)] = new Date();
      } else {
        acc[name] = "";
      }
      return acc;
    }, {});
  };

  setTextValue = e => {
    const { values } = this.state;
    const { name, value } = e.target;
    this.setState({ values: { ...values, [name]: value } });
  };

  setDate = name => date => {
    const { values } = this.state;
    this.setState({ values: { ...values, [name]: date } });
  };

  hideButtonClick = (savedValues = {}) => {
    this.props.dispatchHideButtonClickDialog(savedValues);
  };

  cancel = () => {
    const { values } = this.state;
    this.hideButtonClick(values);
  };

  confirm = () => {
    const { id, name } = this.props;
    const { values } = this.state;
    App.tickets.doAction("button_click", { id, name, fields: values });
    this.hideButtonClick({});
  };

  confirmByEnter = ({ key }) => {
    if (key !== "Enter") return;
    this.confirm();
  };

  render() {
    const { id } = this.props;
    const { values } = this.state;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
        <Dialog
          open={!!id}
          onClose={() => {}}
          aria-labelledby="form-button-click-title"
        >
          <DialogTitle id="form-button-click-title">
            {`Подтверждение действия тикета ${id}`}
          </DialogTitle>
          <DialogContent>
            {Object.keys(values).map((name, index) => (
              <div key={name}>
                {isDate(values[name]) ? (
                  <DateTimePicker
                    value={values[name]}
                    onChange={this.setDate(name)}
                    ampm={false}
                    okLabel="OK"
                    cancelLabel="Отмена"
                  />
                ) : (
                  <TextField
                    autoFocus={index == 0}
                    key={name}
                    placeholder={name}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ name: name }}
                    onChange={this.setTextValue}
                    value={values[name]}
                    onKeyUp={this.confirmByEnter}
                  />
                )}
              </div>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.cancel} color="primary">
              Отмена
            </Button>
            <Button onClick={this.confirm} color="primary">
              Подтвердить
            </Button>
          </DialogActions>
        </Dialog>
      </MuiPickersUtilsProvider>
    );
  }
}

function mapStateToProps(state) {
  const {
    id,
    name,
    fields,
    savedValues
  } = state.tickets.ticketWithClickedButton;
  return { id, name, fields, savedValues };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchHideButtonClickDialog: savedValues =>
      dispatch(hideButtonClickDialog(savedValues))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonClickDialog);
