import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import format from "date-fns/format";
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { showNoteRequest } from "../../actions/notes";
import {
  closeNotificationDrawer,
  purgeNotificationDrawer,
  loadLastNotifications
} from "../../actions/layout";

class NotificationDrawer extends PureComponent {
  showNote = ({ currentTarget }) => {
    this.props.dispatchShowNoteRequest(currentTarget.innerText);
  };

  render() {
    const {
      classes,
      notificationDrawerOpened,
      dispatchCloseNotificationDrawer,
      dispatchPurgeNotificationDrawer,
      dispatchLoadLastNotifications,
      notificationDrawer
    } = this.props;
    const { offset, list } = notificationDrawer;
    return (
      <Drawer
        anchor="right"
        open={notificationDrawerOpened}
        onClose={dispatchCloseNotificationDrawer}
      >
        <i
          className={`${
            classes.closeNotificationButton
          } material-icons btn p-0`}
          title="Закрыть"
          onClick={dispatchCloseNotificationDrawer}
        >
          close
        </i>
        <div className={classes.adjuster} />
        <Typography className={classes.title} component="p" variant="title">
          Последние уведомления
        </Typography>
        {list.length > 0 ? (
          <Fragment>
            <div className={classes.clearNotificationDrawerButton}>
              <span
                className="pointer"
                onClick={dispatchPurgeNotificationDrawer}
              >
                <i className={"material-icons btn p-0"}>delete</i>
                Очистить
              </span>
            </div>
            {list.map(({ id, text, created_at, options }) => {
              const parsedOptions = JSON.parse(options)
              const { note, notes, link, links } = parsedOptions;
              const date = new Date(created_at);
              return (
                <Paper key={id} className={classes.notification}>
                  <small>{format(date, "HH:mm:ss dd.MM.yyy")}</small>
                  <Typography component="p">{text}</Typography>
                  <div>
                    {note && note !== "" ? (
                      <span
                        className="pointer mr-1"
                        title={`Открыть заметку ${note}`}
                      >
                        <i className="material-icons btn p-0">assignment</i>
                        <span onClick={this.showNote}>{note}</span>
                      </span>
                    ) : null}
                    {notes && notes.length > 0
                      ? notes.map((note, index) => (
                          <span
                            className="pointer mr-1"
                            key={index}
                            title={`Открыть заметку ${note}`}
                          >
                            <i className={"material-icons btn p-0"}>
                              assignment
                            </i>
                            <span onClick={this.showNote}>{note}</span>
                          </span>
                        ))
                      : null}
                  </div>
                  <div>
                    {link ? (
                      <span className="mr-1">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={link.url}
                        >
                          {link.title || "Перейти"}
                        </a>
                      </span>
                    ) : null}
                    {links && links.length > 0
                      ? links.map((link, index) => (
                          <span key={index} className="mr-1">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={link.url}
                            >
                              {link.title || "Перейти"}
                            </a>
                          </span>
                        ))
                      : null}
                  </div>
                </Paper>
              );
            })}
          </Fragment>
        ) : (
          <Fragment>
            <Typography className={classes.title} component="div">
              <div>Нет непрочитанных уведомлений</div>
              <div
                className={`${classes.notification} pointer`}
                onClick={dispatchLoadLastNotifications}
              >
                <i className={"material-icons btn p-0 mr-1"}>history</i>
                Загрузить последние
              </div>
            </Typography>
          </Fragment>
        )}
      </Drawer>
    );
  }
}

function mapStateToProps(state) {
  const { notificationDrawerOpened, notificationDrawer } = state.layout;
  return { notificationDrawerOpened, notificationDrawer };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchShowNoteRequest: noteName => dispatch(showNoteRequest(noteName)),
    dispatchCloseNotificationDrawer: () => dispatch(closeNotificationDrawer()),
    dispatchPurgeNotificationDrawer: () => dispatch(purgeNotificationDrawer()),
    dispatchLoadLastNotifications: () => {
      dispatch(loadLastNotifications());
    }
  };
}

const styles = theme => ({
  adjuster: {
    width: "320px",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  title: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    width: "100%"
  },
  notification: {
    padding: theme.spacing.unit * 1.2,
    margin: "8px auto 0px",
    width: "270px",
    [theme.breakpoints.down("sm")]: {
      width: "90%"
    }
  },
  clearNotificationDrawerButton: {
    width: "100%",
    textAlign: "center"
  },
  closeNotificationButton: {
    position: "absolute",
    top: "-5px",
    right: "0px",
    fontWeight: 800,
    fontSize: "28px"
  }
});

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NotificationDrawer);
