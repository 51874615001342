import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

class ChainTable extends React.PureComponent {
  render() {
    const {
      classes,
      filter,
      chains,
      editChain,
      destroyChain,
      openRunDialog
    } = this.props;
    const filterRegexp = new RegExp(
      filter.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"),
      "i"
    );
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Название</TableCell>
              <TableCell numeric>Количество задач</TableCell>
              {editChain && <TableCell numeric>Изменить</TableCell>}
              {destroyChain && <TableCell numeric>Удалить</TableCell>}
              <TableCell numeric>Запустить</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chains.filter(c => filterRegexp.test(c.name)).map(chain => (
              <TableRow key={chain.id}>
                <TableCell>{chain.name}</TableCell>
                <TableCell numeric>{chain.task_size}</TableCell>
                {editChain && (
                  <TableCell numeric>
                    <Button
                      color="secondary"
                      onClick={editChain}
                      data-id={chain.id}
                      size="small"
                    >
                      <i className="material-icons">build</i>
                    </Button>
                  </TableCell>
                )}
                {destroyChain && (
                  <TableCell numeric>
                    <Button
                      color="secondary"
                      onClick={destroyChain}
                      data-id={chain.id}
                      size="small"
                    >
                      <i className="material-icons">delete</i>
                    </Button>
                  </TableCell>
                )}
                <TableCell numeric>
                  <Button
                    color="primary"
                    onClick={openRunDialog}
                    data-id={chain.id}
                    size="small"
                  >
                    <i className="material-icons">play_arrow</i>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    marginBottom: theme.spacing.unit * 3
  },
  table: {
    minWidth: 700
  }
});

export default withStyles(styles)(ChainTable);
