import React from "react";
import { connect } from "react-redux";
import CopyText from "react-copy-text";
import { showAlert } from "../actions/layout";

export class TextCopyWrapper extends React.PureComponent {
  state = { text: "" };

  onCopied = text =>
    this.props.dispatchShowAlert({
      color: "success",
      text: `Скопировано ${text}`
    });

  // cdu with sequence of two setState() needed because
  // CopyText recopy value only if text prop is changed.
  // It will not copy string again if props are equal
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const { text } = this.props.textToCopy;
      this.setState({ text: "" }, () => this.setState({ text }));
    }
  }

  render() {
    return <CopyText text={this.state.text} onCopied={this.onCopied} />;
  }
}

function mapStateToProps(state) {
  return { textToCopy: state.layout.textToCopy };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchShowAlert: payload => dispatch(showAlert(payload))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TextCopyWrapper);
