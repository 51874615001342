import React, { PureComponent } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

class Bool extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { value: false };
  }

  change = () => {
    const { name, setValue } = this.props;
    const value = !this.state.value;
    // TODO: Check in parent
    this.setState({ value }, () => setValue(name, value));
  };

  render() {
    const { title, name } = this.props;
    const { value } = this.state;
    return (
      <FormControlLabel
        key={name}
        control={
          <Checkbox
            checked={value}
            inputProps={{ name }}
            onChange={this.change}
          />
        }
        label={title}
      />
    );
  }
}

export default Bool;
