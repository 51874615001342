import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Input from "@material-ui/core/Input";

class TicketSearchField extends React.Component {
  state = { inputValue: "" };

  changeValue = e => {
    this.setState({ inputValue: e.target.value });
  };

  performSearch = ({ key }) => {
    if (key === "Enter" && this.state.inputValue.trim() !== "")
      App.tickets.doAction("bar_search", this.state.inputValue);
  };

  render() {
    const { inputValue } = this.state;
    const { classes, channelConnected } = this.props;
    return (
      <Input
        defaultValue={inputValue}
        onChange={this.changeValue}
        onKeyUp={this.performSearch}
        placeholder="Поиск тикетов"
        className={classes.input}
        disabled={!channelConnected}
      />
    );
  }
}

function mapStateToProps(state) {
  const { channelConnected } = state.tickets;
  return { channelConnected };
}

const styles = theme => ({
  input: {
    margin: theme.spacing.unit,
    color: "#fff",
    "&:after": {
      transition:
        "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms; border-bottom: 2px solid #fff"
    }
  }
});

export default withStyles(styles)(connect(mapStateToProps)(TicketSearchField));
