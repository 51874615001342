import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import ProjectForm from "./project_management/common/ProjectForm";
import { showAlert } from "../actions/layout";

class ProjectCreatePage extends React.Component {
  state = { isSaving: false };

  saveProject = params => {
    this.setState({ isSaving: true });
    const authenticity_token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    axios
      .post("/projects", { authenticity_token, ...params })
      .then(response => {
        this.props.history.push("/projects");
        this.props.dispatchShowAlert(response.data);
      })
      .catch(() => {
        this.setState({ isSaving: false });
        this.props.dispatchShowAlert({
          color: "error",
          text: "Ошибка создания проекта"
        });
      });
  };

  render() {
    const { classes } = this.props;
    const { isSaving } = this.state;
    return (
      <div>
        <div className={classes.toolbar} />
        <main className={classes.content}>
          <Typography variant="title" gutterBottom>
            Создать новый проект
          </Typography>
          <ProjectForm
            values={{}}
            isSaving={isSaving}
            onSave={this.saveProject}
          />
        </main>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchShowAlert: payload => dispatch(showAlert(payload))
  };
}

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    minWidth: 0,
    minHeight: "100vh"
  }
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ProjectCreatePage)
);
