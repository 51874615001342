import React from "react";
import Text from "./Text";
// import Bool from "./Bool";
import TicketType from "./TicketType";
import Subscription from "./Subscription";
import Group from "./Group";

const renderTypeField = (field, setValue, value, opts) => {
  const { name, title, type } = field;
  const { types, subscriptions } = opts;
  const commonProps = { key: name, name, title, setValue, value };
  switch (type) {
    case "text":
      return <Text {...commonProps} />;
    // case "bool":
    //   return <Bool {...commonProps} />;
    case "ticket_type":
      return <TicketType {...commonProps} types={types} />;
    case "subscription":
      return <Subscription {...commonProps} subscriptions={subscriptions} />;
    case "group":
      return <Group {...commonProps} field={field} opts={opts} />;
    default:
      null;
  }
};

export default renderTypeField;
