// TODO: придумать что сделать, если подписка становится нетерминальной
// TODO: избавиться от кучи sql-запросов при обходе дерева подписок
// TODO: избавиться connect
import React from "react";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import CreateButton from "../../CreateButton";
import StaffTable from "./StaffTable";
import StaffDialog from "./StaffDialog";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";
import { get, create, update, destroy } from "../../../api/staff";
import { showAlert } from "../../../actions/layout";

const emptyDialog = {
  showDialog: false,
  id: null,
  email: "",
  selectedRoles: new Set(),
  selectedSubscriptions: new Set(),
  showDeleteConfirmation: false
};

class Staff extends React.Component {
  state = {
    isLoading: true,
    staff: [],
    roles: {},
    subscriptions: {},
    ...emptyDialog
  };

  componentDidMount() {
    get(this.props.projectId)
      .then(({ data }) => this.setState({ ...data, isLoading: false }))
      .catch(error => console.log(error));
  }

  showDialog = () => {
    this.setState({ showDialog: true });
  };

  addUser = () => {
    const { email, selectedRoles, selectedSubscriptions } = this.state;
    create(this.props.projectId, {
      email,
      roles: JSON.stringify([...selectedRoles]),
      subscriptions: JSON.stringify([...selectedSubscriptions])
    })
      .then(({ data }) => {
        this.setState({ staff: data, ...emptyDialog });
        this.props.dispatchShowAlert({
          color: "success",
          text: "Пользователь успешно добавлен в проект"
        });
      })
      .catch(() => {
        this.props.dispatchShowAlert({
          color: "error",
          text:
            "Ошибка добавления пользователя. Удостоверьтесь, что пользователь зарегистрирован в системе и email введен правильно"
        });
      });
  };

  editUser = e => {
    const { index } = e.currentTarget.dataset;
    const user = this.state.staff[index];
    const selectedRoles = new Set(user.roles.map(x => x.toString()));
    const selectedSubs = new Set(user.subscriptions.map(x => x.toString()));
    this.setState({
      showDialog: true,
      selectedRoles: selectedRoles,
      selectedSubscriptions: selectedSubs,
      id: user.id,
      email: user.email
    });
  };

  confirmDelete = e => {
    const id = parseInt(e.currentTarget.dataset.id);
    const { email } = this.state.staff.find(s => s.id === id);
    this.setState({ id, email, showDeleteConfirmation: true });
  };

  removeUser = () => {
    destroy(this.props.projectId, this.state.id)
      .then(({ data }) => {
        this.setState({ staff: data, ...emptyDialog });
        this.props.dispatchShowAlert({
          color: "success",
          text: "Пользователь успешно удален из проекта"
        });
      })
      .catch(() => {
        this.props.dispatchShowAlert({
          color: "error",
          text: "Ошибка удаления пользователя"
        });
      });
  };

  closeDialogs = () => {
    this.setState({ ...emptyDialog });
  };

  setEmail = e => {
    this.setState({ email: e.target.value });
  };

  toggleRole = id => () => {
    const newRoles = new Set(this.state.selectedRoles);
    newRoles.has(id) ? newRoles.delete(id) : newRoles.add(id);
    this.setState({ selectedRoles: newRoles });
  };

  toggleSubscription = id => () => {
    const newSubs = new Set(this.state.selectedSubscriptions);
    newSubs.has(id) ? newSubs.delete(id) : newSubs.add(id);
    this.setState({ selectedSubscriptions: newSubs });
  };

  deleteRoleOrSub = (group, id, userId) => () => {
    const params = { group: group, entity_id: id };
    update(this.props.projectId, userId, params)
      .then(response => this.setState({ staff: response.data }))
      .catch(error => console.log(error));
  };

  updateRolesAndSubscriptions = () => {
    const { selectedRoles, selectedSubscriptions } = this.state;
    const params = {
      roles: JSON.stringify([...selectedRoles]),
      subscriptions: JSON.stringify([...selectedSubscriptions])
    };
    update(this.props.projectId, this.state.id, params)
      .then(({ data }) => this.setState({ staff: data, ...emptyDialog }))
      .catch(error => console.log(error));
  };

  render() {
    // TODO: Remove roleIds and subscription ids?
    if (this.state.isLoading) return <LinearProgress />;
    const {
      staff,
      roles,
      subscriptions,
      showDialog,
      id,
      email,
      selectedRoles,
      selectedSubscriptions,
      showDeleteConfirmation
    } = this.state;
    const roleIds = Object.keys(roles);
    const subscriptionIds = Object.keys(subscriptions);
    return (
      <div>
        <StaffTable
          staff={staff}
          roles={roles}
          subscriptions={subscriptions}
          deleteRoleOrSub={this.deleteRoleOrSub}
          editUser={this.editUser}
          removeUser={this.confirmDelete}
        />
        <StaffDialog
          showDialog={showDialog}
          roles={roles}
          subscriptions={subscriptions}
          roleIds={roleIds}
          subscriptionIds={subscriptionIds}
          selectedRoles={selectedRoles}
          selectedSubscriptions={selectedSubscriptions}
          toggleRole={this.toggleRole}
          toggleSubscription={this.toggleSubscription}
          closeDialogs={this.closeDialogs}
          setEmail={this.setEmail}
          id={id}
          email={email}
          addUser={this.addUser}
          updateRolesAndSubscriptions={this.updateRolesAndSubscriptions}
        />
        <DeleteConfirmationDialog
          show={showDeleteConfirmation}
          text={`Удалить пользователя "${email}" из проекта?`}
          cancelCallback={this.closeDialogs}
          confirmCallback={this.removeUser}
        />
        <CreateButton callback={this.showDialog} title="Добавить сотрудника" />
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchShowAlert: payload => dispatch(showAlert(payload))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Staff);
