import { loadLastNotifications } from "../actions/layout";

const lastNotificationsLoading = store => next => action => {
  if (
    action.type === loadLastNotifications.toString() &&
    App &&
    App.notifications
  ) {
    App.notifications.loadLastNotifications()
  }
  return next(action);
};

export default lastNotificationsLoading;
