// TODO: Split to subcomponents
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { compose } from "recompose";
import parse from "date-fns/parse";
import format from "date-fns/format";
import isFriday from "date-fns/isFriday";
import isSaturday from "date-fns/isSaturday";
import isSunday from "date-fns/isSunday";
import DateFnsUtils from "material-ui-pickers/utils/date-fns-utils";
import DateTimePicker from "material-ui-pickers/DateTimePicker";
import MuiPickersUtilsProvider from "material-ui-pickers/utils/MuiPickersUtilsProvider";
import ruLocale from "date-fns/locale/ru";
import withStyles from "@material-ui/core/styles/withStyles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ReactSelect from "react-select";
import { hideQuestquestBookingDialog, showAlert } from "../../actions/layout";
import { showNoteRequest } from "../../actions/notes";
import { showInfoSuccess } from "../../actions/infos";
import NewPaymentForm from "./QuestQuestBooking/NewPaymentForm";

const PRIMARY_COLOR = "primary";
const SECONDARY_COLOR = "secondary";

const NOTES = [
  "CTA",
  "Exit intent",
  "Отмена",
  "Обзвон",
  "Отыгранные",
  "Перенос",
  "Questhunt",
  "Vk"
];

const BOOKING_PARAMS = [
  "player_name",
  "player_phone",
  "date",
  "time",
  "price",
  "duration",
  "utm_source_admin"
];

const emptyBookingForm = {
  showBookingForm: false,
  player_name: "",
  player_phone: "",
  comment: "",
  player_amount: "",
  player_age: "",
  discount_text: "",
  duration: "",
  clerify_date: null,
  referral_code: "",
  date: "",
  time: "",
  price: "",
  utm_source_admin: "",
  note: "",
  additional: "",
  rest: ""
};

const initialState = {
  city_list: [],
  selectedCity: {},
  quest_list: [],
  selectedQuest: {},
  schedule: {},
  scheduleLoading: false,
  bookingInProgress: false,
  assignmentId: null,
  showNewPaymentForm: false,
  paymentUrl: null,
  ...emptyBookingForm
};

const NIGHT_START = "00:00";
const NIGHT_STOP = "06:00";
const EXTERNAL_QUEST_RESTRICTION = "Запрещено менять для внешнего квеста";

export class QuestQuestBooking extends PureComponent {
  state = { ...initialState };

  componentDidUpdate(prevProps) {
    if (
      !prevProps.showQuestquestBookingDialog &&
      this.props.showQuestquestBookingDialog
    ) {
      this.run("city_list");
    }
  }

  run = (tofetch, data = null) => {
    const authenticity_token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    axios
      .post(`/projects/${this.props.currentProject.id}/project_services/run`, {
        authenticity_token,
        name: "QuestQuestBooking",
        options: { fetch: tofetch, data }
      })
      .then(({ data }) =>
        this.setState({ [tofetch]: data, scheduleLoading: false })
      )
      .catch(error => console.log(error));
  };

  changeCity = selectedCity => {
    const { city_list } = this.state;
    this.setState({ ...initialState, city_list, selectedCity });
    if (selectedCity && selectedCity.label !== "") {
      this.run("quest_list", selectedCity.value);
    }
  };

  changeQuest = selectedQuest => {
    const { city_list, selectedCity, quest_list } = this.state;
    const scheduleLoading = selectedQuest.label !== "";
    this.setState({
      ...initialState,
      city_list,
      selectedCity,
      quest_list,
      selectedQuest,
      scheduleLoading
    });
    if (scheduleLoading) this.run("schedule", selectedQuest.value);
  };

  showBookingForm = ({ currentTarget }) => {
    const duration = this.state.schedule.duration.toString();
    const { date, time, price, additional } = currentTarget.dataset;
    this.setState({
      showBookingForm: true,
      date,
      time,
      price,
      duration,
      additional,
      rest: additional
    });
  };

  backToSchedule = () => {
    this.setState({
      showBookingForm: false,
      scheduleLoading: true,
      assignmentId: null,
      paymentUrl: null
    });
    this.run("schedule", this.state.selectedQuest.value);
  };

  setTextField = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  setNumField = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value.replace(/[^0-9]/g, "") });
  };

  setClerifyDateChange = date => {
    this.setState({ clerify_date: date });
  };

  book = () => {
    const data = Object.keys(emptyBookingForm).reduce((acc, k) => {
      const value = this.state[k];
      if (value && value !== "") acc[k] = this.state[k];
      return acc;
    }, {});
    data.quest_id = this.state.selectedQuest.value;
    const authenticity_token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    this.setState({ bookingInProgress: true });
    axios
      .post(`/projects/${this.props.currentProject.id}/project_services/run`, {
        authenticity_token,
        name: "QuestQuestBooking",
        options: { fetch: "book", data }
      })
      .then(({ data }) => {
        const { text, color, id } = data;
        this.setState({ bookingInProgress: false, assignmentId: id });
        this.props.dispatchShowAlert({ text, color });
      })
      .catch(error => {
        this.setState({ bookingInProgress: false });
        console.log(error);
      });
  };

  openNewPaymentForm = () => {
    const { paymentUrl } = this.state;
    if (paymentUrl) return this.props.dispatchShowPaymentUrl(paymentUrl);
    this.setState({ showNewPaymentForm: true });
  };

  closeNewPaymentForm = () => {
    this.setState({ showNewPaymentForm: false });
  };

  fetchPaymentUrl = price => {
    const authenticity_token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    axios
      .post(`/projects/${this.props.currentProject.id}/project_services/run`, {
        authenticity_token,
        name: "QuestQuestBooking",
        options: {
          fetch: "payment",
          data: { quest_assignment_id: this.state.assignmentId, price }
        }
      })
      .then(({ data }) => {
        this.setState({ showNewPaymentForm: false, paymentUrl: data });
        this.props.dispatchShowPaymentUrl(data);
      })
      .catch(error => console.log(error));
  };

  closeBooking = () => {
    const { showBookingForm, assignmentId } = this.state;
    if (showBookingForm && !assignmentId) {
      const shouldClose = confirm("Бронирование не завершено. Закрыть?");
      if (!shouldClose) return;
    }
    this.props.dispatchHideQuestquestBookingDialog();
    this.setState(initialState);
  };

  openCityNote = () => {
    const { label } = this.state.selectedCity;
    if (label) this.props.dispatchShowNoteRequest(label);
  };

  openQuestNote = () => {
    const cityLabel = this.state.selectedCity.label;
    const questLabel = this.state.selectedQuest.label;
    if (cityLabel && questLabel)
      this.props.dispatchShowNoteRequest(`${cityLabel} - ${questLabel}`);
  };

  render() {
    const { classes, showQuestquestBookingDialog } = this.props;
    const {
      showBookingForm,
      player_phone,
      bookingInProgress,
      showNewPaymentForm,
      assignmentId
    } = this.state;
    const paramsNotEmpty = BOOKING_PARAMS.every(p => this.state[p] !== "");
    const validPhone = player_phone.match(/^\+?(7|8)\d{10}/);
    return (
      <Fragment>
        <Dialog
          open={showQuestquestBookingDialog}
          onClose={() => {}}
          fullScreen
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.closeBooking}
                aria-label="Close"
              >
                <i className="material-icons">close</i>
              </IconButton>
              <Typography
                variant="title"
                color="inherit"
                className={classes.flex}
              >
                Бронирование QuestQuest
              </Typography>
              {showBookingForm && paramsNotEmpty && validPhone && (
                <Button
                  onClick={this.book}
                  color="inherit"
                  disabled={bookingInProgress || !!assignmentId}
                >
                  Забронировать
                </Button>
              )}
            </Toolbar>
            {bookingInProgress && (
              <LinearProgress className={classes.bookingProgress} />
            )}
          </AppBar>
          <DialogContent>
            <div className={classes.topmargin}>
              <span className={classes.absolute}>
                <i
                  className="material-icons pointer"
                  onClick={this.openCityNote}
                  title="Открыть заметку города"
                >
                  notes
                </i>
              </span>
              <div className={classes.leftPadding}>
                {this.renderCitySelect()}
              </div>
            </div>
            <div className={classes.topmargin}>
              <span className={classes.absolute}>
                <i
                  className="material-icons pointer"
                  onClick={this.openQuestNote}
                  title="Открыть заметку квеста"
                >
                  notes
                </i>
              </span>
              <div className={classes.leftPadding}>
                {this.renderQuestSelect()}
              </div>
              {showBookingForm
                ? this.renderBookingForm()
                : this.renderSchedule()}
            </div>
          </DialogContent>
        </Dialog>
        <NewPaymentForm
          showNewPaymentForm={showNewPaymentForm}
          assignmentId={assignmentId}
          fetchPaymentUrl={this.fetchPaymentUrl}
          closeNewPaymentForm={this.closeNewPaymentForm}
        />
      </Fragment>
    );
  }

  renderCitySelect = () => {
    const { city_list, selectedCity } = this.state;
    return (
      <ReactSelect
        autoFocus
        value={selectedCity}
        options={city_list.map(({ id, name }) => ({ value: id, label: name }))}
        onChange={this.changeCity}
      />
    );
  };

  // TODO: Use object instead of array in quest_list
  renderQuestSelect = () => {
    const { quest_list, selectedQuest } = this.state;
    return (
      <ReactSelect
        value={selectedQuest}
        options={quest_list.map(([id, name, type]) => {
          return {
            value: id,
            label: type === "Квесты" ? name : `${type} | ${name}`
          };
        })}
        onChange={this.changeQuest}
      />
    );
  };

  renderSchedule = () => {
    const { schedule, scheduleLoading } = this.state;
    const { classes } = this.props;
    if (scheduleLoading)
      return <LinearProgress className={classes.topmargin} />;
    const timetable = schedule.schedule;
    return (
      <List disablePadding>
        {timetable &&
          timetable.map(([stringDate, timeslots]) => {
            const date = parse(stringDate, "yyyy-MM-dd", new Date());
            return (
              <ListItem key={date} dense divider disableGutters>
                <ListItemText>
                  <div className={classes.bookingListDate}>
                    {format(date, "dd MMMM | EEEEEE", { locale: ruLocale })}
                  </div>
                  <div className={classes.bookingListTimes}>
                    {timeslots.map(([time, price, additional, withPay]) => (
                      <Chip
                        key={time}
                        label={`${time} | ${price}`}
                        className={classes.chip}
                        data-date={stringDate}
                        data-time={time}
                        data-price={price}
                        data-additional={additional}
                        data-rest={additional}
                        onClick={this.showBookingForm}
                        color={withPay ? SECONDARY_COLOR : PRIMARY_COLOR}
                      />
                    ))}
                  </div>
                </ListItemText>
              </ListItem>
            );
          })}
      </List>
    );
  };

  renderBookingForm = () => {
    const { classes } = this.props;
    const {
      selectedQuest,
      schedule,
      assignmentId,
      player_name,
      player_phone,
      comment,
      player_amount,
      player_age,
      discount_text,
      duration,
      clerify_date,
      referral_code,
      date,
      time,
      price,
      utm_source_admin,
      note
    } = this.state;
    return (
      <div>
        <TextField
          error={player_name === ""}
          className={classes.textField}
          label="Имя"
          inputProps={{ name: "player_name" }}
          value={player_name}
          onChange={this.setTextField}
          margin="normal"
        />
        <TextField
          error={player_phone === "" || !player_phone.match(/^\+?(7|8)\d{10}/)}
          className={classes.textField}
          label="Телефон"
          inputProps={{ name: "player_phone" }}
          value={player_phone}
          onChange={this.setTextField}
          margin="normal"
        />
        <TextField
          disabled={schedule.external}
          helperText={schedule.external && EXTERNAL_QUEST_RESTRICTION}
          error={date === ""}
          className={classes.textField}
          label="Дата"
          inputProps={{ name: "date" }}
          value={date}
          onChange={this.setTextField}
          margin="normal"
        />
        <TextField
          disabled={schedule.external}
          helperText={schedule.external && EXTERNAL_QUEST_RESTRICTION}
          error={time === ""}
          className={classes.textField}
          label="Время"
          inputProps={{ name: "time" }}
          value={time}
          onChange={this.setTextField}
          margin="normal"
        />
        <TextField
          error={price === ""}
          className={classes.textField}
          label="Цена"
          inputProps={{ name: "price" }}
          value={price}
          onChange={this.setTextField}
          margin="normal"
        />
        <FormControl
          className={classes.textField}
          error={utm_source_admin === ""}
        >
          <InputLabel htmlFor="utm_source_admin">Источник</InputLabel>
          <Select
            value={utm_source_admin}
            onChange={this.setTextField}
            inputProps={{ name: "utm_source_admin", id: "utm_source_admin" }}
          >
            {schedule.sources.map(s => (
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          className={classes.textField}
          label="Комментарий"
          inputProps={{ name: "comment" }}
          value={comment}
          onChange={this.setTextField}
          margin="normal"
        />
        <TextField
          className={classes.textField}
          label="Количество"
          inputProps={{ name: "player_amount" }}
          value={player_amount}
          onChange={this.setNumField}
          margin="normal"
        />
        <TextField
          className={classes.textField}
          label="Возраст"
          inputProps={{ name: "player_age" }}
          value={player_age}
          onChange={this.setTextField}
          margin="normal"
        />
        <TextField
          className={classes.textField}
          label="Скидки"
          inputProps={{ name: "discount_text" }}
          value={discount_text}
          onChange={this.setTextField}
          margin="normal"
        />
        <TextField
          error={duration === ""}
          className={classes.textField}
          label="Продолжительность"
          inputProps={{ name: "duration" }}
          value={duration}
          onChange={this.setNumField}
          margin="normal"
        />
        <div className={classes.topmargin}>
          <Button
            variant="outlined"
            color="default"
            className={classes.button}
            onClick={this.backToSchedule}
          >
            К расписанию
          </Button>
          <a
            href={`https://questquest.net/ror/admin/quests/${
              selectedQuest.value
            }/schedule`}
            target="_blank"
            className={classes.link}
            rel="noopener noreferrer"
          >
            <Button
              variant="outlined"
              color="default"
              className={classes.button}
            >
              Админ-расписание
            </Button>
          </a>
          {assignmentId && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={this.openNewPaymentForm}
            >
              Создать платеж
            </Button>
          )}
        </div>
      </div>
    );
  };
}

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  button: {
    margin: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit
  },
  bookingListDate: {
    display: "inline-block",
    width: "10%",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "100%"
    }
  },
  bookingListTimes: {
    display: "inline-block",
    width: "90%"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 280
  },
  topmargin: {
    marginTop: "8px",
    position: "relative"
  },
  leftPadding: {
    paddingLeft: "28px"
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none"
    }
  },
  absolute: {
    position: "absolute",
    left: "0",
    top: "6px"
  },
  bookingProgress: {
    position: "absolute",
    width: "100%",
    height: "5px",
    bottom: "-5px",
    zIndex: "1"
  }
});

function mapStateToProps({ layout }) {
  return {
    currentProject: layout.currentProject,
    showQuestquestBookingDialog: layout.showQuestquestBookingDialog
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchShowAlert: payload => dispatch(showAlert(payload)),
    dispatchShowNoteRequest: noteName => dispatch(showNoteRequest(noteName)),
    dispatchShowPaymentUrl: url => dispatch(showInfoSuccess(url)),
    dispatchHideQuestquestBookingDialog: () =>
      dispatch(hideQuestquestBookingDialog())
  };
}

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(QuestQuestBooking);
