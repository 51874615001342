import React from "react";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Ticket from "./Ticket";
import Filter from "./Filter";
import Pagination from "./Pagination";
import RefreshTicketButton from "./RefreshTicketButton";
import {
  setPageNumber,
  toggleShowFree,
  toggleShowOwned
} from "../../actions/tickets";

const pageLimit = 36;

// TODO: Merge with CurrentTicketsPage
class DelayedTicketsPage extends React.PureComponent {
  handleToggleShowFree = () => {
    this.props.dispatchToggleShowFree("delayed");
  };

  handleToggleShowOwned = () => {
    this.props.dispatchToggleShowOwned("delayed");
  };

  render() {
    const { channelConnected, basicCollectionIsLoading } = this.props;
    if (!channelConnected || basicCollectionIsLoading)
      return <LinearProgress />;
    const {
      tickets,
      types,
      filter,
      showFree,
      showOwned,
      page,
      dispatchSetPageNumber
    } = this.props;
    const takeTo = page * pageLimit;
    const offset = takeTo - pageLimit;
    const ticketIds =
      filter === ""
        ? []
        : Object.keys(tickets).filter(id => {
            if (filter !== tickets[id].type) return false;
            const owned = !!tickets[id].owner;
            if (showOwned && owned) return true;
            if (showFree && !owned) return true;
            return false;
          });
    const pageTicketIds = ticketIds.slice(offset, takeTo);
    const totalPages = Math.ceil(ticketIds.length / pageLimit);
    return (
      <div>
        <RefreshTicketButton group="basic" action="fetch" />
        <Filter types={types} page="delayed" filter={filter} />
        <div className="row">
          {pageTicketIds.map(id => (
            <Ticket key={id} id={id} data={tickets[id]} />
          ))}
        </div>
        <Pagination
          page={page}
          totalPages={totalPages}
          setPageNumber={dispatchSetPageNumber}
          name="delayed"
        />
        <Button
          variant="fab"
          onClick={this.handleToggleShowFree}
          color={showFree ? "primary" : "default"}
          title="Показывать свободные"
          className="show-free-button"
          mini
        >
          <i className="material-icons">accessibility_new</i>
        </Button>
        <Button
          variant="fab"
          onClick={this.handleToggleShowOwned}
          color={showOwned ? "primary" : "default"}
          title="Показывать взятые"
          className="show-owned-button"
          mini
        >
          <i className="material-icons">pan_tool</i>
        </Button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    channelConnected,
    delayed,
    delayedTypes,
    basicCollectionIsLoading,
    delayedTicketsFilter,
    delayedTicketsShowFree,
    delayedTicketsShowOwned,
    delayedTicketPageNumber
  } = state.tickets;
  return {
    channelConnected: channelConnected,
    tickets: delayed,
    types: delayedTypes,
    basicCollectionIsLoading: basicCollectionIsLoading,
    filter: delayedTicketsFilter,
    showFree: delayedTicketsShowFree,
    showOwned: delayedTicketsShowOwned,
    page: delayedTicketPageNumber
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetPageNumber: payload => dispatch(setPageNumber(payload)),
    dispatchToggleShowFree: page => dispatch(toggleShowFree(page)),
    dispatchToggleShowOwned: page => dispatch(toggleShowOwned(page))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DelayedTicketsPage);
