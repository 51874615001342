import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import ReactQuill from "react-quill";
import { hideNote, changeNote, saveNoteRequest } from "../../actions/notes";

const noModules = { toolbar: false };
const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ size: ["small", false, "large", "huge"] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ["link"],
    ["clean"]
  ]
};

class NoteDialog extends React.PureComponent {
  changeNoteText = editorState => {
    this.props.dispatchChangeNote(editorState);
  };

  render() {
    const {
      classes,
      showNote,
      name,
      text,
      can_update,
      dispatchHideNote,
      dispatchSaveNote
    } = this.props;
    return (
      <Dialog
        fullScreen
        open={showNote}
        onClose={() => {}}
        aria-labelledby="note-dialog"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={dispatchHideNote}
              aria-label="Close"
            >
              <i className="material-icons">close</i>
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              className={classes.flex}
            >
              {`Заметка: ${name}`}
            </Typography>
            {can_update && (
              <Button onClick={dispatchSaveNote} color="inherit">
                Сохранить
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <DialogContent>
          {can_update ? (
            <ReactQuill
              value={text}
              onChange={this.changeNoteText}
              modules={modules}
            />
          ) : (
            <ReactQuill value={text} readOnly={true} modules={noModules} />
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

function mapStateToProps({ notes }) {
  return { ...notes };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchHideNote: () => dispatch(hideNote()),
    dispatchChangeNote: text => dispatch(changeNote(text)),
    dispatchSaveNote: () => dispatch(saveNoteRequest())
  };
}

const styles = () => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  }
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(NoteDialog)
);
