import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import CreateButton from "../../CreateButton";
import RoleTable from "./RoleTable";
import RoleDialog from "./RoleDialog";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";
import { get, create, update, destroy } from "../../../api/roles";

const emptyDialog = {
  showDialog: false,
  id: null,
  name: "",
  showDeleteConfirmation: false
};

class Roles extends React.Component {
  state = { isLoading: true, roles: [], ...emptyDialog };

  componentDidMount() {
    get(this.props.projectId)
      .then(response =>
        response.data.map(role => {
          role.editing = false;
          role.editedName = role.name;
          return role;
        })
      )
      .then(roles => this.setState({ roles, isLoading: false }))
      .catch(error => console.log(error));
  }

  newRole = () => {
    this.setState({ showDialog: true });
  };

  createRole = () => {
    const { roles, name } = this.state;
    create(this.props.projectId, name)
      .then(({ data }) => {
        this.setState({ roles: [...roles, data], ...emptyDialog });
      })
      .catch(error => console.log(error));
  };

  toggleRoleParam = (index, name) => () => {
    const { roles } = this.state;
    const newValue = !roles[index][name];
    update(this.props.projectId, roles[index].id, { [name]: newValue })
      .then(({ data }) => {
        const newRoles = [...roles];
        newRoles[index] = { ...data, editing: false, editedName: data.name };
        this.setState({ roles: newRoles });
      })
      .catch(error => console.log(error));
  };

  confirmDelete = e => {
    const id = parseInt(e.currentTarget.dataset.id);
    const { name } = this.state.roles.find(c => c.id === id);
    this.setState({ id, name, showDeleteConfirmation: true });
  };

  destroyRole = () => {
    const { id, roles } = this.state;
    destroy(this.props.projectId, id)
      .then(() =>
        this.setState({ roles: roles.filter(r => r.id !== id), ...emptyDialog })
      )
      .catch(error => console.log(error));
  };

  closeDialogs = () => {
    this.setState(emptyDialog);
  };

  setName = e => {
    this.setState({ name: e.target.value });
  };

  toggleEdit = e => {
    const { index } = e.target.dataset;
    const { roles } = this.state;
    const newRole = { ...roles[index], editing: true };
    roles[index] = newRole;
    this.setState({ roles: [...roles] });
  };

  setEditedName = index => e => {
    const { value } = e.target;
    const { roles } = this.state;
    const newRole = { ...roles[index], editedName: value };
    roles[index] = newRole;
    this.setState({ roles: [...roles] });
  };

  saveEdit = e => {
    const { index } = e.currentTarget.dataset;
    const { roles } = this.state;
    const { editedName } = roles[index];
    update(this.props.projectId, roles[index].id, { name: editedName })
      .then(() => {
        const newRole = {
          ...roles[index],
          name: editedName,
          editing: false,
          editedName
        };
        roles[index] = newRole;
        this.setState({ roles: [...roles] });
      })
      .catch(error => console.log(error));
  };

  cancelEdit = e => {
    const { index } = e.currentTarget.dataset;
    const { roles } = this.state;
    const newRole = {
      ...roles[index],
      editing: false,
      editedName: roles[index].name
    };
    roles[index] = newRole;
    this.setState({ roles: [...roles] });
  };

  render() {
    // TODO: Remove indexes
    if (this.state.isLoading) return <LinearProgress />;
    const { roles, showDialog, name, showDeleteConfirmation } = this.state;
    return (
      <div>
        <RoleTable
          roles={roles}
          setEditedName={this.setEditedName}
          cancelEdit={this.cancelEdit}
          saveEdit={this.saveEdit}
          toggleEdit={this.toggleEdit}
          toggleRoleParam={this.toggleRoleParam}
          destroyRole={this.confirmDelete}
        />
        <RoleDialog
          showDialog={showDialog}
          name={name}
          setName={this.setName}
          closeDialog={this.closeDialogs}
          createRole={this.createRole}
        />
        <DeleteConfirmationDialog
          show={showDeleteConfirmation}
          text={`Вы действительно хотите удалить роль "${name}"?`}
          cancelCallback={this.closeDialogs}
          confirmCallback={this.destroyRole}
        />
        <CreateButton callback={this.newRole} title="Добавить роль" />
      </div>
    );
  }
}

export default Roles;
