import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import renderTypeField from "./types";
import { showAlert } from "../../../actions/layout";
import { get, create, update, destroy } from "../../../api/services";

const emptyDialog = {
  currentService: null,
  serviceValues: {},
  showConnectDialog: false,
  showDisconnectDialog: false
};

export class Services extends React.Component {
  state = {
    isLoading: true,
    services: [],
    project_services: {},
    types: [],
    subscriptions: {},
    ...emptyDialog
  };

  componentDidMount() {
    get(this.props.projectId)
      .then(({ data }) => this.setState({ ...data, isLoading: false }))
      .catch(error => console.log(error));
  }

  setValue = (name, value) => {
    this.setState({
      ...this.state,
      serviceValues: { ...this.state.serviceValues, [name]: value }
    });
  };

  connectService = () => {
    const { currentService, serviceValues, project_services } = this.state;
    create(this.props.projectId, {
      service_id: currentService.id,
      options: serviceValues
    })
      .then(({ data }) => {
        const { service_id, options } = data;
        this.setState({
          project_services: { ...project_services, [service_id]: options },
          ...emptyDialog
        });
      })
      .catch(() =>
        this.props.dispatchShowAlert({
          color: "error",
          text: "Ошибка подключения сервиса. Проверьте правильность заполнения необходимых полей."
        })
      );
  };

  editService = e => {
    const id = parseInt(e.currentTarget.dataset.id);
    const service = this.state.services.find(s => s.id === id);
    this.setState({
      showConnectDialog: true,
      currentService: service,
      serviceValues: this.state.project_services[id]
    });
  };

  updateService = () => {
    const { currentService, serviceValues, project_services } = this.state;
    update(this.props.projectId, {
      service_id: currentService.id,
      options: serviceValues
    })
      .then(({ data }) => {
        const { service_id, options } = data;
        this.setState({
          project_services: { ...project_services, [service_id]: options },
          ...emptyDialog
        });
      })
      .catch(error => console.log(error));
  };

  disconnectService = () => {
    const { id } = this.state.currentService;
    destroy(this.props.projectId, id)
      .then(({ data }) => this.setState({ ...data, ...emptyDialog }))
      .catch(error => console.log(error));
  };

  handleCheckService = id => () => {
    const { services, project_services } = this.state;
    const service = services.find(s => s.id === id);
    if (project_services[id]) {
      this.setState({ showDisconnectDialog: true, currentService: service });
    } else {
      this.setState({ showConnectDialog: true, currentService: service });
    }
  };

  closeDialog = () => {
    this.setState(emptyDialog);
  };

  render() {
    if (this.state.isLoading) return <LinearProgress />;
    const { services, project_services } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Название</TableCell>
                <TableCell numeric>Настройки</TableCell>
                <TableCell numeric>Подключен</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {services.map(service => {
                return (
                  <TableRow key={service.name}>
                    <TableCell>{service.title}</TableCell>
                    <TableCell numeric>
                      {project_services[service.id] && (
                        <Button
                          color="secondary"
                          onClick={this.editService}
                          size="small"
                          data-id={service.id}
                        >
                          <i className="material-icons">build</i>
                        </Button>
                      )}
                    </TableCell>
                    <TableCell component="th" numeric>
                      <Checkbox
                        checked={!!project_services[service.id]}
                        onChange={this.handleCheckService(service.id)}
                        color="primary"
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
        {this.renderConnectDialog()}
        {this.renderDisconnectDialog()}
      </div>
    );
  }

  renderConnectDialog = () => {
    const { classes } = this.props;
    const {
      showConnectDialog,
      currentService,
      project_services,
      serviceValues,
      types,
      subscriptions
    } = this.state;
    return (
      <Dialog
        fullScreen
        open={showConnectDialog}
        onClose={() => {}}
        aria-labelledby="form-dialog-title"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={this.closeDialog}
              aria-label="Close"
            >
              <i className="material-icons">close</i>
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              className={classes.flex}
            >
              {`Сервис ${currentService && currentService.title}`}
            </Typography>
            {currentService && (
              <Button
                onClick={
                  project_services[currentService.id]
                    ? this.updateService
                    : this.connectService
                }
                color="inherit"
              >
                Сохранить
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <DialogContent>
          {currentService &&
            currentService.required_fields.map(field =>
              renderTypeField(field, this.setValue, serviceValues[field.name], {
                types,
                subscriptions
              })
            )}
        </DialogContent>
      </Dialog>
    );
  };

  renderDisconnectDialog = () => {
    const { showDisconnectDialog, currentService } = this.state;
    return (
      <Dialog
        open={showDisconnectDialog}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Отключение сервиса</DialogTitle>
        <DialogContent>
          {currentService ? (
            <DialogContentText>
              {`Отключить сервис "${
                currentService.name
              }" от проекта? Все текущие настройки будут уничтожены.`}
            </DialogContentText>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeDialog} color="primary">
            Отмена
          </Button>
          <Button onClick={this.disconnectService} color="primary" autoFocus>
            Отключить
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    marginBottom: theme.spacing.unit * 3
  },
  table: {
    minWidth: 700
  },
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  }
});

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchShowAlert: payload => dispatch(showAlert(payload))
  };
}

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Services);
