import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default class RoleDialog extends React.PureComponent {
  render() {
    const { showDialog, name, setName, closeDialog, createRole } = this.props;
    return (
      <Dialog
        open={showDialog}
        onClose={() => {}}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Создание новой роли</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Создание новой роли для текущего проекта
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Название"
            fullWidth
            value={name}
            onChange={setName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Отменить
          </Button>
          <Button onClick={createRole} color="primary">
            Создать
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
