export const addTicket = (collection, { id, data }) => {
  const newCollection = { ...collection, [id]: data };
  return { coll: newCollection, types: buildTypes(newCollection) };
};

export const removeTicket = (collection, idToDelete, types = null) => {
  if (!collection[idToDelete]) return { coll: collection, types: types };
  return Object.keys(collection).reduce(
    (acc, id) => {
      if (id != idToDelete) {
        acc.coll[id] = collection[id];
        if (!types) return acc;
        const type = collection[id].type;
        const typeCount = acc.types[type] || 0;
        acc.types[type] = typeCount + 1;
      }
      return acc;
    },
    { coll: {}, types: {} }
  );
};

export const buildTypes = collection => {
  let types = {};
  Object.values(collection).forEach(ticket => {
    const type = ticket.type;
    types[type] ? (types[type] += 1) : (types[type] = 1);
  });
  return types;
};

export const removeTicketGroup = (collection, ids, types = null) => {
  const idsToDelete = ids.filter(id => collection[id]);
  if (idsToDelete.length === 0) return { coll: collection, types: types };
  return Object.keys(collection).reduce(
    (acc, id) => {
      if (!idsToDelete.includes(id)) {
        acc.coll[id] = collection[id];
        if (!types) return acc;
        const type = collection[id].type;
        const typeCount = acc.types[type] || 0;
        acc.types[type] = typeCount + 1;
      }
      return acc;
    },
    { coll: {}, types: {} }
  );
};

export const updateTicket = (collection, { id, data }) => {
  if (!collection[id]) return collection;
  return { ...collection, [id]: data };
};
