import React from "react";
import { connect } from "react-redux";
import toDate from "date-fns/toDate";
import format from "date-fns/format";
import {
  showCloseDialog,
  showDelayDialog,
  showCommentDialog,
  showReassignDialog,
  showButtonClickDialog,
  showTicketFieldEditingDialog
} from "../../actions/tickets";
import { showNoteRequest } from "../../actions/notes";
import { showInfoRequest } from "../../actions/infos";
import { setTextToCopy } from "../../actions/layout";

class Ticket extends React.PureComponent {
  // TODO: Move functions that use this.props to parent components

  showClose = e => {
    this.props.dispatchShowCloseDialog(e.target.dataset.id);
  };

  showDelay = e => {
    this.props.dispatchShowDelayDialog(e.target.dataset.id);
  };

  showReassign = e => {
    this.props.dispatchShowReassignDialog(e.target.dataset.id);
  };

  showComment = ({ currentTarget }) => {
    this.props.dispatchShowCommentDialog(currentTarget.dataset);
  };

  showNote = e => {
    this.props.dispatchShowNoteRequest(e.target.dataset.note);
  };

  showInfo = e => {
    const { id } = this.props;
    const { name } = e.target.dataset;
    const value = e.target.parentElement.querySelector(".field-val").innerText;
    this.props.dispatchShowInfoRequest({ id, name, value });
  };

  handleTicketButtonClick = e => {
    const { id, name, withdialog, fields } = e.target.dataset;
    if (withdialog) {
      this.props.dispatchShowButtonClickDialog({ id, name, fields });
    } else {
      App.tickets.doAction("button_click", { id, name });
    }
  };

  showFieldEditing = e => {
    const { id, name } = e.target.dataset;
    const value = e.target.parentElement.querySelector(".field-val").innerText;
    this.props.dispatchShowTicketFieldEditingDialog({ id, name, value });
  };

  copyFieldValue = e => {
    const text = e.target.parentElement.querySelector(".field-val").innerText;
    this.props.dispatchSetTextToCopy(text);
  };

  actualizeTicket(e) {
    App.tickets.doAction("actualize", e.target.dataset.id);
  }

  takeTicket(e) {
    App.tickets.doAction("take", e.target.dataset.id);
  }

  returnTicket(e) {
    App.tickets.doAction("return", e.currentTarget.dataset.id);
  }

  handleTicket(e) {
    App.tickets.doAction("handle", e.currentTarget.dataset.id);
  }

  leaveTicket(e) {
    App.tickets.doAction("leave", e.currentTarget.dataset.id);
  }

  upcheckTicket(e) {
    App.tickets.doAction("upcheck", e.currentTarget.dataset.id);
  }

  findSimilar(e) {
    const { name } = e.currentTarget.dataset;
    const value = e.target.parentElement.querySelector(".field-val").innerText;
    App.tickets.doAction("search", `${name}:${value}`);
  }

  render() {
    const { id } = this.props;
    const {
      type,
      date,
      comment,
      delayed_to,
      owner,
      handler,
      flags,
      tries_count,
      max_tries,
      fields,
      buttons
    } = this.props.data;
    const dateTime = toDate(date * 1000);
    const delayedTo = delayed_to && toDate(delayed_to * 1000);
    const hasFlags = !!flags;
    const closable = hasFlags && (flags & 0b1) === 1;
    const delyable = hasFlags && ((flags >>> 1) & 0b1) === 1;
    const takable = hasFlags && ((flags >>> 2) & 0b1) === 1;
    const reassignable = hasFlags && ((flags >>> 3) & 0b1) === 1;
    return (
      <div className="mt-4 col-lg-4 col-md-6 col-xs-12">
        <div className="card">
          <div className="dinamic-ticket-actions">
            {handler ? null : (
              <i
                className="material-icons btn p-0"
                onClick={this.handleTicket}
                data-id={id}
                title="В обработку"
              >
                play_arrow
              </i>
            )}
            {takable ? (
              owner ? null : (
                <i
                  className="material-icons btn p-0"
                  onClick={this.takeTicket}
                  data-id={id}
                  title="Взять"
                >
                  pan_tool
                </i>
              )
            ) : null}
            {takable &&
              reassignable && (
                <i
                  className="material-icons btn p-0"
                  onClick={this.showReassign}
                  data-id={id}
                  title="Назначить"
                >
                  low_priority
                </i>
              )}
            {delayedTo ? (
              <i
                className="material-icons btn p-0"
                onClick={this.actualizeTicket}
                data-id={id}
                title="В текущие"
              >
                replay
              </i>
            ) : delyable ? (
              <i
                className="material-icons btn p-0"
                onClick={this.showDelay}
                data-id={id}
                title="Отложить"
              >
                event
              </i>
            ) : null}
            {closable && (
              <i
                className="material-icons btn p-0"
                onClick={this.showClose}
                data-id={id}
                title="Закрыть"
              >
                close
              </i>
            )}
          </div>
          <div className="card-body">
            <h5
              className="card-title pointer"
              onClick={this.showComment}
              data-id={id}
              data-comment={comment || ""}
            >
              {id} - {type}
            </h5>
            <h6 className="card-subtitle mb-2 text-muted">
              <span
                className="badge badge-secondary mr-1"
                title={`Создан ${format(dateTime, "d.MM.YYYY HH:mm:ss")}`}
              >
                {format(dateTime, "d.MM.YYYY")}
              </span>
              {delayedTo && (
                <span
                  className="badge badge-warning mr-1"
                  title={`Отложен до ${format(delayedTo, "d.MM.YYYY HH:mm")}`}
                >
                  <i className="material-icons small">alarm_on</i>
                  {format(delayedTo, "d.MM.YYYY")}
                </span>
              )}
              {owner && (
                <span
                  className="badge badge-info mr-1 pointer"
                  title={`Взят ${owner}`}
                  data-id={id}
                  onClick={this.returnTicket}
                >
                  <i className="material-icons small">pan_tool</i>
                </span>
              )}
              {handler && (
                <span
                  className="badge badge-danger mr-1 pointer"
                  title={`В обработке ${handler}`}
                  data-id={id}
                  onClick={this.leaveTicket}
                >
                  <i className="material-icons small">play_arrow</i>
                </span>
              )}
              {comment &&
                comment.trim() !== "" && (
                  <span
                    className="badge badge-success mr-1 pointer"
                    title={`Комментарий: ${comment}`}
                    data-id={id}
                    data-comment={comment}
                    onClick={this.showComment}
                  >
                    <i className="material-icons small">comment</i>
                  </span>
                )}
              {(tries_count || tries_count === 0) &&
                (max_tries || max_tries === 0) && (
                  <span
                    className={`badge ${
                      tries_count >= max_tries
                        ? "badge-danger"
                        : "badge-primary"
                    } mr-1 pointer`}
                    title={`Израсходовано попыток: ${tries_count} из ${max_tries}`}
                    data-id={id}
                    onClick={this.upcheckTicket}
                  >
                    {tries_count >= max_tries ? (
                      <i className="material-icons small">not_interested</i>
                    ) : (
                      `${tries_count} / ${max_tries}`
                    )}
                  </span>
                )}
            </h6>
            {fields && fields.map(this.renderField)}
            {buttons &&
              buttons.map(
                ([name, color, text_color, with_dialog, dialog_fields]) => {
                  return (
                    <button
                      key={name}
                      style={{ backgroundColor: color, color: text_color }}
                      className="btn btn-sm"
                      data-id={id}
                      data-name={name}
                      data-withdialog={with_dialog ? 1 : null}
                      data-fields={dialog_fields === "" ? null : dialog_fields}
                      onClick={this.handleTicketButtonClick}
                    >
                      {name}
                    </button>
                  );
                }
              )}
          </div>
        </div>
      </div>
    );
  }

  renderField = field => {
    const { id } = this.props;
    const { name, value, note_name, url, flags } = field;
    const hasFlags = !!flags;
    const editable = hasFlags && (flags & 0b1) === 1;
    const searchable = hasFlags && ((flags >>> 1) & 0b1) === 1;
    const copiable = hasFlags && ((flags >>> 2) & 0b1) === 1;
    const with_info = hasFlags && ((flags >>> 3) & 0b1) === 1;
    const valueElement = url ? (
      <a
        href={url}
        target={url.startsWith("tel:") ? "_self" : "_blank"}
        rel="noopener noreferrer"
        className="field-val"
      >
        {value}
      </a>
    ) : (
      <span className="field-val">{value}</span>
    );
    return (
      <div className="ticket-field" key={name}>
        {name}: {valueElement}
        {editable && (
          <i
            onClick={this.showFieldEditing}
            title="Редактировать"
            className="material-icons"
            data-id={id}
            data-name={name}
          >
            build
          </i>
        )}
        {searchable && (
          <i
            onClick={this.findSimilar}
            data-name={name}
            title="Найти все тикеты с такими же названием поля и значением"
            className="material-icons"
          >
            device_hub
          </i>
        )}
        {with_info && (
          <i
            onClick={this.showInfo}
            data-name={name}
            title="Посмотреть информацию"
            className="material-icons"
          >
            announcement
          </i>
        )}
        {note_name && (
          <i
            data-note={note_name}
            onClick={this.showNote}
            title={`Открыть заметку ${note_name}`}
            className="material-icons"
          >
            assignment
          </i>
        )}
        {copiable && (
          <i
            onClick={this.copyFieldValue}
            title="Копировать"
            className="material-icons"
          >
            library_add
          </i>
        )}
      </div>
    );
  };
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetTextToCopy: text => dispatch(setTextToCopy(text)),
    dispatchShowCloseDialog: id => dispatch(showCloseDialog(id)),
    dispatchShowDelayDialog: id => dispatch(showDelayDialog(id)),
    dispatchShowCommentDialog: payload => dispatch(showCommentDialog(payload)),
    dispatchShowNoteRequest: noteName => dispatch(showNoteRequest(noteName)),
    dispatchShowReassignDialog: id => dispatch(showReassignDialog(id)),
    dispatchShowButtonClickDialog: payload =>
      dispatch(showButtonClickDialog(payload)),
    dispatchShowInfoRequest: payload => dispatch(showInfoRequest(payload)),
    dispatchShowTicketFieldEditingDialog: payload =>
      dispatch(showTicketFieldEditingDialog(payload))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Ticket);
