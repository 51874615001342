import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Link } from "react-router-dom";

class ProjectForm extends React.Component {
  constructor(props) {
    super(props);
    const { name, description, api_key } = props.values;
    this.state = {
      name: { text: name || "", valid: !!name && name.length >= 3 },
      description: {
        text: description || "",
        valid: !!description && description.length >= 3
      },
      api_key: { text: api_key || "", valid: !!api_key }
    };
  }

  componentDidUpdate(prevProps) {
    const { values } = this.props;
    if (!values) return;
    if (values.api_key !== prevProps.values.api_key)
      this.setState({
        ...this.state,
        api_key: { text: values.api_key, valid: true }
      });
  }

  setValue = ({ target }) => {
    const { name } = target.dataset;
    const { value } = target;
    this.setState({
      [name]: {
        text: value,
        valid: value && value.trim() !== "" && value.length >= 3
      }
    });
  };

  clickSave = () => {
    const params = Object.keys(this.state).reduce((acc, key) => {
      acc[key] = this.state[key].text;
      return acc;
    }, {});
    this.props.onSave(params);
  };

  showKey = () => {
    this.setState({ showKey: true });
  };

  render() {
    const { classes, isSaving, onRegenerate } = this.props;
    const { name, description, api_key, showKey } = this.state;
    return (
      <div>
        <form className={classes.container} noValidate autoComplete="off">
          <TextField
            required
            className={classes.textField}
            label="Название"
            placeholder="Название"
            helperText="Название проекта"
            margin="normal"
            onChange={this.setValue}
            value={name.text}
            error={!name.valid}
            inputProps={{ "data-name": "name" }}
          />
          <TextField
            required
            className={classes.textField}
            label="Описание"
            placeholder="Описание"
            helperText="Описание проекта"
            margin="normal"
            onChange={this.setValue}
            value={description.text}
            error={!description.valid}
            inputProps={{ "data-name": "description" }}
          />
          {showKey ? (
            <TextField
              required
              label="Ключ API проекта"
              placeholder="Ключ API проекта"
              className={classes.textField}
              helperText="Ключ API проекта"
              margin="normal"
              value={api_key.text}
              error={!api_key.valid}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    className="pointer"
                    position="end"
                    onClick={onRegenerate}
                    title="Сгенерировать"
                  >
                    <i className="material-icons">refresh</i>
                  </InputAdornment>
                )
              }}
            />
          ) : null}
        </form>
        {onRegenerate && !showKey && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.showKey}
          >
            Показать API-ключ проекта
          </Button>
        )}
        <Button
          component={Link}
          to="/projects"
          variant="outlined"
          color="secondary"
          className={`btn ${classes.link}`}
        >
          Назад
        </Button>
        {[name, description].every(key => key.valid) ? (
          <Button
            disabled={isSaving}
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.clickSave}
          >
            Сохранить
          </Button>
        ) : null}
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "30px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: 0,
    width: 600
  },
  button: {
    margin: "10px 20px 10px 5px"
  },
  link: {
    "&:hover": {
      color: "#f50057"
    }
  },
});

export default withStyles(styles)(ProjectForm);
