import axios from "axios";

export const getList = projectId => {
  return axios.get(`/projects/${projectId}/tasks/list`);
};

export const get = projectId => {
  return axios.get(`/projects/${projectId}/tasks`);
};

export const create = (projectId, values) => {
  return axios.post(`/projects/${projectId}/tasks`, {
    authenticity_token: fetchAuthenticityToken(),
    ...buildTaskParams(values)
  });
};

export const update = (projectId, { id, ...values }) => {
  return axios.patch(`/projects/${projectId}/tasks/${id}`, {
    authenticity_token: fetchAuthenticityToken(),
    ...buildTaskParams(values)
  });
};

export const destroy = (projectId, id) => {
  return axios.delete(`/projects/${projectId}/tasks/${id}`, {
    data: { authenticity_token: fetchAuthenticityToken() }
  });
};

export const run = (projectId, id) => {
  return axios.post(`/projects/${projectId}/tasks/${id}/run`, {
    authenticity_token: fetchAuthenticityToken()
  });
};

export const stop = (projectId, id) => {
  return axios.post(`/projects/${projectId}/tasks/${id}/stop`, {
    authenticity_token: fetchAuthenticityToken()
  });
};

// private

const fetchAuthenticityToken = () => {
  return document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");
};

const buildTaskParams = rawState => {
  const {
    name,
    description,
    typeId,
    subId,
    maxTickets,
    sendPush,
    withSource,
    sourceUrl,
    periodic,
    runMinutes,
    runHours,
    runDays,
    runMonths,
    severalTicketsPerTime,
    useSubscriptionFromResponse
  } = rawState;
  return {
    name: name.trim(),
    description: description.trim(),
    ticket_type_id: typeId,
    subscription_chunk_id: subId,
    max_tickets: maxTickets,
    send_push: sendPush,
    with_source: withSource,
    source_url: sourceUrl,
    periodic,
    run_minutes:
      runMinutes === "" ? [] : runMinutes.replace(/,$/, "").split(","),
    run_hours: runHours === "" ? [] : runHours.replace(/,$/, "").split(","),
    run_days: runDays === "" ? [] : runDays.replace(/,$/, "").split(","),
    run_months: runMonths === "" ? [] : runMonths.replace(/,$/, "").split(","),
    several_tickets_per_time: severalTicketsPerTime,
    use_subscription_from_response: useSubscriptionFromResponse
  };
};
