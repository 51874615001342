import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";

class TaskTable extends React.PureComponent {
  render() {
    const {
      classes,
      filter,
      tasks,
      types,
      subs,
      edit,
      destroy,
      run,
      stop
    } = this.props;
    const filterRegexp = new RegExp(
      filter.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"),
      "i"
    );
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Название</TableCell>
              <TableCell numeric>Тип тикета</TableCell>
              <TableCell numeric>Подписка</TableCell>
              <TableCell numeric>Макс. тикетов</TableCell>
              <TableCell numeric>Слать пуш</TableCell>
              <TableCell numeric>Периодический</TableCell>
              <TableCell numeric>C запросом</TableCell>
              <TableCell numeric>Несколько тикетов за раз</TableCell>
              {edit && <TableCell numeric>Изменить</TableCell>}
              {destroy && <TableCell numeric>Удалить</TableCell>}
              <TableCell numeric>Запуск/Пауза</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks
              .filter(
                t =>
                  filterRegexp.test(t.name) || filterRegexp.test(t.description)
              )
              .map(task => {
                const { id, ticket_type, subscription } = task;
                return (
                  <TableRow key={id}>
                    <TableCell>{task.name}</TableCell>
                    <TableCell numeric>{types[ticket_type]}</TableCell>
                    <TableCell numeric>{subs[subscription]}</TableCell>
                    <TableCell numeric>{task.max_tickets}</TableCell>
                    <TableCell numeric>
                      {task.send_push ? (
                        <i className={`material-icons ${classes.green}`}>
                          done
                        </i>
                      ) : (
                        <i className={`material-icons ${classes.red}`}>close</i>
                      )}
                    </TableCell>
                    <TableCell numeric>
                      {task.periodic ? (
                        <i className={`material-icons ${classes.green}`}>
                          done
                        </i>
                      ) : (
                        <i className={`material-icons ${classes.red}`}>close</i>
                      )}
                    </TableCell>
                    <TableCell numeric>
                      {task.with_source ? (
                        <i className={`material-icons ${classes.green}`}>
                          done
                        </i>
                      ) : (
                        <i className={`material-icons ${classes.red}`}>close</i>
                      )}
                    </TableCell>
                    <TableCell numeric>
                      {task.several_tickets_per_time ? (
                        <i className={`material-icons ${classes.green}`}>
                          done
                        </i>
                      ) : (
                        <i className={`material-icons ${classes.red}`}>close</i>
                      )}
                    </TableCell>
                    {edit && (
                      <TableCell numeric>
                        <Button color="secondary" onClick={edit} data-id={id}>
                          <i className="material-icons">build</i>
                        </Button>
                      </TableCell>
                    )}
                    {destroy && (
                      <TableCell numeric>
                        {task.in_chain ? (
                          <span title="Задача находится в одной или более цепей, поэтому не может быть удалена">
                            В цепи
                          </span>
                        ) : (
                          <Button
                            color="secondary"
                            onClick={destroy}
                            data-id={id}
                          >
                            <i className="material-icons">delete</i>
                          </Button>
                        )}
                      </TableCell>
                    )}
                    <TableCell numeric>
                      {ticket_type &&
                        subscription &&
                        (task.periodic && task.active ? (
                          <Button color="primary" onClick={stop} data-id={id}>
                            <i className="material-icons">pause</i>
                          </Button>
                        ) : (
                          <Button color="primary" onClick={run} data-id={id}>
                            <i className="material-icons">play_arrow</i>
                          </Button>
                        ))}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    marginBottom: theme.spacing.unit * 3
  },
  table: {
    minWidth: 700
  },
  green: {
    color: "#00bb00"
  },
  red: {
    color: "#dd0000"
  }
});

export default withStyles(styles)(TaskTable);
