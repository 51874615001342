import React, { PureComponent } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

class Subscription extends PureComponent {
  change = ({ target }) => {
    const { name, setValue } = this.props;
    const { value } = target;
    setValue(name, value);
  };

  render() {
    console.log('2')
    const { title, name, value, subscriptions } = this.props;
    return (
      <FormControl key={name} fullWidth>
        <InputLabel htmlFor={`${name}-subscription`}>{title}</InputLabel>
        <Select
          value={value || ""}
          onChange={this.change}
          inputProps={{ name, id: `${name}-subscription` }}
        >
          <MenuItem value="">
            <em>Нет</em>
          </MenuItem>
          {Object.keys(subscriptions).map(id => (
            <MenuItem key={id} value={id}>
              {subscriptions[id]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export default Subscription;
