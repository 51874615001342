import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";

class RoleTable extends React.PureComponent {
  render() {
    const {
      classes,
      roles,
      setEditedName,
      cancelEdit,
      saveEdit,
      toggleEdit,
      toggleRoleParam,
      destroyRole
    } = this.props;
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Название</TableCell>
              <TableCell numeric>Могут редактировать проект</TableCell>
              <TableCell numeric>Доступ к задачам</TableCell>
              <TableCell numeric>Доступ к цепям</TableCell>
              <TableCell numeric>Доступ к чужим тикетам</TableCell>
              <TableCell numeric>Редактирование заметок</TableCell>
              <TableCell numeric>Доступ к сервисам</TableCell>
              <TableCell numeric>Удалить</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map((role, index) => {
              return (
                <TableRow key={role.id}>
                  <TableCell component="th" scope="row">
                    {role.editing ? (
                      <div>
                        <TextField
                          autoFocus
                          margin="dense"
                          value={role.editedName}
                          onChange={setEditedName(index)}
                        />
                        <Button
                          onClick={cancelEdit}
                          color="primary"
                          data-index={index}
                        >
                          <i className="material-icons">close</i>
                        </Button>
                        {role.name === role.editedName ? null : (
                          <Button
                            data-index={index}
                            onClick={saveEdit}
                            color="primary"
                          >
                            <i className="material-icons">check</i>
                          </Button>
                        )}
                      </div>
                    ) : (
                      <div onClick={toggleEdit} data-index={index}>
                        {role.name}
                      </div>
                    )}
                  </TableCell>
                  {[
                    "can_edit",
                    "see_tasks",
                    "see_chains",
                    "see_other_tickets",
                    "can_update_notes",
                    "see_services"
                  ].map(name => {
                    return (
                      <TableCell key={name} component="th" numeric>
                        <Checkbox
                          checked={role[name]}
                          onChange={toggleRoleParam(index, name)}
                          color="primary"
                        />
                      </TableCell>
                    );
                  })}
                  <TableCell component="th" numeric>
                    <Button
                      color="secondary"
                      onClick={destroyRole}
                      data-id={role.id}
                      size="small"
                      title="Удалить роль из проекта"
                    >
                      <i className="material-icons">delete</i>
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    marginBottom: theme.spacing.unit * 3
  },
  table: {
    minWidth: 700
  }
});

export default withStyles(styles)(RoleTable);
