import { handleActions } from "redux-actions";
import {
  showInfoSuccess,
  showInfoError,
  hideFieldInfo
} from "../actions/infos";

const initState = { fieldInfoText: null };

const Infos = handleActions(
  {
    [showInfoSuccess]: (state, { payload }) => {
      return { ...state, fieldInfoText: payload };
    },
    [showInfoError]: state => {
      return { ...state, fieldInfoText: "Ошибка получения информации" };
    },
    [hideFieldInfo]: state => {
      return { ...state, fieldInfoText: null };
    }
  },
  initState
);

export default Infos;
