import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import RoleSelector from "./RoleSelector";
import SubscriptionSelector from "./SubscriptionSelector";

class StaffDialog extends React.PureComponent {
  render() {
    const {
      classes,
      showDialog,
      roleIds,
      setEmail,
      selectedRoles,
      closeDialogs,
      id,
      email,
      addUser,
      updateRolesAndSubscriptions
    } = this.props;
    return (
      <Dialog
        fullScreen
        open={showDialog}
        onClose={() => {}}
        aria-labelledby="add-user-dialog"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={closeDialogs}
              aria-label="Close"
            >
              <i className="material-icons">close</i>
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              className={classes.flex}
            >
              {id
                ? `Изменение ролей и подписок пользователя ${email}`
                : "Добавление нового сотрудника"}
            </Typography>
            {selectedRoles.size > 0 && (id || email.match(/@/)) ? (
              <Button
                onClick={id ? updateRolesAndSubscriptions : addUser}
                color="inherit"
              >
                Сохранить
              </Button>
            ) : null}
          </Toolbar>
        </AppBar>
        <DialogContent>
          {id ? (
            this.renderSelectors()
          ) : roleIds.length > 0 ? (
            <div>
              <TextField
                autoFocus
                margin="dense"
                label="Email"
                fullWidth
                value={email}
                onChange={setEmail}
              />
              {this.renderSelectors()}
            </div>
          ) : (
            <div>
              Невозможно добавить сотрудника, так как в проекте не определена ни
              одна роль
            </div>
          )}
        </DialogContent>
      </Dialog>
    );
  }

  renderSelectors = () => {
    const {
      roles,
      roleIds,
      selectedRoles,
      toggleRole,
      subscriptions,
      subscriptionIds,
      selectedSubscriptions,
      toggleSubscription
    } = this.props;
    return (
      <div>
        <hr />
        <RoleSelector
          roles={roles}
          roleIds={roleIds}
          selectedRoles={selectedRoles}
          toggleRole={toggleRole}
        />
        <hr />
        <SubscriptionSelector
          subscriptions={subscriptions}
          subscriptionIds={subscriptionIds}
          selectedSubscriptions={selectedSubscriptions}
          toggleSubscription={toggleSubscription}
        />
      </div>
    );
  };
}

const styles = () => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  }
});

export default withStyles(styles)(StaffDialog);
