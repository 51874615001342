import axios from "axios";

export const getList = projectId => {
  return axios.get(`/projects/${projectId}/chains/list`);
};

export const get = projectId => {
  return axios.get(`/projects/${projectId}/chains`);
};

export const create = (projectId, name, taskSelections) => {
  return axios.post(`/projects/${projectId}/chains`, {
    authenticity_token: fetchAuthenticityToken(),
    name: name.trim(),
    tasks: JSON.stringify(
      taskSelections.map(t => {
        return { id: t.id, delay: t.delay };
      })
    )
  });
};

export const edit = (projectId, id) => {
  return axios.get(`/projects/${projectId}/chains/${id}/edit`);
};

export const update = (projectId, id, name, webhook, taskSelections) => {
  return axios.patch(`/projects/${projectId}/chains/${id}`, {
    authenticity_token: fetchAuthenticityToken(),
    name: name.trim(),
    webhook: webhook.trim(),
    tasks: JSON.stringify(
      taskSelections.map(t => {
        return { id: t.id, delay: t.delay };
      })
    )
  });
};

export const destroy = (projectId, id) => {
  return axios.delete(`/projects/${projectId}/chains/${id}`, {
    data: { authenticity_token: fetchAuthenticityToken() }
  });
};

export const run = (projectId, id, mark) => {
  return axios.post(`/projects/${projectId}/chains/${id}/run`, {
    authenticity_token: fetchAuthenticityToken(),
    mark: mark.trim()
  });
};

// private

const fetchAuthenticityToken = () => {
  return document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");
};
