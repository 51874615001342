import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import TextCopyWrapper from "../TextCopyWrapper";
import { hideFieldInfo } from "../../actions/infos";
import { setTextToCopy } from "../../actions/layout";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class FieldInfoDialog extends React.PureComponent {
  copy = () => {
    const { fieldInfoText, dispatchSetTextToCopy } = this.props;
    dispatchSetTextToCopy(fieldInfoText);
  };

  render() {
    const { fieldInfoText } = this.props;
    return (
      <Dialog
        open={!!fieldInfoText || fieldInfoText === ""}
        TransitionComponent={Transition}
        onClose={() => {}}
      >
        <DialogContent>
          {fieldInfoText &&
            fieldInfoText
              .split("\n")
              .map((line, index) => <div key={index}>{line}</div>)}
        </DialogContent>
        <DialogActions>
          <TextCopyWrapper />
          <Button onClick={this.copy} color="primary">
            Копировать
          </Button>
          <Button onClick={this.props.dispatchHideFieldInfo} color="primary">
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    fieldInfoText: state.infos.fieldInfoText
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetTextToCopy: text => dispatch(setTextToCopy(text)),
    dispatchHideFieldInfo: () => dispatch(hideFieldInfo())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FieldInfoDialog);
