import axios from "axios";

export const get = projectId => {
  return axios.get(`/projects/${projectId}/project_roles`);
};

export const create = (projectId, name) => {
  return axios.post(`/projects/${projectId}/project_roles`, {
    authenticity_token: fetchAuthenticityToken(),
    name: name.trim()
  });
};

export const update = (projectId, id, params) => {
  return axios.patch(`/projects/${projectId}/project_roles/${id}`, {
    authenticity_token: fetchAuthenticityToken(),
    ...params
  });
};

export const destroy = (projectId, id) => {
  return axios.delete(
    `/projects/${projectId}/project_roles/${id}`,
    {
      data: { authenticity_token: fetchAuthenticityToken() }
    }
  );
};

// private

const fetchAuthenticityToken = () => {
  return document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");
};
