import { showNoteRequest, showNoteSuccess } from "../actions/notes";
import { show } from "../api/notes";

const showNote = store => next => action => {
  if (action.type === showNoteRequest.toString()) {
    const projectId = store.getState().layout.currentProject.id;
    show(projectId, action.payload)
      .then(({ data }) => store.dispatch(showNoteSuccess(data)))
      .catch(error => console.log(error));
  }
  return next(action);
};

export default showNote;
