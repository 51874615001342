import { saveNoteRequest, hideNote } from "../actions/notes";
import { showAlert } from "../actions/layout";
import { update } from "../api/notes";

const saveNote = store => next => action => {
  if (action.type === saveNoteRequest.toString()) {
    const projectId = store.getState().layout.currentProject.id;
    const { name, text } = store.getState().notes;
    update(projectId, name, { text })
      .then(() => store.dispatch(hideNote()))
      .catch(() =>
        store.dispatch(
          showAlert({ text: "Ошибка сохранения заметки", color: "error" })
        )
      );
  }
  return next(action);
};

export default saveNote;
