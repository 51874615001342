import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { setTicketComment, hideCommentDialog } from "../../actions/tickets";

class CommentDialog extends React.Component {
  setComment = e => {
    this.props.dispatchSetTicketComment(e.target.value);
  };

  hideCommenting = () => {
    this.props.dispatchHideCommentDialog();
  };

  saveComment = () => {
    App.tickets.doAction("save_comment", this.props.ticketToComment);
    this.hideCommenting();
  };

  saveCommentByEnter = ({ key }) => {
    if (key !== "Enter") return;
    App.tickets.doAction("save_comment", this.props.ticketToComment);
    this.hideCommenting();
  };

  render() {
    const { ticketToComment } = this.props;
    const { id, comment } = ticketToComment
      ? ticketToComment
      : { id: null, comment: "" };
    return (
      <Dialog
        open={!!id}
        onClose={() => {}}
        aria-labelledby="form-comment-title"
      >
        <DialogTitle id="form-comment-title">
          Комментировать тикет {id}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Оставленный комментарий будет привязан к данному тикету
          </DialogContentText>
          <TextField
            autoFocus
            label="Текст комментария"
            fullWidth
            value={comment}
            onChange={this.setComment}
            onKeyUp={this.saveCommentByEnter}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.hideCommenting} color="primary">
            Отмена
          </Button>
          <Button onClick={this.saveComment} color="primary">
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    ticketToComment: state.tickets.ticketToComment
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetTicketComment: text => dispatch(setTicketComment(text)),
    dispatchHideCommentDialog: () => dispatch(hideCommentDialog())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentDialog);
