import { createActions } from "redux-actions";

export const {
  showNoteRequest,
  showNoteSuccess,
  hideNote,
  changeNote,
  saveNoteRequest,
  showSearchNoteDialog,
  hideSearchNoteDialog
} = createActions(
  "SHOW_NOTE_REQUEST",
  "SHOW_NOTE_SUCCESS",
  "HIDE_NOTE",
  "CHANGE_NOTE",
  "SAVE_NOTE_REQUEST",
  "SHOW_SEARCH_NOTE_DIALOG",
  "HIDE_SEARCH_NOTE_DIALOG",
);
