import { createActions } from "redux-actions";

export const {
  setChannelConnected,
  setChannelDisconnected,
  addBasicCollection,
  addCurrentTicket,
  addDelayedTicket,
  removeTicket,
  moveCurrentTicketToDelayed,
  moveDelayedTicketToCurrent,
  moveTicketToOther,
  moveOtherTicketToCurrent,
  moveOtherTicketToDelayed,
  setFilter,
  toggleShowFree,
  toggleShowOwned,
  setLoadingState,
  addOtherTicketCollection,
  resetOtherTicketCollection,
  addFoundTicketCollection,
  hideFound,
  showCloseDialog,
  hideCloseDialog,
  showDelayDialog,
  hideDelayDialog,
  showCommentDialog,
  setTicketComment,
  hideCommentDialog,
  showReassignDialog,
  hideReassignDialog,
  showButtonClickDialog,
  hideButtonClickDialog,
  showTicketFieldEditingDialog,
  hideTicketFieldEditingDialog,
  removeTicketGroup,
  refreshTicketsRequest,
  updateTicket,
  setPageNumber
} = createActions(
  "SET_CHANNEL_CONNECTED",
  "SET_CHANNEL_DISCONNECTED",
  "ADD_BASIC_COLLECTION",
  "ADD_CURRENT_TICKET",
  "ADD_DELAYED_TICKET",
  "REMOVE_TICKET",
  "MOVE_CURRENT_TICKET_TO_DELAYED",
  "MOVE_DELAYED_TICKET_TO_CURRENT",
  "MOVE_TICKET_TO_OTHER",
  "MOVE_OTHER_TICKET_TO_CURRENT",
  "MOVE_OTHER_TICKET_TO_DELAYED",
  "SET_FILTER",
  "TOGGLE_SHOW_FREE",
  "TOGGLE_SHOW_OWNED",
  "SET_LOADING_STATE",
  "ADD_OTHER_TICKET_COLLECTION",
  "RESET_OTHER_TICKET_COLLECTION",
  "ADD_FOUND_TICKET_COLLECTION",
  "HIDE_FOUND",
  "SHOW_CLOSE_DIALOG",
  "HIDE_CLOSE_DIALOG",
  "SHOW_DELAY_DIALOG",
  "HIDE_DELAY_DIALOG",
  "SHOW_COMMENT_DIALOG",
  "SET_TICKET_COMMENT",
  "HIDE_COMMENT_DIALOG",
  "SHOW_REASSIGN_DIALOG",
  "HIDE_REASSIGN_DIALOG",
  "SHOW_BUTTON_CLICK_DIALOG",
  "HIDE_BUTTON_CLICK_DIALOG",
  "SHOW_TICKET_FIELD_EDITING_DIALOG",
  "HIDE_TICKET_FIELD_EDITING_DIALOG",
  "REMOVE_TICKET_GROUP",
  "REFRESH_TICKETS_REQUEST",
  "UPDATE_TICKET",
  "SET_PAGE_NUMBER"
);
