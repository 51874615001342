import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";

class StaffTable extends React.PureComponent {
  subNameSort = (id1, id2) => {
    const subs = this.props.subscriptions;
    return (subs[id1].path >= subs[id2].path) ? 1 : -1;
  }

  render() {
    const {
      classes,
      staff,
      roles,
      subscriptions,
      deleteRoleOrSub,
      editUser,
      removeUser
    } = this.props;
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Роли</TableCell>
              <TableCell>Подписки</TableCell>
              <TableCell numeric>Изменить</TableCell>
              <TableCell numeric>Удалить</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {staff.map((user, index) => {
              return (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {user.email}
                  </TableCell>
                  <TableCell component="th">
                    {user.roles.map(id => (
                      <Chip
                        key={id}
                        label={roles[id]}
                        onDelete={deleteRoleOrSub("roles", id, user.id)}
                        className={classes.chip}
                      />
                    ))}
                  </TableCell>
                  <TableCell component="th">
                    {user.subscriptions.sort(this.subNameSort).map(id => {
                      const { path, active } = subscriptions[id];
                      return (
                        <Chip
                          key={id}
                          label={path}
                          onDelete={deleteRoleOrSub("subs", id, user.id)}
                          className={classes.chip}
                          color={active ? "primary" : "secondary"}
                        />
                      );
                    })}
                  </TableCell>
                  <TableCell component="th" numeric>
                    <Button
                      color="secondary"
                      onClick={editUser}
                      size="small"
                      title="Изменить роли и подписки"
                      data-index={index}
                    >
                      <i className="material-icons">build</i>
                    </Button>
                  </TableCell>
                  <TableCell component="th" numeric>
                    <Button
                      color="secondary"
                      onClick={removeUser}
                      data-id={user.id}
                      size="small"
                      title="Удалить пользователя из проекта"
                    >
                      <i className="material-icons">delete</i>
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    marginBottom: theme.spacing.unit * 3
  },
  table: {
    minWidth: 700
  },
  chip: {
    margin: theme.spacing.unit / 2
  }
});

export default withStyles(styles)(StaffTable);
