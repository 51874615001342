import { handleActions } from "redux-actions";
import {
  showNoteSuccess,
  hideNote,
  changeNote,
  showSearchNoteDialog,
  hideSearchNoteDialog
} from "../actions/notes";

const initState = {
  showNote: false,
  name: "",
  text: "",
  can_update: false,
  showSearchNoteDialog: false
};

const Notes = handleActions(
  {
    [showNoteSuccess]: (state, { payload }) => {
      return {
        ...state,
        showNote: true,
        name: payload.name,
        text: payload.text,
        can_update: payload.can_update
      };
    },
    [hideNote]: state => {
      return {
        ...state,
        showNote: false,
        name: "",
        text: "",
        can_update: false
      };
    },
    [changeNote]: (state, { payload }) => {
      return { ...state, text: payload };
    },
    [showSearchNoteDialog]: state => {
      return { ...state, showSearchNoteDialog: true };
    },
    [hideSearchNoteDialog]: state => {
      return { ...state, showSearchNoteDialog: false };
    }
  },
  initState
);

export default Notes;
