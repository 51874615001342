import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import { removePoppedNotification } from "../../actions/layout";
import { showNoteRequest } from "../../actions/notes";

class PoppedNotification extends React.PureComponent {
  componentDidMount() {
    this.props.removeByTimeout(this.props.id);
  }

  render() {
    const {
      classes,
      removePoppedNotification,
      showNote,
      id,
      text,
      options
    } = this.props;
    const parsedOptions = JSON.parse(options);
    const { note, notes, link, links } = parsedOptions;
    return (
      <div className={classes.poppedNotification}>
        <i
          className={`${
            classes.closeNotificationButton
          } material-icons btn p-0`}
          title="Закрыть"
          data-id={id}
          onClick={removePoppedNotification}
        >
          close
        </i>
        <h6>{text}</h6>
        <div>
          {note && note !== "" ? (
            <span className="pointer mr-1" title={`Открыть заметку ${note}`}>
              <i className="material-icons btn p-0">assignment</i>
              <span onClick={showNote}>{note}</span>
            </span>
          ) : null}
          {notes && notes.length > 0
            ? notes.map((note, index) => (
                <span
                  className="pointer mr-1"
                  key={index}
                  title={`Открыть заметку ${note}`}
                >
                  <i className={"material-icons btn p-0"}>assignment</i>
                  <span onClick={showNote}>{note}</span>
                </span>
              ))
            : null}
        </div>
        <div>
          {link ? (
            <span className="mr-1">
              <a
                className={classes.notificationLink}
                target="_blank"
                rel="noopener noreferrer"
                href={link.url}
              >
                {link.title || "Перейти"}
              </a>
            </span>
          ) : null}
          {links && links.length > 0
            ? links.map((link, index) => (
                <span key={index} className="mr-1">
                  <a
                    className={classes.notificationLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={link.url}
                  >
                    {link.title || "Перейти"}
                  </a>
                </span>
              ))
            : null}
        </div>
      </div>
    );
  }
}

class NotificationTape extends React.PureComponent {
  removePoppedNotification = ({ currentTarget }) => {
    const { id } = currentTarget.dataset;
    this.props.dispatchRemovePoppedNotification(id);
  };

  removePoppedNotificationByTimeout = id => {
    setTimeout(() => this.props.dispatchRemovePoppedNotification(id), 60000);
  };

  showNote = ({ currentTarget }) => {
    this.props.dispatchShowNoteRequest(currentTarget.innerText);
  };

  render() {
    const { classes, poppedNotificationList } = this.props;
    return (
      <div className={classes.noificationTapeWrapper}>
        <div className={classes.toolbar} />
        {poppedNotificationList.map(notification => (
          <PoppedNotification
            {...notification}
            key={notification.id}
            removePoppedNotification={this.removePoppedNotification}
            removeByTimeout={this.removePoppedNotificationByTimeout}
            classes={classes}
            showNote={this.showNote}
          />
        ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    poppedNotificationList: state.layout.poppedNotificationList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchShowNoteRequest: noteName => dispatch(showNoteRequest(noteName)),
    dispatchRemovePoppedNotification: id =>
      dispatch(removePoppedNotification(id))
  };
}

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  noificationTapeWrapper: {
    position: "fixed",
    zIndex: "1",
    right: 0,
    top: theme.mixins.toolbar,
    overflow: "hidden",
    transition: "opacity 0.4s",
    opacity: "0.4",
    "&:hover": {
      opacity: "1"
    }
  },
  poppedNotification: {
    width: "260px",
    backgroundColor: "#3f51b5",
    color: "white",
    borderRadius: "5px",
    margin: "4px 2px",
    padding: "14px",
    wordWrap: "break-word",
    position: "relative",
    border: "1px solid white"
  },
  closeNotificationButton: {
    position: "absolute",
    top: "-3px",
    right: "0px",
    fontWeight: 800
  },
  notificationLink: {
    color: "white",
    textDecoration: "underline"
  }
});

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NotificationTape);
