import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import Ticket from "./Ticket";
import Filter from "./Filter";
import Pagination from "./Pagination";
import TextCopyWrapper from '../TextCopyWrapper'
import { setPageNumber, hideFound } from "../../actions/tickets";

const pageLimit = 36;

class FoundDialog extends React.PureComponent {
  render() {
    if (!this.props.channelConnected) return <LinearProgress />;
    const {
      classes,
      tickets,
      types,
      filter,
      dispatchHideFound,
      showFound,
      page,
      dispatchSetPageNumber
    } = this.props;
    const takeTo = page * pageLimit;
    const offset = takeTo - pageLimit;
    const allTicketIds = Object.keys(tickets);
    const ticketIds =
      filter === ""
        ? []
        : allTicketIds.filter(id => filter === tickets[id].type);
    const pageTicketIds = ticketIds.slice(offset, takeTo);
    const totalPages = Math.ceil(ticketIds.length / pageLimit);
    return (
      <Dialog
        fullScreen
        open={showFound}
        onClose={() => {}}
        aria-labelledby="note-dialog"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={dispatchHideFound}
              aria-label="Close"
            >
              <i className="material-icons">close</i>
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              className={classes.flex}
            >
              {`Найденные тикеты (${allTicketIds.length}):`}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div className={classes.filterMargin} />
          <Filter types={types} page="found" filter={filter} />
          <div className="row">
            {pageTicketIds.map(id => (
              <Ticket key={id} id={id} data={tickets[id]} />
            ))}
          </div>
          <div className={classes.bottomPadded}>
            <Pagination
              page={page}
              totalPages={totalPages}
              setPageNumber={dispatchSetPageNumber}
              name="found"
            />
          </div>
        </DialogContent>
        <TextCopyWrapper />
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  const {
    channelConnected,
    showFound,
    found,
    foundTypes,
    foundTicketsFilter,
    foundTicketPageNumber
  } = state.tickets;
  return {
    showFound,
    channelConnected,
    tickets: found,
    types: foundTypes,
    filter: foundTicketsFilter,
    page: foundTicketPageNumber
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchHideFound: () => dispatch(hideFound()),
    dispatchSetPageNumber: payload => dispatch(setPageNumber(payload))
  };
}

const styles = () => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  bottomPadded: {
    paddingBottom: "20px"
  },
  filterMargin: {
    marginTop: "24px"
  }
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(FoundDialog)
);
