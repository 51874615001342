import React from "react";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";

const Sidebar = props => {
  const { classes, showSidebarText } = props;
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: showSidebarText ? classes.drawerPaper : classes.drawerPaperNoText
      }}
    >
      <div className={classes.toolbar} />
      <List>
        <ListItem
          component={NavLink}
          exact
          to="/"
          activeClassName={classes.active}
          button
        >
          <ListItemIcon>
            <i className="material-icons">flash_on</i>
          </ListItemIcon>
          {showSidebarText ? <ListItemText primary="Текущие" /> : null}
        </ListItem>
        <ListItem
          component={NavLink}
          exact
          to="/delayed"
          activeClassName={classes.active}
          button
        >
          <ListItemIcon>
            <i className="material-icons">hourglass_empty</i>
          </ListItemIcon>
          {showSidebarText ? <ListItemText primary="Отложенные" /> : null}
        </ListItem>
        <ListItem
          component={NavLink}
          exact
          to="/other"
          activeClassName={classes.active}
          button
        >
          <ListItemIcon>
            <i className="material-icons">business_center</i>
          </ListItemIcon>
          {showSidebarText ? <ListItemText primary="Чужие" /> : null}
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          component={NavLink}
          exact
          to="/tasks"
          activeClassName={classes.active}
          button
        >
          <ListItemIcon>
            <i className="material-icons">outlined_flag</i>
          </ListItemIcon>
          {showSidebarText ? <ListItemText primary="Задачи" /> : null}
        </ListItem>
        <ListItem
          component={NavLink}
          exact
          to="/chains"
          activeClassName={classes.active}
          button
        >
          <ListItemIcon>
            <i className="material-icons">link</i>
          </ListItemIcon>
          {showSidebarText ? <ListItemText primary="Цепи задач" /> : null}
        </ListItem>
      </List>
    </Drawer>
  );
};

function mapStateToProps(state) {
  const { showSidebarText } = state.layout;
  return {
    showSidebarText: showSidebarText
  };
}

const styles = theme => ({
  drawerPaper: {
    position: "relative",
    width: 240,
    transition: "width 0.5s"
  },
  drawerPaperNoText: {
    position: "relative",
    width: 70,
    transition: "width 0.5s"
  },
  active: {
    backgroundColor: "#eee"
  },
  toolbar: theme.mixins.toolbar
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps)
)(Sidebar);
