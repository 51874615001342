import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";
import { setCurrentProject } from "../actions/layout";

class ProjectSelectPage extends React.Component {
  state = {
    isLoading: true,
    projects: []
  };

  setProject = e => {
    const { id, name } = e.currentTarget.dataset;
    this.props.dispatchSetCurrentProject({ id: parseInt(id), name: name });
  };

  componentDidMount() {
    axios
      .get("/projects/list")
      .then(({ data }) => this.setState({ projects: data, isLoading: false }))
      .catch(error => console.log(error));
  }

  render() {
    const { isLoading, projects } = this.state;
    const { classes } = this.props;
    if (isLoading)
      return (
        <div>
          <div className={classes.toolbar} />
          <LinearProgress />
        </div>
      );
    return (
      <div>
        <div className={classes.toolbar} />
        <Button
          className={classes.fixed}
          component={Link}
          to="/projects/new"
          variant="fab"
          color="primary"
          title="Создать новый проект"
        >
          <i className="material-icons">add</i>
        </Button>
        <div className="container-fluid">
          <div className="row">{projects.map(this.renderProjectCard)}</div>
        </div>
      </div>
    );
  }

  renderProjectCard = project => {
    return (
      <div
        key={project.id}
        className="mt-4 col-lg-3 col-md-4 col-sm-6 col-xs-12"
      >
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{project.name}</h5>
            <p className="card-text">{project.description}</p>
            <Button
              className="mr-1"
              variant="outlined"
              color="primary"
              onClick={this.setProject}
              data-id={project.id}
              data-name={project.name}
            >
              Перейти
            </Button>
            {(project.owner || project.can_edit) && (
              <Button
                className="mr-1"
                component={Link}
                to={`/projects/${project.id}/edit`}
                variant="outlined"
                color="primary"
              >
                Настройки
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetCurrentProject: payload => dispatch(setCurrentProject(payload))
  };
}

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  fixed: {
    position: "fixed",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
    zIndex: 1
  }
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProjectSelectPage);
