import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";

class TypeTable extends React.PureComponent {
  render() {
    const { classes, filter, types, editType, destroyType } = this.props;
    const filterRegexp = new RegExp(
      filter.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"),
      "i"
    );
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell numeric>Название типа</TableCell>
              <TableCell numeric>Лимит тикетов</TableCell>
              <TableCell numeric>Отправлять push</TableCell>
              <TableCell numeric>Можно закрыть</TableCell>
              <TableCell numeric>Можно отложить</TableCell>
              <TableCell numeric>Можно взять</TableCell>
              <TableCell numeric>Можно переназначить</TableCell>
              <TableCell numeric>Исп. систему попыток</TableCell>
              <TableCell numeric>Кол. полей</TableCell>
              <TableCell numeric>Кол. кнопок</TableCell>
              <TableCell numeric>Редактировать</TableCell>
              <TableCell numeric>Удалить</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {types.filter(t => filterRegexp.test(t.name)).map(type => {
              return (
                <TableRow key={type.id}>
                  <TableCell component="th" scope="row">
                    {type.name}
                  </TableCell>
                  <TableCell component="th" numeric>
                    {type.ticket_limit}
                  </TableCell>
                  {[
                    "send_push",
                    "can_close",
                    "can_delay",
                    "can_take",
                    "can_reassign",
                    "use_tries"
                  ].map(name => {
                    const color = type[name] ? classes.green : classes.red;
                    const icon = type[name] ? "done" : "close";
                    return (
                      <TableCell key={name} component="th" numeric>
                        <i className={`material-icons ${color}`}>{icon}</i>
                      </TableCell>
                    );
                  })}
                  <TableCell component="th" numeric>
                    {type.fields.length}
                  </TableCell>
                  <TableCell component="th" numeric>
                    {type.buttons.length}
                  </TableCell>
                  <TableCell component="th" numeric>
                    <Button
                      color="secondary"
                      onClick={editType}
                      data-id={type.id}
                      size="small"
                    >
                      <i className="material-icons">build</i>
                    </Button>
                  </TableCell>
                  <TableCell component="th" numeric>
                    <Button
                      color="secondary"
                      onClick={destroyType}
                      data-id={type.id}
                      size="small"
                    >
                      <i className="material-icons">delete</i>
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    marginBottom: theme.spacing.unit * 3
  },
  table: {
    minWidth: 700
  },
  green: {
    color: "#00bb00"
  },
  red: {
    color: "#dd0000"
  }
});

export default withStyles(styles)(TypeTable);
