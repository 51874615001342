import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import CreateButton from "../../CreateButton";
import TextField from "@material-ui/core/TextField";
import NoteTable from "./NoteTable";
import NoteDialog from "./NoteDialog";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";
import { get, create, show, update, destroy } from "../../../api/notes";

const emptyDialog = {
  showDialog: false,
  id: null,
  oldName: "",
  name: "",
  text: "",
  showDeleteConfirmation: false
};

class Notes extends React.PureComponent {
  state = {
    isLoading: true,
    filter: "",
    notes: [],
    page: 0,
    rowsPerPage: 50,
    ...emptyDialog
  };

  componentDidMount() {
    get(this.props.projectId)
      .then(({ data }) => this.setState({ notes: data, isLoading: false }))
      .catch(error => console.log(error));
  }

  newNote = () => {
    this.setState({ showDialog: true });
  };

  createNote = () => {
    const { notes, name, text } = this.state;
    create(this.props.projectId, name, text)
      .then(({ data }) => {
        this.setState({ notes: [...notes, data], ...emptyDialog });
      })
      .catch(error => console.log(error));
  };

  editNote = e => {
    const id = parseInt(e.currentTarget.dataset.id);
    const { name } = this.state.notes.find(n => n.id === id);
    show(this.props.projectId, name)
      .then(({ data }) => {
        this.setState({
          showDialog: true,
          id,
          oldName: name,
          name,
          text: data.text
        });
      })
      .catch(error => console.log(error));
  };

  updateNote = () => {
    const { oldName, name, text } = this.state;
    update(this.props.projectId, oldName, { new_name: name, text: text })
      .then(({ data }) => this.setState({ notes: data, ...emptyDialog }))
      .catch(error => console.log(error));
  };

  confirmDelete = e => {
    const id = parseInt(e.currentTarget.dataset.id);
    const { name } = this.state.notes.find(n => n.id === id);
    this.setState({ id, name, showDeleteConfirmation: true });
  };

  destroyNote = () => {
    const { id, name, notes } = this.state;
    destroy(this.props.projectId, name)
      .then(() =>
        this.setState({ notes: notes.filter(n => n.id !== id), ...emptyDialog })
      )
      .catch(error => console.log(error));
  };

  closeDialogs = () => {
    this.setState(emptyDialog);
  };

  changeText = editorState => {
    this.setState({ text: editorState });
  };

  setName = e => {
    this.setState({ name: e.target.value });
  };

  setFilter = e => {
    this.setState({ filter: e.target.value });
  };

  setPage = page => {
    this.setState({ page });
  };

  setRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    if (this.state.isLoading) return <LinearProgress />;
    const {
      filter,
      notes,
      showDialog,
      id,
      oldName,
      name,
      text,
      showDeleteConfirmation,
      page,
      rowsPerPage
    } = this.state;
    const filterRegexp = new RegExp(
      filter.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"),
      "i"
    );
    return (
      <div>
        <TextField
          style={{ margin: 8 }}
          placeholder="Строка фильтрации"
          helperText="Введите текст для фильтрации заметок"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          onChange={this.setFilter}
          value={filter}
        />
        <NoteTable
          notes={
            filter.trim() === ""
              ? notes
              : notes.filter(n => filterRegexp.test(n.name))
          }
          editNote={this.editNote}
          destroyNote={this.confirmDelete}
          setPage={this.setPage}
          setRowsPerPage={this.setRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
        />
        <NoteDialog
          showDialog={showDialog}
          id={id}
          oldName={oldName}
          name={name}
          text={text}
          setName={this.setName}
          changeText={this.changeText}
          closeDialog={this.closeDialogs}
          createNote={this.createNote}
          updateNote={this.updateNote}
        />
        <DeleteConfirmationDialog
          show={showDeleteConfirmation}
          text={`Вы действительно хотите удалить заметку "${name}"?`}
          cancelCallback={this.closeDialogs}
          confirmCallback={this.destroyNote}
        />
        <CreateButton callback={this.newNote} title="Создать заметку" />
      </div>
    );
  }
}

export default Notes;
