import React, { PureComponent } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

class NewPaymentForm extends PureComponent {
  state = { price: "" };

  setPrice = ({ target }) => {
    const { value } = target;
    if (value.match(/[^0-9 ]/)) return;
    this.setState({ price: target.value });
  };
  clearPrice = () => this.setState({ price: "" });
  fetchPayment = () => {
    this.setState({ price: "" });
    this.props.fetchPaymentUrl(this.state.price);
  };

  render() {
    const {
      assignmentId,
      showNewPaymentForm,
      closeNewPaymentForm
    } = this.props;
    const { price } = this.state;
    return (
      <Dialog
        open={!!(showNewPaymentForm && assignmentId)}
        onClose={this.clearPrice}
      >
        <DialogTitle>
          {`Создание нового платежа по брони ${assignmentId}`}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Цена"
            fullWidth
            value={price}
            onChange={this.setPrice}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeNewPaymentForm} color="primary">
            Закрыть
          </Button>
          <Button
            disabled={!price.match(/[0-9]+/)}
            onClick={this.fetchPayment}
            color="primary"
          >
            Создать
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default NewPaymentForm;
