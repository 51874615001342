import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import NoteTablePagination from "./NoteTablePagination";

class NoteTable extends React.PureComponent {
  render() {
    const {
      classes,
      notes,
      editNote,
      destroyNote,
      setPage,
      setRowsPerPage,
      page,
      rowsPerPage
    } = this.props;
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Название</TableCell>
              <TableCell numeric>Редактировать</TableCell>
              <TableCell numeric>Удалить</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notes
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(note => {
                return (
                  <TableRow key={note.id}>
                    <TableCell>{note.name}</TableCell>
                    <TableCell numeric>
                      <Button
                        color="secondary"
                        onClick={editNote}
                        data-id={note.id}
                        size="small"
                      >
                        <i className="material-icons">build</i>
                      </Button>
                    </TableCell>
                    <TableCell numeric>
                      <Button
                        color="secondary"
                        onClick={destroyNote}
                        data-id={note.id}
                        size="small"
                      >
                        <i className="material-icons">delete</i>
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[50, 100, 150, 200, 250, 300]}
                colSpan={3}
                count={notes.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={setPage}
                onChangeRowsPerPage={setRowsPerPage}
                ActionsComponent={NoteTablePagination}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    marginBottom: theme.spacing.unit * 3
  },
  table: {
    minWidth: 700
  }
});

export default withStyles(styles)(NoteTable);
