import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default class SubscriptionSelector extends React.PureComponent {
  state = { showInactive: false };

  toggleVisibility = () => {
    this.setState({ showInactive: !this.state.showInactive });
  };

  render() {
    const {
      subscriptions,
      subscriptionIds,
      selectedSubscriptions,
      toggleSubscription
    } = this.props;
    const { showInactive } = this.state;
    return (
      <div>
        <FormLabel component="legend">
          Подписки
          {" "}
          <i
            className="material-icons pointer small"
            title={showInactive ? "Скрыть неактивные" : "Показать неактивные"}
            onClick={this.toggleVisibility}
          >
            {showInactive ? "visibility_off" : "visibility"}
          </i>
        </FormLabel>
        {subscriptionIds.map(id => {
          if (!showInactive && !subscriptions[id].active) return null;
          return (
            <FormControlLabel
              key={id}
              control={
                <Checkbox
                  checked={selectedSubscriptions.has(id)}
                  onChange={toggleSubscription(id)}
                />
              }
              label={subscriptions[id].path}
            />
          );
        })}
      </div>
    );
  }
}
