// TODO: избавиться connect
import React from "react";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import CreateButton from "../../CreateButton";
import TextField from "@material-ui/core/TextField";
import ChainTable from "./ChainTable";
import ChainDialog from "./ChainDialog";
import RunDialog from "./RunDialog";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";
import { get, create, edit, update, destroy, run } from "../../../api/chains";
import { showAlert } from "../../../actions/layout";

const emptyDialog = {
  showChainDialog: false,
  id: null,
  name: "",
  webhook: "",
  taskSelections: [],
  taskSelectionIds: new Set(),
  showRunDialog: false,
  mark: "",
  showDeleteConfirmation: false
};

const emptyTaskSelection = {
  id: null,
  selection: { value: "", label: "" },
  delay: 0
};

const swapSelections = (coll, index1, index2) => {
  const selection1 = { ...coll[index1] };
  const selection2 = { ...coll[index2] };
  const updatedColl = [...coll];
  updatedColl[index2] = selection1;
  updatedColl[index1] = selection2;
  return updatedColl;
};

class Chains extends React.Component {
  state = {
    filter: "",
    isLoading: true,
    chains: [],
    tasks: [],
    ...emptyDialog
  };

  componentDidMount() {
    get(this.props.projectId)
      .then(({ data }) => this.setState({ ...data, isLoading: false }))
      .catch(error => console.log(error));
  }

  newChain = () => {
    this.setState({ showChainDialog: true });
  };

  createChain = () => {
    const { name, taskSelections, chains } = this.state;
    create(this.props.projectId, name, taskSelections)
      .then(({ data }) => {
        this.setState({ chains: [...chains, data], ...emptyDialog });
      })
      .catch(error => console.log(error));
  };

  // TODO: Set default string value for chain webhook in db and remove || ""
  editChain = e => {
    const id = parseInt(e.currentTarget.dataset.id);
    const chain = this.state.chains.find(c => c.id === id);
    edit(this.props.projectId, chain.id)
      .then(({ data }) =>
        this.setState({
          showChainDialog: true,
          id: chain.id,
          name: chain.name,
          webhook: chain.webhook || "",
          taskSelections: data.map(({ id, name, delay }) => ({
            id,
            selection: { value: id, label: name },
            delay
          })),
          taskSelectionIds: new Set(data.map(t => t.id))
        })
      )
      .catch(error => console.log(error));
  };

  updateChain = () => {
    const { id, name, webhook, taskSelections } = this.state;
    update(this.props.projectId, id, name, webhook, taskSelections)
      .then(({ data }) => this.setState({ ...data, ...emptyDialog }))
      .catch(error => console.log(error));
  };

  confirmDelete = e => {
    const id = parseInt(e.currentTarget.dataset.id);
    const { name } = this.state.chains.find(c => c.id === id);
    this.setState({ id, name, showDeleteConfirmation: true });
  };

  destroyChain = () => {
    const { id, chains } = this.state;
    destroy(this.props.projectId, id)
      .then(() =>
        this.setState({
          chains: chains.filter(c => c.id !== id),
          ...emptyDialog
        })
      )
      .catch(error => console.log(error));
  };

  openRunDialog = e => {
    const id = parseInt(e.currentTarget.dataset.id);
    this.setState({ id, showRunDialog: true });
  };

  runChain = () => {
    const { id, mark } = this.state;
    const { projectId, dispatchShowAlert } = this.props;
    run(projectId, id, mark)
      .then(() => {
        this.setState({ ...emptyDialog });
        dispatchShowAlert({ color: "success", text: "Цепь успешно запущена" });
      })
      .catch(() => {
        dispatchShowAlert({ color: "error", text: "Ошибка запуска цепи" });
      });
  };

  setTextValue = e => {
    const { name, value } = e.target;
    const strippedValue = value.replace(/^\s+/, "").replace(/\s{2,}/g, " ");
    this.setState({ [name]: strippedValue });
  };

  closeDialogs = () => {
    this.setState({ ...emptyDialog });
  };

  addTaskSelection = () => {
    const { taskSelections } = this.state;
    this.setState({ taskSelections: [...taskSelections, emptyTaskSelection] });
  };

  setDelayValue = ({ currentTarget }) => {
    const index = parseInt(currentTarget.dataset.index);
    const value = currentTarget.value.replace(/[^0-9]/, "");
    const { taskSelections } = this.state;
    const taskSelection = { ...taskSelections[index], delay: value };
    taskSelections[index] = taskSelection;
    this.setState({ taskSelections: [...taskSelections] });
  };

  handleSelectChange = index => task => {
    const { tasks, taskSelections } = this.state;
    const newSelections = [...taskSelections];
    const selection = { ...tasks.find(t => t.id === parseInt(task.value)) };
    newSelections[index] = {
      id: selection.id,
      selection: { value: selection.id, label: selection.name },
      delay: 0
    };
    this.setState({
      taskSelections: newSelections,
      taskSelectionIds: new Set(newSelections.map(t => t.id))
    });
  };

  dropSelection = e => {
    const index = parseInt(e.currentTarget.dataset.index);
    const { taskSelections } = this.state;
    const newSelections = taskSelections.filter((_, i) => i !== index);
    this.setState({
      taskSelections: newSelections,
      taskSelectionIds: new Set(newSelections.map(t => t.id))
    });
  };

  upSelection = e => {
    const index = parseInt(e.currentTarget.dataset.index);
    const { taskSelections } = this.state;
    if (index <= 0) return;
    this.setState({
      taskSelections: swapSelections(taskSelections, index, index - 1)
    });
  };

  downSelection = e => {
    const index = parseInt(e.currentTarget.dataset.index);
    const { taskSelections } = this.state;
    if (index >= taskSelections.length - 1) return;
    this.setState({
      taskSelections: swapSelections(taskSelections, index, index + 1)
    });
  };

  render() {
    if (this.state.isLoading) return <LinearProgress />;
    const {
      filter,
      chains,
      showChainDialog,
      taskSelections,
      taskSelectionIds,
      tasks,
      id,
      name,
      webhook,
      mark,
      showRunDialog,
      showDeleteConfirmation
    } = this.state;
    return (
      <div>
        <TextField
          style={{ margin: 8 }}
          placeholder="Строка фильтрации"
          helperText="Введите текст для фильтрации цепей по имени"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          inputProps={{ name: "filter" }}
          onChange={this.setTextValue}
          value={filter}
        />
        <ChainTable
          filter={filter}
          chains={chains}
          editChain={this.editChain}
          destroyChain={this.confirmDelete}
          openRunDialog={this.openRunDialog}
        />
        <ChainDialog
          showChainDialog={showChainDialog}
          taskSelections={taskSelections}
          taskSelectionIds={taskSelectionIds}
          tasks={tasks}
          id={id}
          name={name}
          webhook={webhook}
          setChainValue={this.setTextValue}
          handleSelectChange={this.handleSelectChange}
          dropSelection={this.dropSelection}
          upSelection={this.upSelection}
          downSelection={this.downSelection}
          addTaskSelection={this.addTaskSelection}
          setDelayValue={this.setDelayValue}
          closeDialog={this.closeDialogs}
          createChain={this.createChain}
          updateChain={this.updateChain}
        />
        <RunDialog
          mark={mark}
          showRunDialog={showRunDialog}
          setTextValue={this.setTextValue}
          closeDialog={this.closeDialogs}
          runChain={this.runChain}
        />
        <DeleteConfirmationDialog
          show={showDeleteConfirmation}
          text={`Вы действительно хотите удалить цепь "${name}"?`}
          cancelCallback={this.closeDialogs}
          confirmCallback={this.destroyChain}
        />
        <CreateButton callback={this.newChain} title="Создать новую цепь" />
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchShowAlert: payload => dispatch(showAlert(payload))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Chains);
