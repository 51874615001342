import { kick } from "../actions/layout";
import {
  addBasicCollection,
  addOtherTicketCollection,
  addFoundTicketCollection
} from "../actions/tickets";
import { setProjectToLocalStorage } from "../reducers/helpers/layout";

const kickUser = store => next => action => {
  if (action.type === kick.toString()) {
    store.dispatch(addBasicCollection({current: {}, delayed: {}}));
    store.dispatch(addOtherTicketCollection({}));
    store.dispatch(addFoundTicketCollection({}));
    setProjectToLocalStorage(null);
  }
  return next(action);
};

export default kickUser;
